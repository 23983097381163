import { ContestDetailResponse } from "types/contest"
import { Regtulation2024AboutStyle } from "./style"
import { useOutletContext } from "react-router"

const Regtulation2024About = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const organ: {
        title: string,
        direc: { title: string, list: { name: string, job: string }[] },
        deputyDirec: { title: string, list: { name: string, job: string }[] },
        general: { title: string, list: { name: string, job: string }[] },
        deputyGeneral: { title: string, list: { name: string, job: string }[] },
    } = contestJSON.organ || {}
    const jury2024: {
        title: string,
        chairman: { title: string, list: { name: string, job: string }[] },
        secondD: { title: string, list: string[] },
        threeD: { title: string, list: string[] },
        ani: { title: string, list: string[] },
    } = contestJSON.jury2024 || {}
    const member2022: { title: string, list: { name: string, job: string }[] } = contestJSON.member2022 || {}
    const board2022: {
        title: string,
        chairman: { title: string, list: { name: string, job: string }[] },
        matchBoard: { title: string, list: { name: string, job: string }[] },
    } = contestJSON.board2022 || []
    const cooperCompany2022: { title: string, allList: { title: string, list: string[] }[] } = contestJSON.cooperCompany2022 || {}
    const about1: string[] = contestJSON.about1 || []
    const about2: { title: string, desc: string[], logo: string } = contestJSON.about2 || {}

    const lang = localStorage.getItem('lang')

    return <Regtulation2024AboutStyle>
        {
            detail && <div className={`about_wrapper ${lang === 'en_GB' ? 'about_wrapper_en' : ''}`}>
                <div className="about_match ggac_container">
                    <img src="https://cdn.ggac.com/images/result_2020/about_badge.png" alt="" className="about_match_bg" />
                    <div className="title" lang="t_lang">关于大赛</div>
                    <div className="text">
                        {
                            about1.map(item => {
                                return <div className="cut" key={item} lang="t_lang">{item}</div>
                            })
                        }
                    </div>
                    <div className="about_match_leader">
                        <img src={about2.logo} alt="" />
                        <p lang="t_lang"> {about2.title}</p>
                    </div>
                    <div className="text">
                        {
                            about2.desc.map(item => {
                                return <div key={item} lang="t_lang">{item}</div>
                            })
                        }
                    </div>
                </div>
                <div className="match_Judge">
                    <div className="title second-title" lang="t_lang"> 大赛组委</div>
                    <div className="match_judge_card ggac_container">
                        <div className="card_left">
                            <p className="card_title" lang="t_lang">{organ.title}</p>
                            <div className="top_cnt">
                                <div>
                                    <p className="blod" lang="t_lang">{organ.direc.title}</p>
                                    {
                                        organ.direc.list.map(item => {
                                            return <p key={item.name} ><span lang="t_lang">{item.name}</span> <span lang="t_lang">{item.job}</span></p>
                                        })
                                    }
                                </div>
                                <div className="top_cnt">
                                    <div>
                                        <p className="blod" lang="t_lang">{organ.deputyDirec.title}</p>
                                        {
                                            organ.deputyDirec.list.map(item => {
                                                return <p key={item.name} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                            })
                                        }
                                        <p className="blod" lang="t_lang">{organ.general.title}</p>
                                        {
                                            organ.general.list.map(item => {
                                                return <p key={item.name} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                            })
                                        }
                                        <p className="blod" lang="t_lang">{organ.deputyGeneral.title}</p>
                                        {
                                            organ.deputyGeneral.list.map(item => {
                                                return <p key={item.name} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card_right">
                            <div>
                                <p className="card_title" lang="t_lang">{jury2024.title}</p>
                                <p className="blod" lang="t_lang">{jury2024.chairman.title}</p>
                                {
                                    jury2024.chairman.list.map(item => {
                                        return <p key={item.job} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                    })
                                }
                                <p className="blod" lang="t_lang">{jury2024.secondD.title}</p>
                                {
                                    jury2024.secondD.list.map(item => {
                                        return <p key={item} lang="t_lang"><span lang="t_lang">{item}</span></p>
                                    })
                                }
                            </div>
                            <div>
                                <p className="card_title" lang="t_lang">{board2022.title}</p>
                                <p className="blod" lang="t_lang">{board2022.chairman.title}</p>
                                {
                                    board2022.chairman.list.map(item => {
                                        return <p key={item.job} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                    })
                                }
                                <p className="blod" lang="t_lang">{board2022.matchBoard.title}</p>
                                {
                                    board2022.matchBoard.list.map(item => {
                                        return <p key={item.name} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                    })
                                }
                            </div>

                        </div>
                    </div>
                    <div className="judge_card ggac_container">
                        <p className="card_title" lang="t_lang">{member2022.title}</p>
                        <div className="top-cnt member" style={{ height: "1300px" }}>
                            {
                                member2022.list.map((item, index) => {
                                    return <p key={index} lang="t_lang">
                                        <span lang="t_lang">{item.name}</span>{item.job}
                                    </p>
                                })
                            }
                        </div>
                    </div>

                    <div className="work_together ggac_container">
                        {
                            lang === "zh_CN" && <p className="title">{cooperCompany2022.title}</p>
                        }
                        {
                            lang === "en_GB" && <p className="title">Partners</p>
                        }
                        <div className="work_list">
                            {
                                cooperCompany2022.allList.map((item, index) => {
                                    return <div className="work_list_item" key={index}>
                                        <p className="sub-title" lang="t_lang">{item.title}</p>
                                        {
                                            item.list.map((ele, i) => {
                                                return <div key={i} dangerouslySetInnerHTML={{ __html: ele }} lang="t_lang"></div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div >
            </div >
        }
    </Regtulation2024AboutStyle >
}

export default Regtulation2024About
