import { Button, Form, Input, Modal, message, Checkbox, Space } from "antd"
import { apiSendVerifyCode } from "api/common";
import { apiQQLogin, apiQQRegister } from "api/qq";
import { apiGetUserBaseInfo } from "api/user";
import {  useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkPhone } from "utils/functions/common";


export const QQLogin = () =>{

  const [agreement, setAgreement] = useState(false)

    const navigate = useNavigate()
    const [qqBindPhoneShow, setQQBindPhoneShow] = useState(false);
  const [sendMsgBtnLoading, setSendMsgBtnLoading] = useState(false);

  const [countDown, setCountDown] = useState<number>(60);

  const [wordModalShow, setWordModalShow] = useState(false)
  const [wordUrl, setWordUrl] = useState('https://pre-cdn-hz.ggac.com/docx/policy.html')
  const [modalTitle, setModalTitle] = useState('隐私政策')


  const [form] = Form.useForm<{mobile: string, verCode: ''}>();
  const [sessionId, setSessionId] = useState('')
  let [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const redirect = searchParams.get("redirect");
  useEffect(()=>{
    if(code) {
      apiQQLogin({code: code, appType: 3}).then(async res=>{
        if(res.isRegistered) {
          localStorage.setItem('auth_token', res.token)
          const userInfo = await apiGetUserBaseInfo()
          localStorage.setItem("user_info", JSON.stringify(userInfo)); 
          message.success("登录成功！")
          if(redirect) {
            // navigate(redirect,{replace:true})
            window.location.href = redirect
          }else {
            navigate('/home')
          }
          
        } else {
          setSessionId(res.sessionId)
          setQQBindPhoneShow(true)
        }
      }).catch(err=>{
        setQQBindPhoneShow(true)
        setSearchParams('')
      })
    }
  }, [code])


     const onClickRegister = () =>{
      console.log(form.getFieldValue("agreement"))
      if(!agreement){
        message.warning("请先同意用户协议！")
        return
      }
        form.validateFields().then(async values => {
        let {mobile, verCode} = values
        let params = {
            sessionId: sessionId,
            mobile: mobile,
            verCode: verCode,
            appType: 3,
            channelCode:localStorage.getItem('channelCode')
        }
        apiQQRegister(params).then(async res=>{
          localStorage.setItem('auth_token', res.token); 
          const userInfo = await apiGetUserBaseInfo()
          localStorage.setItem("user_info", JSON.stringify(userInfo)); 
         
         if(res.isNew) {
            navigate(`/auth/completeUserInfo?redirect=${redirect}`)
         } else {
          if(redirect) {
            // navigate(redirect,{replace:true})
            window.location.href = redirect
          }else {
            navigate('/home')
          }
          
         }
          }).catch(err=>{
            setSearchParams('')
            setQQBindPhoneShow(false)
            setTimeout(() => {
              if(redirect) {
                window.location.replace(`/auth/login?redirect=${redirect}`) 
              }
              
            }, 1.5*1000);
            
          //  window.
            // console.log(999999999999999)
          })
        })
    
  }

  const onSendCode = async () => {
   let mobile = form.getFieldValue('mobile')
   if(!mobile){
     message.error("请填写手机号码！");
     return
   }
   if(!checkPhone(mobile)){
     message.error("手机号码格式不正确！");
     return
   }
    //让按钮开始loading
    setSendMsgBtnLoading(true);
    // 让发送短信验证码
    await apiSendVerifyCode({
      mobile: mobile,
      countryCode: "86",
    });
    message.info("短信已发送");
    //刷新倒计时开始
    let interval = setInterval(() => {
      setSendMsgBtnLoading(false);
      setCountDown((pre) => {
        if (pre === 0) {
          setCountDown(60);
          clearInterval(interval);
        }
        return pre - 1;
      });
    }, 1000);
  }

  const  onClickOpenWordModal = (url: string, title: string) =>{
    setWordUrl(url)
    setModalTitle(title)
    setWordModalShow(true)
  }

   

    return <div>
                QQ登录
                <Modal width={500}
            closable={false}
            cancelButtonProps={{style:{
              display:'none'
            }}}
              getContainer={document.getElementById('login-form-container')!} 
              title="绑定手机号" open={qqBindPhoneShow} 
              footer={<Button type="primary" style={{width: '100px', marginTop: '4px'}} onClick={onClickRegister}>确定</Button>}
              // onOk={onClickRegister}
              maskClosable={false}
              maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.90)'}}
              onCancel={() => {setQQBindPhoneShow(false)}}>
          <Form
            name="basic"
            style={{ width: "100%" }}
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
            form={form}
          >
            <Form.Item
              label="手机号："
              name="mobile"
              style={{marginTop: '18px'}}
              rules={[{ required: true, message: "请输入手机号码" },
              {
                pattern:/^[0-9]*$/,
                message:"手机号码只能是数字"
              }]}
            >
              <Input   placeholder="请输入手机号码" />
            </Form.Item>

        <Form.Item
          label="验证码："
          name="verCode"
          rules={[
            {
              required: true,
              message: "请填写验证码",
            },
            {
              pattern: /^\d{4}$/,
              message: "验证码为4位数字",
            },
          ]}
        >
          <div className="code" style={{display: 'flex'}}>
            <Input className="verifyCode" style={{marginRight: '8px'}} placeholder="请输入短信验证码" />
            <Button
              type="primary"
              onClick={() => onSendCode()}
              disabled={countDown < 60}
              loading={sendMsgBtnLoading}
              className="sendMsg"
             
            >
              {countDown < 60 && countDown + "秒后重新获取"}
              {countDown === 60 && !sendMsgBtnLoading && "获取验证码"}
            </Button>
          </div>
        </Form.Item>
        
        </Form>
        <Checkbox checked={agreement} onChange= { () =>setAgreement(!agreement)}>
              <div className="userAgreement">
                <Space  onClick={() => onClickOpenWordModal('https://cdn-prd.ggac.com/ggac/assets/html/ggac-user-agreement.html','GGAC用户协议及隐私权政策')}>
                注册即代表同意<span className="Agreement" style={{ color:  '#ffc712', cursor: 'pointer', fontWeight: '600' }}>用户协议</span>和<span style={{ color:  '#ffc712', cursor: 'pointer', fontWeight: '600' }}>隐私协议</span>
                </Space>
              </div>
              <Modal
                centered
                onCancel={()=>{ setWordModalShow(false)}}
                  width="820px"
                  open={wordModalShow}
                  title={modalTitle}
                  footer={<Button type="primary" onClick={()=>{
                    setWordModalShow(false)
                  }}>确认</Button>}
                >
                <div>
                  <iframe style={{width: '750px', height: '700px', border: 'none', marginTop: '10px', marginLeft: '10px'}} src={wordUrl} title="用户协议"></iframe>
                </div>
                
              </Modal>
            </Checkbox>
          </Modal>
            </div>
}