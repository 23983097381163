import { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import ContestLayout from "views/contest/layout";
import CommonWork from 'views/contest/work'

import Layout from "views/layout";
import { ContestApplication } from "views/contest-form/apply";
import { ContestUpload } from "views/contest-form/upload";
import { YcwlontestUpload } from "views/contest/ycwl2/upload"
import { GContestApplication } from "views/grand-contest-form/apply";
import { GContestUpload } from "views/grand-contest-form/upload";
import Regtulation2024 from "views/contest/2024";
import Regulatioin2024Detail from "views/contest/2024/detail";
import Regulation2024Work from "views/contest/2024/work";
import Regultion2024Rule from "views/contest/2024/rule";
import Regulation2024Previous from "views/contest/2024/previous";
import Regtulation2024About from "views/contest/2024/about";
import RegulationPlan from "views/contest/2024/plan";

//路由懒加载,重定向的不用再调用懒加载函数
const LazyLoad = (path: string) => {
    const Comp = lazy(() => import('views/' + path));
    return (
        <Suspense fallback={<></>}>
            <Comp />
        </Suspense>
    )
}

export const matchRoutes: RouteObject[] = [
    {
        path: '/contest/wukong',
        element: <Navigate to="/contest/wukong/detail"></Navigate>
    },
    {
        path: '/contest/ty',
        element: <Navigate to="/contest/ty/detail"></Navigate>
    },
    {
        path: '/contest/ylzy',
        element: <Navigate to="/contest/ylzy/detail"></Navigate>
    },
    {
        path: '/contest/czwj',
        element: <Navigate to="/contest/czwj/detail"></Navigate>
    },
    {
        path: '/contest/czwj2',
        element: <Navigate to="/contest/czwj2/detail"></Navigate>
    },
    {
        path: '/contest/ycwl2',
        element: <Navigate to="/contest/ycwl2/detail"></Navigate>
    },
    {
        path: '/contest/longarts',
        element: <Navigate to="/contest/longarts/pro"></Navigate>
    },
    {
        path: '/contest/ydbz',
        element: <Navigate to="/contest/ydbz/detail"></Navigate>
    },
    {
        path: '/contest/ly3d',
        element: <Navigate to="/contest/ly3d/detail"></Navigate>
    },
    {
        path: '/contest/xrtz',
        element: <Navigate to="/contest/xrtz/detail"></Navigate>
    },
    {
        path: '/contest/sims',
        element: <Navigate to="/contest/sims/detail"></Navigate>
    },
    // {
    //     path: '/contest/:code',
    //     element: <ContestTemplate></ContestTemplate>
    // },
    {
        path: '/contest',
        element: <Layout></Layout>,
        children: [
            {
                path: 'sims',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/sims/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/sims/rule'),
                    },
                    {
                        path: "work",
                        element: LazyLoad('contest/sims/work'),
                    },
                ]
            },
            {
                path: 'czwj2',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/czwj2/detail'),
                    },
                    {
                        path: "arms",
                        element: LazyLoad('contest/czwj2/arms'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/czwj2/rule'),
                    },
                    {
                        path: "about",
                        element: LazyLoad('contest/czwj2/about'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='czwj2' />,
                    },
                ]
            },
            {
                path: 'xrtz',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/xrtz/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/xrtz/rule'),
                    },
                    {
                        path: "about",
                        element: LazyLoad('contest/xrtz/about'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='xrtz' />,
                    },
                ]
            },
            {
                path: 'ly3d',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/ly3d/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/ly3d/rule'),
                    },
                    {
                        path: "about",
                        element: LazyLoad('contest/ly3d/about'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ly3d' />,
                    },
                ]
            },
            {
                path: 'longarts',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/longarts/detail'),
                    },
                    {
                        path: "pro",
                        element: LazyLoad('contest/longarts/pro'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='longarts' />,
                    },
                ]
            },
            {
                path: 'longarts/upload/:id',
                element: LazyLoad("contest/longarts/upload")
            },
            {
                path: 'ycwl2',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/ycwl2/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/ycwl2/rule'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ycwl2' />,
                    },
                    {
                        path: 'upload/:id',
                        element: <YcwlontestUpload></YcwlontestUpload>
                    }
                ]
            },
            {
                path: 'czwj',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/czwj/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/czwj/rule'),
                    },
                    {
                        path: "workprize",
                        element: LazyLoad('contest/czwj/workprize'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='czwj' />,
                    },
                ]
            },
            {
                path: 'wukong',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/wukong/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/wukong/rule'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='wukong' />,
                    },
                    {
                        path: 'workprize',
                        element: LazyLoad('contest/wukong/workprize'),
                    },
                ]
            },
            {
                path: 'ty',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/ty/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/ty/rule'),
                    },
                    {
                        path: "about",
                        element: LazyLoad('contest/ty/about'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ty' />,
                    },
                    {
                        path: 'workprize',
                        element: LazyLoad('contest/ty/workprize'),
                    },
                ]
            },
            {
                path: 'ylzy',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/ylzy/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/ylzy/rule'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ylzy' />,
                    },
                    {
                        path: 'workprize',
                        element: LazyLoad('contest/ylzy/workprize'),
                    }
                ]
            },
            {
                path: 'ydbz',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/ydbz/detail'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ydbz' />,
                    },
                    {
                        path: 'shop',
                        element: LazyLoad('contest/ydbz/shop'),
                    },
                    {
                        path: 'exhibition',
                        element: LazyLoad('contest/ydbz/exhibition'),
                    }
                ]
            },
            {
                path: 'apply/:id',
                element: <ContestApplication></ContestApplication>
            },
            {
                path: 'upload/:id',
                element: <ContestUpload></ContestUpload>
            }
        ]
    },
    {
        path: '/2024/regulation',
        element: <Navigate to="/2024/regulation/detail"></Navigate>
    },
    {
        path: '/2024',
        element: <Layout></Layout>,
        children: [
            {
                path: 'regulation',
                element: <Regtulation2024></Regtulation2024>,
                children: [
                    {
                        path: 'detail',
                        element: <Regulatioin2024Detail></Regulatioin2024Detail>
                    },
                    {
                        path: 'rule',
                        element: <Regultion2024Rule />,
                    },
                    {
                        path: 'previous',
                        element: <Regulation2024Previous />,
                    },
                    {
                        path: 'about',
                        element: <Regtulation2024About />,
                    },
                    // {
                    //     path: "plan",
                    //     element: <RegulationPlan />
                    // },
                    {
                        path: 'work',
                        element: <Regulation2024Work name='ggac6' />,
                    },
                ]
            }
        ]
    },
    {
        path: '/g-contest',
        element: <Layout></Layout>,
        children: [
            {
                path: 'apply/:id',
                element: <GContestApplication></GContestApplication>
            },
            {
                path: 'upload/:id',
                element: <GContestUpload></GContestUpload>
            }
        ]
    }

]

