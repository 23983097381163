import styled from "styled-components";

export const CategoryFilterStyle = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.space.margin}px;
  .row-1 {
    display: inline-flex;
    justify-content: center;
    gap: 18px;
    width: 100%;
    align-items: center;
    .block {
      min-width: calc((100%  - 80px) / 9) !important;
      max-width: calc((100%  - 80px) / 9) !important;
      height: 51px;
      background-color: ${(props) => props.theme.colors.colorBorderSecondary};
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      font-weight: bold;
      &:hover {
        .text {
          color: ${(props) => props.theme.colors.colorPrimaryText};
        }
      }
      .block-overlay {
        content: "";
        display: block;
        position: absolute;
        border-radius: 6px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        filter: brightness(50%) saturate(220%);
      }
      .text {
        position: absolute;
        color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.active {
          color: ${(props) => props.theme.colors.colorPrimaryText};
        }
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        width: 100%;
        text-align: center;
      }
    }
  }
  .control {
      font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
      border-radius: 50% 50%;
      width: 28px;
      height: 28px;
      background-color: #F1F1F3;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .tags_container  {
      width: 100%;
      overflow: hidden;
    }
    .select {
      width: max-content;
      margin: 0 auto;
      .row-2 {
        justify-content: flex-start;
        .tag {
          padding: 3px 10px;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  .row-2 {
    margin: ${(props) => props.theme.space.margin}px auto;
    height: 34px;
    display: flex;
    justify-content: center;
    gap: 12px;
    position: relative;
    align-items: center;
    /* min-width: 1400px; */
    position: relative;
    .tag {
      padding: 6px 20px;
      cursor: pointer;
      text-align: center;
      &.active {
        background-color: ${(props) => props.theme.colors.colorPrimary};
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.colorPrimary};
      }
      background-color: #f1f1f3;
      border-radius: 26px;
      text-align: center;
      width: auto;
      min-width: 80px;
    }
    
  }
  .meta{
    margin: 0 auto;
    text-align: center;
    height:22px;
    .name{
        font-size: 20px;
        font-weight: 500;
    }
    .desc{
        color: #8C8C8C;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        svg {
          color: rgba(0, 0, 0, 0.25);
          cursor: pointer;
          &:hover {
            color: rgba(0, 0, 0, 0.45);
          }
        }
    }
  }
`;
