import { PublicProductListItem } from "types/product";
import ProductCardLayout from "../layout";

function RecommandProductCard({ item }: { item: PublicProductListItem }) {
  const UiObject = {
    coverUrl: item.coverUrl,
    title: item.name,
    subtitle: item.name,
    productLink: "",
    id: item.id,
    simple:true
  };

  return ProductCardLayout({ item: UiObject });
}

export default RecommandProductCard;
