import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import AlbumList from "views/mall/album";
import Market from "views/mall/market";

export const mallRoutes: RouteObject[] = [

    {
        path: "/mall",
        element: <Layout></Layout>,
        children: [
            {
                path: "market",
                element: <Market></Market>,
            },
            {
                path: "album",
                element: <AlbumList></AlbumList>,
            },
        ]

    }
]