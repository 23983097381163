import styled from "styled-components";

export const UserCenterLayoutStyle = styled.div`
  background-color: ${(props) => props.theme.colors.colorBgLayout};
  .layout-padding {
    height: 50px;
    width: 100%;
    background-color: gray;
  }

  .banner {
    width: 100%;
    aspect-ratio: 1920/300;
    background-color: ${(props) => props.theme.colors.colorBlack6Ggac};
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      .actions {
        display: block;
        display: flex;
        gap: 12px;

        .item {
          color: #fff;
          cursor: pointer;
          padding: ${(props) => props.theme.space.paddingSM}px
            ${(props) => props.theme.space.padding}px;
          background-color: rgba(7, 7, 7, 0.2);
          border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
          &:hover {
            color: ${(props) => props.theme.colors.colorPrimary};
          }
          .icon-btn {
            font-size: 18px;
          }
        }
      }
    }
    .actions {
      display: none;
    }
    .to-user-frontpage {
      position: absolute;
      bottom: 20px;
      z-index: 101;
      max-width: ${(props) => props.theme.space.maxContainer}px;
      width: 100%;
      padding: 0 ${(props) => props.theme.space.paddingLG}px;
      display: flex;
      justify-content: flex-end;

      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      color: ${(props) => props.theme.colors.colorBlack1Ggac};
      font-size: ${(props) => props.theme.fontSizes.fontSize}px;

      .link {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: ${(props) => props.theme.space.padding}px
          ${(props) => props.theme.space.padding}px;
        /* background-image: linear-gradient(to top, rgba(7, 7, 7, 0.4),transparent);
  
          border-radius:  ${(props) => props.theme.border.borderRadiusLG}px ; */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        span {
          padding-right: ${(props) => props.theme.space.paddingXXS}px;
        }
        .icon {
          font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
        }
        &:hover {
          color: ${(props) => props.theme.colors.colorPrimary};
        }
      }
    }
  }
  .main-container {
    /* transform: translateY(-20px); */
    display: flex;
    // width: 1280px;
   
    width: 100%;
    max-width: ${(props) => props.theme.space.maxContainer}px;
    padding: 0 ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.padding}px;
    /* margin: -20px auto 0; */
    position: relative;
    margin: 0 auto;
    .left-pannel{
      transform: translateY(-80px);
      /* max-width: 350px;
      min-width: 329px; */
      width: 270px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${(props) => props.theme.space.marginLG}px;
      .menu {
      border: 1px solid ${(props) => props.theme.colors.colorBorder};
      width: 100%;
      border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
      padding: ${(props) => props.theme.space.paddingLG}px;
      text-align: center;
      .ant-menu-item {
        padding-left: 0 !important;
        &:hover{
          background-color: #F0F0F0 !important;
        }
      }
      .ant-menu-submenu{
        &:hover{
          background-color: #F0F0F0 !important;
        }
        .ant-menu-title-content{
         padding-right: 7px;
        }
      }
      .ant-menu-item-selected {
        background-color: ${(props) => props.theme.colors.colorPrimary};
        &:hover {
          background-color: #F0F0F0 !important;
        }

        .ant-menu-title-content {
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-item {
        color: ${(props) => props.theme.colors.colorTextLightCustom};
        &:hover {
          background-color: transparent;
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-submenu-title {
        color: ${(props) => props.theme.colors.colorTextTertiary};
        &:hover {
          background: none !important;
          color: ${(props) => props.theme.colors.colorText};
        }
      }
      .ant-menu-sub {
        border-radius: ${(props) => props.theme.border.borderRadius}px;
        overflow: hidden;
      }
    }
    }
    .right-pannel {
      position: relative;
      margin: ${(props) => props.theme.space.marginLG* 1.4}px;
      margin-right: ${(props) => props.theme.space.marginXS}px;
      max-width: calc(100vw - 360px);
      flex: 1;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      .wrapper{
       
      }
    }
  }
`;
