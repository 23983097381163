import { useState, useEffect } from 'react';
import { Select } from 'antd';
import { MallProductCategoryListItem } from "types/product";
import { apiGetMallProductListCategory } from 'api/shop';
import { useNavigate } from 'react-router-dom';
import { queryUrl } from 'utils/functions/common';

export const TypeSelect = (props: { value?: number[], type?: string, onChange?: (value: number[]) => void }) => {
    const [firstData, setFirstData] = useState<MallProductCategoryListItem[]>([])
    const [secondData, setSecondData] = useState<MallProductCategoryListItem[]>([])

    const [first, setFirst] = useState<number | null>();
    const [second, setSecond] = useState<number | null>();
    const navigate = useNavigate()

    useEffect(() => {
        apiGetMallProductListCategory({ type: 3, level: 1 }).then(res => {
            setFirstData(res)
            if (props.value && props.value.length > 1) {
                setFirst(props.value[0])
                setSecond(props.value[1])
                if (props.value[1]) {
                    apiGetMallProductListCategory({ type: 3, level: 2, pid: Number(props.value[0]) }).then(second => {
                        setSecondData(second)
                    })
                }
            } else {
                setFirst(null)
                // apiGetMallProductListCategory({ type: 3, level: 2, pid: Number(res[0].id) }).then(second => {
                //     setSecondData(second)
                //     setSecond(second[0].id)
                // })
            }
        })
    }, [props?.value])



    const handleFirstChange = (value: number) => {
        setFirst(value)
        apiGetMallProductListCategory({ type: 3, level: 2, pid: Number(value) }).then(second => {
            setSecondData(second)
            setSecond(second[0].id)
            props.onChange && props.onChange([value, second[0]?.id])
        })
        const params = queryUrl({type:value})
        navigate(`?${params}`)
    };

    const handleSecondChange = (value: number) => {
        setSecond(value)
        props.onChange && props.onChange([Number(first), value])
        const params = queryUrl({type:value})
        navigate(`?${params}`)
    };


    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Select
                style={{ width: '48%' }}
                value={first}
                onChange={handleFirstChange}
                options={firstData && firstData.map((item) => ({ label: item.name, value: item.id }))}
                placeholder="请选择"
            />

            <Select
                style={{ width: '48%' }}
                value={second}
                onChange={handleSecondChange}
                options={secondData?.map((item) => ({ label: item.name, value: item.id }))}
                placeholder="请选择"
            />
        </div>
    );
};

export default TypeSelect
