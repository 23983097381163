import { Button, message } from "antd";
import { SuperInteractionStyle } from "./style";
import { UserBaseInfo } from "types/user";
import { WorkDetailType } from "types/work";
import { useEffect, useState } from "react";
import { postToRecommendWork } from "api/work";
import ScoreModal from "components/uni-card/self-cards/scoreWorkCard/score-modal";
import { useSearchParams } from "react-router-dom";
import { apiGetWorkScoreDetail } from "api/contest";
import { ScoreFlowDetail } from "types/contest";


type Props = {
  detail: WorkDetailType;
  updateDetail: (detail: WorkDetailType) => void;
};
function SuperInteraction({ detail, updateDetail }: Props) {
  const [recommendLoading, setRecommedLoading] = useState(false);
  const [openScoreModal, setOpenScoreModal] = useState(false);
  const [scoreDetail, setScoreDetail] = useState<ScoreFlowDetail>();
  const [scoreLoading, setScoreLoading] = useState(false);
  const [searchParams]= useSearchParams()
  let userBaseInfo: UserBaseInfo = JSON.parse(
    localStorage.getItem("user_info")!
  );

  const toRecommend = () => {
    setRecommedLoading(true);
    postToRecommendWork({
      dataId: detail.id,
      dataTable: "work",
      userId: userBaseInfo.id,
    })
      .then(() => {
        detail.isRecommend = 1;
        updateDetail(detail);
        message.success("推荐成功");
      })
      .finally(() => {
        setRecommedLoading(false);
      });
  };

  const onScored = ()=>{
    getScoreDetail()
    setOpenScoreModal(false)
  }

  const getScoreDetail = ()=>{
    setScoreLoading(true)
    searchParams.get('scoreTaskId') && apiGetWorkScoreDetail({taskId: Number(searchParams.get('scoreTaskId')), workId: detail.id,mediaCategory:Number(searchParams.get('taskMediaCategory'))}).then((res)=>{
      setScoreDetail(res)
    }).finally(()=>{
      setScoreLoading(false)
    })
  }

  useEffect(()=>{
    getScoreDetail()
  },[])
  return (
    <SuperInteractionStyle>
      {userBaseInfo && userBaseInfo.permissions.includes("workRecommend") && (
        <Button
          disabled={detail.isRecommend === 1}
          loading={recommendLoading}
          style={{width: '100%'}}
          onClick={toRecommend}
          type="primary"
        >
          {detail && detail.isRecommend === 1 ? "已推荐" : "推荐"}
        </Button>
      )}

      {userBaseInfo && userBaseInfo.permissions.includes("workScore") && searchParams.get('scoreTaskId') && (
        <>
       
      <ScoreModal workCoverUrl={detail.coverUrl} workTitle={detail.title} taskId={Number(searchParams.get('scoreTaskId'))} workId={detail.id} openModal={openScoreModal} handleOk={onScored} handleCancel={onScored} mediaCategory={Number(searchParams.get('taskMediaCategory'))}></ScoreModal>
        <Button
          disabled={scoreDetail?.isSubmit ===1}
          style={{width: '100%',marginTop: '24px'}}
          onClick={()=>setOpenScoreModal(true)}
          type="primary"
          loading={scoreLoading}
        >
          {scoreDetail && scoreDetail?.averageScore >0 ? `修改打分(${scoreDetail?.averageScore})`:'打分'}
        </Button>
        </>
      )}
    </SuperInteractionStyle>
  );
}

export default SuperInteraction;
