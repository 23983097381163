import { Checkbox, Select } from "antd"
import { WorkFilterStyle } from "./style"
import { useEffect, useState } from "react"
import { ContestDetailResponse, WorkContestListRequest } from "types/contest"
import { apiGetSignUpField } from "api/sub";
import { SignupFieldOptions } from "types/sub/application";
import { queryUrl } from "utils/functions/common";
import { useLocation, useNavigate } from "react-router";

const ContestWorkFilter = ({ subDetail, showVoteFilter }: { updateValue: (value: WorkContestListRequest) => void, subDetail: ContestDetailResponse, showVoteFilter?: boolean }) => {
    const [themeList, setThemeList] = useState<SignupFieldOptions[]>([])
    const [themeAList, setThemeAList] = useState<SignupFieldOptions[]>([])
    const pathname = window.location.pathname
    const navigate = useNavigate()
    const themeId = new URLSearchParams(useLocation().search).get("themeId")
    const sortField = new URLSearchParams(useLocation().search).get("sortField") || 'lastSubmitTime'
    let themeA = new URLSearchParams(useLocation().search).get("themeA") || ""
    let publishType = new URLSearchParams(useLocation().search).get("publishType") || ''
    let trackId = new URLSearchParams(useLocation().search).get("trackId") || ''
    const [checked, setCheckout] = useState("")
    const detailId = subDetail?.id
    useEffect(() => {
        setCheckout(publishType)
    }, [publishType])

    const getContestTypeList = () => {
        apiGetSignUpField(detailId).then(res => {
            const theme = res.find(item => {
                return item.code === 'theme'
            })
            const themeA = res.find(item => {
                return item.code === 'themeA'
            })
            setThemeAList(themeA?.options || [])
            setThemeList(theme?.options! || [])
        })
    }

    useEffect(() => {
        if (!detailId) {
            return
        }
        getContestTypeList()
    }, [detailId])

    const handleChangeSort = (value: string) => {
        const query = queryUrl({ sortField: value, pageNumber: 1 })
        navigate(`${pathname}?${query}`)
    }


    const handleChange = (value: number, type: string) => {
        let query
        if (type === 'theme') {
            if (value === 0) {
                query = queryUrl({ themeId: 'all', trackId: "1", pageNumber: 1 })
            } else {
                query = queryUrl({ themeId: value, pageNumber: 1 })
            }
        } else if (type === 'themeA') {
            if (value === 0) {
                query = queryUrl({ themeA: 'all', trackId: "2", pageNumber: 1 })
            } else {
                query = queryUrl({ themeA: value, pageNumber: 1 })
            }
        }
        navigate(`${pathname}?${query}`)
    }
    let list = [
        {
            label: "最新",
            value: "lastSubmitTime"
        },
        {
            label: "最热",
            value: "threeDaysHot"
        },
    ]

    if (showVoteFilter) {
        list = [...list, {
            label: "票数",
            value: "ticketCount"
        }]
    }

    const onCheckoutChange = (e: boolean) => {
        const query = queryUrl({ publishType: e ? '2' : '', pageNumber: 1 })
        setCheckout(e ? '2' : '')
        navigate(`${pathname}?${query}`)
    }

    const typeList = [
        { name: "全部", id: "" },
        { name: "GGAC创作奖", id: "1" },
        { name: "GGAC学院奖", id: "2" },
        { name: "Style3D特别奖", id: "3" }
    ]

    const handleChangeTrack = (e: string) => {
        let query
        if (e === "1") {
            query = queryUrl({ trackId: e, themeId: "all", themeA: "", pageNumber: 1, tagName: "" })
        } else if (e === "2") {
            query = queryUrl({ trackId: e, themeId: "", themeA: "all", pageNumber: 1, tagName: "" })
        } else if (e === "3") {
            query = queryUrl({ trackId: e, themeId: "", themeA: "", pageNumber: 1, tagName: "Style3D" })
        } else if (!e) {
            query = queryUrl({ trackId: e, themeId: "", themeA: "", pageNumber: 1, tagName: "" })
        }
        navigate(`${pathname}?${query}`)
    }

    return <WorkFilterStyle>
        <div className="type-list">


            <div className="wrap">
                <Select
                    style={{ width: 120 }}
                    defaultValue={trackId || ""}
                    className="select"
                    onChange={(e) => handleChangeTrack(e)}
                    options={typeList}
                    fieldNames={{ label: 'name', value: 'id' }}
                    placeholder="请选择"
                />

                <div className="type-list2">
                    {
                        trackId === "1" && themeList.length > 0 && <div className="type-list-item">
                            <div className="type fwb">
                                <div className={`type-item ${themeId === 'all' ? 'active' : ''}`} key={0} onClick={() => handleChange(0, 'theme')}><span>全部</span></div>
                                {
                                    themeList.map(item => {
                                        return <div key={item.id} className={`type-item ${item.id === Number(themeId) ? 'active' : ''}`}>
                                            <span onClick={() => handleChange(item.id, 'theme')}>{item.name}</span>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }

                    {
                        trackId === "2" && themeAList.length > 0 && <div className="type-list-item">
                            <div className="type">
                                <div className={`type-item  fwb ${themeA === 'all' ? 'active' : ''}`} key={0} onClick={() => handleChange(0, 'themeA')}><span>全部</span></div>
                                {
                                    themeAList.map(item => {
                                        return <div key={item.id} className={`type-item ${(item.id === Number(themeA)) ? 'active' : ''}`}>
                                            <span onClick={() => handleChange(item.id, 'themeA')}>{item.name}</span>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }

                </div>
            </div>
            <div className="type-list-item">
                <div className="type-wrap">
                    <div className="type">
                        {
                            list.map(item => {
                                return <div key={item.value} className={`type-item ${item.value === sortField ? 'active' : ''}`} onClick={() => handleChangeSort(item.value)}>
                                    <span>{item.label}</span>
                                </div>
                            })
                        }
                        <div className="type-item">
                            <Checkbox checked={checked === '2' ? true : false} onChange={(e) => onCheckoutChange(e.target.checked)}>
                                只看完成作品
                            </Checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WorkFilterStyle>
}

export default ContestWorkFilter