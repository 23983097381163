import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DetailStyle } from "./style";
import {
  ExclamationCircleOutlined,
  StarOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  InputNumber,
  Modal,
  QRCode,
  message,
  Image,
  Space,
  Tooltip,
  Popover,
  Statistic,
} from "antd";
import {
  apiGetOrderDetail,
  apiGetProductShareImage,
  apiGetPublicProductDetail,
  getMallReview,
  postPreCreateMall,
  postSearchOrderSuccess,
} from "api/shop";
import { PublicProductDetail } from "types/product";
import PreSubmitModal from "./preSubmitModal";
import CommonTabs from "ui-lib/tab/common-tab";
import RichTextContent from "./richTextContent";
import ProductReviewList from "./productReview/productReviewList";
import ProductDetailCardComponent from "./card-component";
import ProductSellerProfile from "./SellerProfile";
import ProductComment from "./productComment";
import SellerProduct from "./sellerProduct";
import RelatedWork from "./relatedWork";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiShareForwardBoxLine,
} from "react-icons/ri";
import { findNextItem, findPreviousItem, renderTip } from "./fn";
import { apiPostWeixinPayCommodity } from "api/commodity";
import { useKeenSlider } from "keen-slider/react";
import AddFavorite from "components/favorite/add-favorite";
import { loginPathWithRedirect } from "utils/functions/user";
import { CouponCanUseListItem, UserBaseInfo } from "types/user";
import { getUserAvailableCoupons } from "api/user";
import ProductTags from "./tags";
import GlobalShare from "components/share";
import { formatMoney } from "views/user-shop/productManage/create/fn";
import BuyerGuide from "./buyer-guide";
import { DeliveryStatusArr } from "types/enums/commodity-status-type";
import dayjs from "dayjs";
import { logoutChat, orderPayedSendMessage } from "utils/functions/chat";

let weixinTimer: any = null; // 微信定时器

const { Countdown } = Statistic;

const Detail = () => {
  const [detail, setDetail] = useState<PublicProductDetail>();
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const id = useParams()?.productId;
  const [preImageUrl, setPreImageUrl] = useState("");
  const [currentId, setCurrentId] = useState(0);
  const [count, setCount] = useState(1);
  const [weixinVisible, setWeixinVisible] = useState(false);
  const [weixinCode, setWeixinCode] = useState<string>();
  const [currentTabIndex, setCurrentTabIndex] = useState<string | number>(1);
  const navigate = useNavigate();
  const [tatal, setTotal] = useState(0);
  const [codeStatus, setCodeStatus] = useState<
    "active" | "expired" | "loading"
  >("loading");
  const [orderNumber, setOrderNumber] = useState("");
  const [visible, setVisible] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [showAddToFavorite, setShowAddToFavorite] = useState(false);
  const [showShare, setShowShare] = useState(false);
  let userAllInfo: UserBaseInfo = JSON.parse(
    localStorage.getItem("user_info")!
  );
  const [openCouponList, setOpenCouponList] = useState(false);
  const [availableCouponList, setAvailableCouponList] =
    useState<CouponCanUseListItem[]>();
  const [shareUrl, setShareUrl] = useState("");
  const [shareImageUrl, setShareImageUrl] = useState("");
  const [shareQrCode, setShareQrCose] = useState("");

  const [countDownValue, setCountDownValue] = useState(0)
  const [isYueGao, setIsYueGao] = useState(false)

  useEffect(() => {
    if (!detail) {
      return;
    }
    setCurrentId(detail?.skuList[0].id);
    if (detail.categoryList[0].code === 'yuegao') {
      setIsYueGao(true)
    }
    return () => {
      logoutChat()
    };
  }, [detail]);

  const currentSpecs = useMemo(() => {
    return detail?.skuList.find((item) => item.id === currentId);
  }, [currentId]);

  const getDetail = useCallback(async () => {
    const result = await apiGetPublicProductDetail(+id!);
    setDetail(result);
    setPreImageUrl(result.imageList[0]);
  }, [id]);

  const getWeixinCode = (value: string, orderNumber: string) => {
    clearInterval(weixinTimer);
    setIsShow(false); // 关闭确认订单弹窗
    setWeixinCode(value);
    setCodeStatus("active");
    setWeixinVisible(true);
    loopCheckOrderStatus(orderNumber);
    setOrderNumber(orderNumber);
  };

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  useEffect(() => {
    return () => {
      // 清除定时器
      clearInterval(weixinTimer);
    };
  }, []);

  const onRefreshCode = () => {
    apiPostWeixinPayCommodity(String(orderNumber)).then((res) => {
      getWeixinCode(res.code_url, orderNumber);
      setCodeStatus("active");
    });
  };

  // 查询订单支付状态，最多查询100次
  const loopCheckOrderStatus = (value: string) => {
    let num = 0;
    weixinTimer = setInterval(() => {
      // 开启定时器
      if (num >= 100) {
        clearInterval(weixinTimer);
        setCodeStatus("expired");
        return;
      }
      searchOrderSuccess(String(value));
      const deadline = Date.now() + 1000 * 1.5 * (100 - num);
      setCountDownValue(deadline)
      num++;
    }, 1500);
  };

  // 查询订单是否支付成功
  const searchOrderSuccess = async (value: string) => {
    const result = await postSearchOrderSuccess(value);
    if (result.state === 1) {

      if (isYueGao) {
        apiGetOrderDetail(value).then(res => {
          orderPayedSendMessage(res)
          message.success("支付成功");
          navigate(`/user-center/shop/order/detail/${value}`);
          setWeixinVisible(false);
          clearInterval(weixinTimer);
          setCountDownValue(0)
        })
        return
      }
      message.success("支付成功");
      navigate(`/user-center/shop/order/detail/${value}`);
      setWeixinVisible(false);
      clearInterval(weixinTimer);
      setCountDownValue(0)
    }
  };

  // 支付
  const pay = async () => {
    if (currentSpecs?.outerUrl) {
      const urlArr = currentSpecs.outerUrl.match(
        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/
      );
      const url = urlArr ? urlArr[0] : "";
      modal.confirm({
        title: "非站内购买",
        icon: <ExclamationCircleOutlined />,
        content: `您将前往 ${url} 进行购买，购买后将由该网站运营方进行相关商品的交付。`,
        okText: "立即前往",
        cancelText: "取消",
        onOk() {
          window.open(currentSpecs?.outerUrl);
        },
      });

      return;
    }
    setLoading(true);
    clearInterval(weixinTimer);
    postPreCreateMall({ num: count, skuId: Number(currentSpecs?.id) })
      .then((res) => {
        if (res.products[0].paidToTalCount > 0) {
          Modal.confirm({
            title: "提示",
            content: "您曾购买过这个商品，是否再次购买",
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
              setIsShow(true);
            },
          });
        } else {
          setIsShow(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectImage = (detail: string) => {
    setPreImageUrl(detail);
  };

  const imageNavPrev = (url: string) => {
    if (!detail?.imageList) {
      return;
    }
    setPreImageUrl(findPreviousItem(detail?.imageList, url));
  };

  const imageNavNext = (url: string) => {
    if (!detail?.imageList) {
      return;
    }
    setPreImageUrl(findNextItem(detail?.imageList, url));
  };

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    mode: "free-snap",
    initial: 0,
    slides: {
      perView: 7,
      spacing: 5,
    },
  });

  const move = (type: string) => {
    if (type === "prev") {
      instanceRef.current?.prev();
    }
    if (type === "next") {
      console.log(instanceRef.current);
      instanceRef.current?.next();
    }
  };

  useEffect(() => {
    instanceRef && instanceRef.current?.update();
  }, [detail?.imageList]);

  // const couponList = () => {
  //   return <div className="coupon-list" style={{ maxHeight: 380, overflowY: 'auto' }}>
  //     {availableCouponList?.map((item, index) => {
  //       return <div className="wrap">
  //         <CouponCard key={index} item={item} />
  //       </div>
  //     })}
  //   </div>
  // }
  const couponList = () => {
    return (
      <div
        className="coupon-list"
        style={{ maxHeight: 380, overflowY: "auto" }}
      >
        <div style={{ marginBottom: 4 }}>可用优惠券:</div>
        {availableCouponList?.map((item, index) => {
          return (
            <div className="wrap" key={index}>
              <div>
                满{formatMoney(item.limitAmount)}元减{formatMoney(item.amount)}
                元
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const ImagePreview = () => {
    return (
      <div>
        <div
          className="pre-image"
          style={{ backgroundImage: `url(${preImageUrl})` }}
          onClick={() => setVisible(true)}
        >
          <Image
            src={preImageUrl}
            style={{ display: "none" }}
            preview={{
              visible,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          ></Image>
          <div
            className="nav_ left_"
            onClick={(e) => {
              e.stopPropagation();
              imageNavPrev(preImageUrl);
            }}
          >
            <RiArrowLeftSLine></RiArrowLeftSLine>
          </div>
          <div
            className="nav_ right_"
            onClick={(e) => {
              e.stopPropagation();
              imageNavNext(preImageUrl);
            }}
          >
            <RiArrowRightSLine></RiArrowRightSLine>
          </div>
        </div>
        <div className="image-bottom">
          {detail && detail.imageList.length > 7 && (
            <div onClick={() => move("prev")} className="arrow-left arrow">
              <RiArrowLeftSLine />
            </div>
          )}
          <div ref={sliderRef} className="keen-slider">
            {detail?.imageList.map((item) => {
              return (
                <div
                  className={`image-item keen-slider__slide ${item === preImageUrl ? "active" : ""
                    }`}
                  key={item}
                  style={{ backgroundImage: `url(${item})` }}
                  onClick={() => selectImage(item)}
                ></div>
              );
            })}
          </div>
          {detail && detail.imageList.length > 7 && (
            <div onClick={() => move("next")} className="arrow-right arrow">
              <RiArrowRightSLine />
            </div>
          )}
        </div>
      </div>
    );
  };

  const tabs = [
    {
      key: 1,
      title: "详情",
      number: null,
    },
    {
      key: 2,
      title: "评论",
      number: detail?.commentCount,
    },
    {
      key: 3,
      title: "购买评价",
      number: tatal,
    },
    {
      key: 4,
      title: "购买须知",
    },
  ];

  const visitShop = (
    <Button
      size="small"
      className="btn visit-shop"
      onClick={() => {
        navigate(`/user/${detail?.userId}/shop`);
      }}
    >
      进店逛逛
    </Button>
  );

  const shareProduct = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    apiGetProductShareImage(Number(detail?.id)).then((res) => {
      setShareImageUrl(res.minaImage);
      setShareQrCose(res.minaQrCode);
      if (userAllInfo) {
        setShareUrl(
          window.location.origin +
          window.location.pathname +
          `?shareUserId=${userAllInfo.id}`
        );
      } else {
        setShareUrl(window.location.href);
      }
      setShowShare(true);
    });
  };

  useEffect(() => {
    getMallReview({ productId: Number(id) }).then((res) => {
      setTotal(res.totalSize);
    });
  }, [id]);

  const closeAddToFavorite = () => {
    setShowAddToFavorite(false);
    getDetail();
  };
  useEffect(() => {
    userAllInfo && detail && getUserCouponList();
  }, [currentSpecs?.quantity]);

  const getUserCouponList = () => {
    let price = undefined;
    if (currentSpecs?.quantity) {
      price = currentSpecs?.rmbPrice * count;
    }
    getUserAvailableCoupons({
      bizType: 100,
      productId: Number(id),
      productAmount: price,
    }).then((res) => {
      if (res.length > 0) {
        setAvailableCouponList(res);
      }
    });
  };
  return (
    <DetailStyle>
      {contextHolder}
      <div className="wrap">
        <div className="product-up-part border-box">
          <div className="left">
            <div className="image-wrap">{ImagePreview()}</div>
          </div>
          <div className="right">
            <div className="title">{detail?.name}</div>
            <div className="first-row">
              {currentSpecs && (
                <div className="item price">
                  <div className="label">商品价格</div>
                  <div className="value">
                    <div className="rmbPrice">
                      <span>￥</span>
                      <span>
                        {(currentSpecs?.rmbPrice / 100).toFixed(2) || 0}
                      </span>
                    </div>

                    {currentSpecs?.rmbPrice < currentSpecs?.originalPrice && (
                      <span className="originalPrice">
                        ￥{(currentSpecs?.originalPrice / 100).toFixed(2) || 0}
                      </span>
                    )}
                  </div>
                </div>
              )}

              {availableCouponList && availableCouponList?.length > 0 && (
                <Popover
                  content={couponList}
                  title=""
                  placement="right"
                  trigger="click"
                  open={openCouponList}
                  onOpenChange={(val: boolean) => {
                    setOpenCouponList(val);
                  }}
                >
                  <div className=" coupon">
                    <div className="label_">优惠</div>
                    <div className="more">
                      <RightOutlined />
                    </div>
                  </div>
                </Popover>
              )}
            </div>

            {
              !isYueGao && <div className="item">
                <div className="label">商品库存</div>
                <div className="value">{currentSpecs?.quantity}</div>
              </div>
            }
            <div className="item">
              <div className="label">商品属性</div>
              <div className="value">
                {detail?.skuList.map((item) => {
                  return (
                    <div
                      onClick={() => setCurrentId(item.id)}
                      className={`sku-item ${currentId === item.id ? "active" : ""
                        }`}
                      key={item.id}
                    >
                      <div
                        className="cover"
                        style={{ backgroundImage: `url(${item.coverUrl})` }}
                      />
                      <div className="name" title={item.name}>
                        {item.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="item">
              <div className="label">购买数量</div>
              <div className="value">
                <InputNumber
                  min={1}
                  value={count}
                  max={currentSpecs?.quantity}
                  defaultValue={1}
                  onChange={(e) => setCount(Number(e))}
                  style={{ width: 68 }}
                  disabled={detail?.type === 1}
                />
              </div>
            </div>

            {detail?.type === 2 && (
              <Space size={"large"}>
                <div className="item">
                  <div className="label">发货地：</div>
                  <div className="value" style={{ paddingLeft: 4 }}>
                    {detail.deliveryAddress}
                  </div>
                </div>
                <div className="item">
                  <div className="label">快递：</div>
                  <div className="value" style={{ paddingLeft: 4 }}>
                    {detail?.deliveryFeeTip}
                    {detail?.freeDelivery !== 1 && (
                      <Tooltip
                        placement="top"
                        title={renderTip(detail.shippingFeeTemplate)}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    )}
                  </div>
                </div>
                {
                  (detail.lastSendType === 0 || !!detail.lastSendType) && <div className="item">
                    {
                      detail.lastSendType < 3 && <div className="value" style={{ paddingLeft: 4 }}>
                        {DeliveryStatusArr.find(item => item.value === detail.lastSendType)?.label}发货
                      </div>
                    }

                    {
                      detail.lastSendType === 3 && <div className="value" style={{ paddingLeft: 4 }}>
                        预售 {dayjs(detail.lastSendTime).format("YYYY年MM月DD日 HH时")}前发货
                      </div>
                    }
                  </div>
                }
              </Space>
            )}
            <Space>
              {currentSpecs && currentSpecs?.quantity > 0 && (
                <Button
                  type="primary"
                  loading={loading}
                  onClick={pay}
                  className="btn"
                >
                  立即购买
                </Button>
              )}
              {currentSpecs && currentSpecs?.quantity === 0 && (
                <Button
                  type="primary"
                  className="btn"
                  disabled={currentSpecs?.quantity === 0}
                >
                  已售罄
                </Button>
              )}
              <Button
                icon={<StarOutlined />}
                className={`btn ${detail?.isFavorite === 1 ? "active" : ""}`}
                style={{ width: 120 }}
                onClick={() => {
                  if (!userAllInfo?.id) {
                    navigate(loginPathWithRedirect());
                    return;
                  }
                  setShowAddToFavorite(true);
                }}
              >
                {detail?.isFavorite === 1 ? "取消收藏" : "收藏"}
              </Button>
            </Space>

            <div className="specs-detail">
              {!!currentSpecs?.fileCount && currentSpecs?.fileCount > 0 && (
                <div>
                  <span>文件数量：{currentSpecs?.fileCount}</span>
                </div>
              )}
              {!!currentSpecs?.fileFormat && (
                <div>
                  <span>文件格式：{currentSpecs?.fileFormat}</span>
                </div>
              )}
              <div>
                <span>
                  商品类目：
                  {detail?.categoryList.find((item) => item.level === 2)?.name}
                </span>
              </div>
            </div>
            {/* <div className="tip">
              <div>购买须知</div>
              <div>
                若卖家无特别说明，则以预览图、商品描述展示为准。如有任何疑问请在购买前联系卖家咨询。资源类商品，一经售出，不退不换。
              </div>
            </div> */}
            <div className="tip">
              <div>版权说明</div>
              <div>在本站下载的素材，著作权归原作者所有。</div>
              <div>
                如需获得商业授权，购买前请咨询作者或者联系客服。未经书面授权或签订书面合同，不得以任何形式发行、发布、传播、复制、出租、转售、汇编该素材。<br />如无特别说明同人作品仅供学习交流使用，不可进行商用。
              </div>
            </div>
            {
              detail?.type === 1 && <div className="tip">
                <div>授权说明</div>
                <div>虚拟商品通常贩售内容为商品的使用权，授权范围由卖家制定，购买前请参考商品说明及咨询卖家确认商品的可使用范围。</div>
                <div>本站授权商品提供如下授权许可样式，详细说明请参见本站授权协议。</div>
                <div>个人使用授权许可：仅供个人使用、交流，不能在商业项目中使用。</div>
                <div>商业使用授权许可：除个人使用外，还能在商业项目中的使用。</div>
                <div>商业独家授权许可：一经出售卖家无法再出售、转让给他人的独家商业授权。</div>
              </div>
            }
          </div>
        </div>
        <div className="product-down-part">
          <div className="left-a border-box">
            <div className="top">
              <CommonTabs
                tabs={tabs}
                onClick={(tab) => {
                  setCurrentTabIndex(tab.key);
                }}
                defaultKey={currentTabIndex}
              ></CommonTabs>
              <div className="share" onClick={(e) => shareProduct(e)}>
                <a href="">
                  <RiShareForwardBoxLine></RiShareForwardBoxLine>
                  <span>分享商品</span>
                </a>
              </div>
            </div>
            {detail && (
              <div className="bottom-content">
                {currentTabIndex === 1 && (
                  <>
                    <RichTextContent detail={detail}></RichTextContent>

                    <div className="tags">
                      <ProductTags tagList={detail.tagList}></ProductTags>
                    </div>
                  </>
                )}
                {currentTabIndex === 2 && (
                  <ProductComment
                    detail={detail}
                    update={getDetail}
                  ></ProductComment>
                )}
                {currentTabIndex === 3 && (
                  <ProductReviewList></ProductReviewList>
                )}
                {currentTabIndex === 4 && (
                  <BuyerGuide></BuyerGuide>
                )}
              </div>
            )}
          </div>
          <div className="right-b">
            <ProductDetailCardComponent title="卖家信息">
              {detail?.userId && (
                <ProductSellerProfile
                  userId={detail?.userId}
                ></ProductSellerProfile>
              )}
            </ProductDetailCardComponent>
            <ProductDetailCardComponent title="TA的店铺" subTitle={visitShop}>
              {detail && <SellerProduct userId={detail?.userId} />}
            </ProductDetailCardComponent>
            {detail?.workList && detail?.workList.length > 0 && (
              <ProductDetailCardComponent title="关联作品">
                <RelatedWork list={detail.workList} />
              </ProductDetailCardComponent>
            )}
          </div>
        </div>
        {detail && isShow && (
          <PreSubmitModal
            detail={detail}
            getWeixinCode={getWeixinCode}
            isShow={isShow}
            onCancal={() => setIsShow(false)}
            buyCount={count}
            skuId={Number(currentSpecs?.id)}
          />
        )}

        <Modal
          className="modal"
          centered={true}
          footer={null}
          getContainer={false}
          onCancel={() => {
            setWeixinVisible(false);
            clearInterval(weixinTimer);
            setCountDownValue(0);
            logoutChat()
          }}
          open={weixinVisible}
        >
          {
            isYueGao && <div className="sub-title">订单已创建，约稿商品请和卖家协商后再进行支付</div>
          }
          {
            !isYueGao && <div className="title">请扫码进行微信支付</div>
          }
          <div className="code-wrap">
            {weixinCode && (
              <QRCode
                errorLevel="H"
                value={weixinCode}
                size={200} // 二维码的大小
                status={codeStatus}
                onRefresh={onRefreshCode}
              />
            )}
            {
              codeStatus !== 'expired' && countDownValue > 0 && <div className="code-time">二维码过期时间：<Countdown value={countDownValue} format="HH:mm:ss" /></div>
            }
          </div>
          {
            isYueGao && <div className="second-title">支付入口：个人中心-我的订单</div>
          }

        </Modal>
        <GlobalShare
          info={{
            title: "",
            cover_image: "",
            desc: "",
          }}
          shareImageInfo={{ qrCode: shareQrCode, shareImageUrl: shareImageUrl }}
          shareType="shop"
          isShow={showShare}
          shareUrl={shareUrl}
          onClose={() => {
            setShowShare(false);
          }}
        ></GlobalShare>
      </div>
      {showAddToFavorite && (
        <AddFavorite
          type="mall_product"
          FavoriteTypeDisabled={true}
          onClose={closeAddToFavorite}
          dataId={Number(id)}
        ></AddFavorite>
      )}
    </DetailStyle>
  );
};

export default Detail;
