import { GlobalSearchPostResponse } from "types/common"
import { PrizeIconsStyle } from "./style"
import { WorkListItem } from "types/work"
import { FavoriteDataListItem } from "types/favorite"
import { WorkContestListItem } from "types/contest"
import { Tooltip } from "antd"
import { WorkLikeListsItem } from "types/user/work"

function PrizeIcons({ item }: { item: GlobalSearchPostResponse | WorkListItem | FavoriteDataListItem | WorkContestListItem | WorkLikeListsItem }) {

  let prize = item.prizeList && item.prizeList[0]

  const jumpUser = (e: any) => {
    console.log(e)
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    window.open('/user/' + prize.userInfo.id);
  }
  return (
    <PrizeIconsStyle>
      {/* 获奖图标 */}
      {item.prizeList && item.prizeList.length > 0 && prize && prize.type === 2 && (
        <Tooltip title={prize.name}>
          {
            !prize?.listIcon && <div className="item stage-prize" >
              <span className="title">{prize.name}</span>

              <img className="default-icon" src="https://cdn-prd.ggac.com/ggac/assets/image/icon/trophy.svg" alt="" />
            </div>
          }
          {
            prize?.listIcon && <div className="item stage-prize" style={{ backgroundColor: "transparent", marginTop: 0 }}><img src={prize?.listIcon} alt="" width={38} /></div>
          }
        </Tooltip>
      )}

      {item.prizeList && item.prizeList.length > 0 && prize && prize.type === 1 && (
        <div className="item stage-prize" >
          <span className="title">{prize.name}</span>
          {prize.userInfo && <Tooltip title={prize.userInfo.username}>
            <img src={prize.userInfo.avatarUrl} alt={prize.userInfo.username} onClick={jumpUser} className="avatar" />
          </Tooltip>
          }
        </div>
      )}

    </PrizeIconsStyle>
  )
}

export default PrizeIcons