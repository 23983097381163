import styled from "styled-components";
export const UserDetailStyle = styled.div`
    .detail{
        padding: ${(props) => props.theme.space.padding}px;
        .btn{
            padding: ${(props) => props.theme.space.padding}px 0;
        }
    }
`

export const Part1Style = styled.div`
padding: ${props => props.theme.space.padding}px 0;
.userName{
    display: flex;
    align-items: center;
    button{
        margin-left: 15px;
        background-color: ${(props) => props.theme.colors.colorPrimary};
        color: #fff;
    }
}

.tag{
    margin-top: 15px;
    display: flex;
    
    .ant-space{
        margin: 0 10px;
    }
}
.career{
    margin-top: 5px;
    
}
.flex{
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    &-label{
        width: 100px;
        text-align: right;
        margin-top: 15px;
    }
}
`

export const Part2Style = styled.div`

`