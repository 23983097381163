import styled from "styled-components";

export const WorkContentStyle =styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 53px;
  /* overflow-y: auto; */
  padding-bottom: ${(props) => props.theme.space.paddingLG*2}px; 
 
  .header{
    display: flex;
    justify-content: space-between;
    width: 100%;
      .header-left{
        .header-top{
        display: flex;
        align-items: center;
        .title{
            font-size: 26px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1D1D1F;
            margin-right: 40px;
          }
      .meta{
        display: flex;
        align-items: center;
        justify-content: space-between;
      .roundIcon{
          border-radius: 50% 50%;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 100%;
          }
      }
      .icon{
        color:${props=> props.theme.colors.colorTextTertiary};
        display: flex;
        align-items: center;
        span{
          padding-left:${(props) => props.theme.space.paddingXXS}px;   
        }
      }
      
    }
    }
      .category{
        font-size: 14px;
        /* margin-top: 13px; */
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1D1F;
      }
    }

    .header-right{
      
    }
    
    
  }
  .desc{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1D1D1F;
    margin: 0 0 39px 0;
    word-break: break-all;
  }
  .html-content {
    width: 100%;
    margin: 0 auto;
    word-wrap: break-word;
    img {
      max-width: 100%;
      height: auto;
    }
    p {
      video {

      }
    }
  }
  .contentObject{
    width: 100%;
    .media{
      .maxWidth{
        width: 100%;
      }
      .fit{
        max-width:100%;
      }
      .view3d-wrapper{
        width: 880px;
        height: 400px;
      }
    }
    .caption-0{
      text-align: left;
      padding: ${props=> props.theme.space.paddingSM}px;
    }
    .caption{
      color: ${(props) => props.theme.colors.colorText};
      text-align: center;
      padding: ${props=> props.theme.space.paddingLG}px;
    }
  }

  audio {
    width: 500px;
    position: relative;
  }

 
`
