import { createGlobalStyle } from 'styled-components'


export const GlobalStyle = createGlobalStyle`

  .work-image-preview {
    .ant-image-preview-close {
      background-color:rgba(0,0,0,.5);
      top: 41px;
      padding: 10px;
    }
  }

  .ant-modal-confirm-btns {
    .ant-btn-default:hover {
      color: ${props => props.theme.colors.colorPrimary};
    }
  }

  .badge-modal {
    .ant-modal-content {
      padding: 0 !important;
    }
  }

  .ant-modal-wrap {
    .ant-modal-confirm-btns {
      .ant-btn-default:hover {
        border: 1px solid ${props => props.theme.colors.colorPrimary} !important;
      }
      .ant-btn-primary {
        background-color: ${props => props.theme.colors.colorPrimary} !important;
      }
    }
  }
  
  body,html{
    font-size: 14px;
    box-sizing: border-box;
    /* overflow-x:hidden; */
  }
  a{
    color: ${props => props.theme.colors.colorLinkDefault};
    &:hover{
      color: ${props => props.theme.colors.colorLinkHover};
    }
  }
  .a-like{
    color: ${props => props.theme.colors.colorLinkDefault} !important;
    &:hover{
      color: ${props => props.theme.colors.colorLinkHover} !important;
      cursor: pointer;
    }
  }
  div{
    box-sizing: border-box;
  }

  ul {
  list-style: none;
  margin: 0;
  padding: 0;
  }

  //弹性排列
  .flex{
    display: flex;
  }
  //弹性排列，水平居中
  .flex.jc{
    display: flex;
    justify-content: center;
  }
  //弹性排列，垂直居中
  .flex.ac{
    display: flex;
    align-items: center;
  }
  //弹性排列，水平垂直居中
  .flex.jac{
    display: flex;
    align-items: center;
    justify-content: center;
  }
   //弹性列表排列，水平居中
  .flex-column.ac{
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  //hack
  .ant-select-item-option-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  //文字超框隐藏
  .overflow-hidden {
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出容器的部分 */
  text-overflow: ellipsis; /* 使用省略号表示被隐藏的文本 */
  }

  .tox-statusbar__branding{
    display: none;
  }
  .PhotoView-Slider__BannerWrap{
  background: transparent !important;
}

.toolbar-wrapper{
    position: fixed;
    display: flex;
    gap: 22px !important;
    right: 87px;
    top: 41px;
    font-size: 21px;
  }
  .ant-image-preview-root .ant-image-preview-mask{
    background-color: rgba(0, 0, 0, 0.95) !important;
  }
  .ant-image-preview-root .ant-image-preview-img{
    max-height: 100% !important;
  }
.coupon-modal{
  .ant-modal-content{
        background-color: transparent !important;
        box-shadow: none;
    }
}
`
