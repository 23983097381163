import styled from "styled-components";

export const DetailHeaderStyle = styled.div`
width: 100%;
margin-top: 4px;
  .contest-header-container {
    /* top: 50px; */
   
    /* max-width: ${(props) => props.theme.space.maxContainer}px; */
    .contest {
      /* margin: ${(props) => props.theme.space.paddingLG}px; */
      margin-bottom: 0;
     
      /* background-color: #fff; */
      /* padding: ${(props) => props.theme.space.paddingLG}px
        ${(props) => props.theme.space.paddingLG}px; */
      display: flex;
      /* justify-content: space-between; */
      flex-direction: column;
      align-items: flex-end;
      font-size: ${(props) => props.theme.fontSizes.fontSize}px;
      .contest-info {
        display: flex;
        align-items: center;
        margin-top: 8px;
        color: #8A8A8B;
        font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
        .title {
          
          display: flex;
          align-items: center;
          gap: 1em;
          cursor: pointer;
          div {
            width: max-content;
          }
          .icon{
            color:${(props) => props.theme.colors.colorPrimary};
            font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
          }
        }

        .content-title {
          display: flex;
          gap: 5px;
        }

        .info-block {
          /* margin-left: ${(props) => props.theme.space.paddingLG}px; */
          .info-item {
            display: flex;
          }
          display: flex;
          div {
            width: max-content;
          }
          span {
            padding: 0 6px;
          }
        }
      }
      .stage {
        /* padding-right: ${(props) => props.theme.space.paddingXS}px; */
        display: flex;
        align-items: center;
        .prize-item {
          display: flex;
          align-items: center;
          background-color: ${(props) => props.theme.colors.colorPrimary};
          position: relative;
          height: 32px;
          border-radius: 32px 0 0 32px;
          cursor: pointer;
          margin-right:16px;
          margin-left: 4px;
          .name {
            padding: ${(props) => props.theme.space.paddingXXS}px
              ${(props) => props.theme.space.paddingLG * 1.2}px
              ${(props) => props.theme.space.paddingXXS}px
              ${(props) => props.theme.space.paddingSM}px;
            color: ${(props) => props.theme.colors.colorTextLightSolid};
          }
          .avatar {
            position: absolute;
            height: 34px;
            aspect-ratio: 1;
            border-radius: 50%;
            right: -17px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: ${(props) => props.theme.colors.colorPrimary};
          }
        }
        .prize-avatar{
            height: 34px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-left:4px;
            cursor: pointer;
        }
      }
    }
    .metadata {
      width: 100%;
    }
    .gpass {
      position: absolute;
      cursor: pointer;
      top: ${(props) => props.theme.space.margin + 50}px;
      right: ${(props) => props.theme.space.margin}px;
      .gpassImage {
        width: 80px;
      }
    }
  }
`;
