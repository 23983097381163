import styled from "styled-components";

export const ScoreWorkCardStyle = styled.div`
    .scoreInfo{
      padding: 4px;
      padding-right: 20px;
      background: linear-gradient(to right, black, transparent);
      .item{
        color: ${props => props.theme.colors.colorTextLightSolid};
        font-size: ${props => props.theme.fontSizes.fontSizeSM}px;
      }
      .score{
        font-weight: 800;
      }
    }
`


export const ScoreComponentStyle = styled.div`
  .btn {
    margin-top: ${props => props.theme.space.paddingXS}px;
  }
`

export const ModalStyle = styled.div`
  .container{
    display: flex;
    gap: ${props => props.theme.space.paddingLG}px;
    .left{
      margin-top: ${props => props.theme.space.marginLG}px;
      .cover{
        width: 160px;
        height: 140px;
        border-radius: ${props => props.theme.border.borderRadius}px;
        overflow: hidden;
        background-color: #000;
        background-size: cover;
        background-position: center;
      }
      .name{
        margin-top: ${props => props.theme.space.paddingXS}px;
        max-width: 180px;
      }
    }
    .right{
      padding-top: ${props => props.theme.space.paddingLG}px;
      flex:1;
        .score-widget{
          padding: ${props => props.theme.space.paddingSM}px 0;
         
        }
        .tip{
          span{color:red}
        }
        .review{
          padding: ${props => props.theme.space.paddingLG}px 0;
          display: flex;
          gap: 40px;
        }
    }
  }


`
