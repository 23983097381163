import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { VideoContentDisplayStyle } from "./style";
import Player from "griffith";

type Props = {
  url: string;
  cover?: string;
  from?: string
};

const VideoContentDisplay = forwardRef(({ url, cover,from }: Props, ref: any) => {
  const [videoRatio, setVideoRatio] = useState(1);
  const video = document.createElement('video');
  const getVideoDimensions = (url: string) => {



    video.addEventListener('loadedmetadata', function () {
      const width = this.videoWidth;
      const height = this.videoHeight;

      setVideoRatio(height / width);
    });

    video.src = url;

    // Load the video to trigger the 'loadedmetadata' event
    video.load();
  }

  useImperativeHandle(ref, () => ({
    pauseVideo: () => {
      const videoList: HTMLCollection = document.getElementsByTagName('video');
      if (videoList.length > 1) {
        [...videoList].forEach(item => {
          // item.currentTime = 0
          // item && item.pause()
          console.log(video)
        })
      }
    }

  }));

  useEffect(() => {
    getVideoDimensions(url)
  }, [])

  function isImageUrl(url: string) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];

    const fileExtension = url.slice(url.lastIndexOf('.')).toLowerCase();
    return imageExtensions.includes(fileExtension);
  }

  return (
    <VideoContentDisplayStyle>
      {<div style={{ margin: '0 auto' }}>
        <Player
          shouldObserveResize={true}
          cover={isImageUrl(cover!) ? cover : undefined}
          locale="zh-Hans"
          sources={{
            sd: {
              play_url: url,
            },
          }}
          loop={!!(from==='ly3d')}
          autoplay={!!(from==='ly3d')}
          id={"player"}
          initialObjectFit="contain"
        ></Player>
      </div>}

    </VideoContentDisplayStyle>
  );
})


export default VideoContentDisplay;
