import { RouteObject } from "react-router-dom";
import Layout from "views/layout";
import ScoreList from "views/score";


export const scoreRoutes:RouteObject[] = [

    {
        path: "/score",
        element: <Layout></Layout>,
        children: [
            {
                path: ":id/list",
                element: <ScoreList></ScoreList>,
              },
        ]

    }
]