import { get, post } from "api"
import { MediaDeleteRelationRequest, HomeUpdateTypeStatusRequest, MediaDeleteRelationResponse,WorkListRequest, WorkListItem, WorkDetailType,draftWorkDetailType, getHistoyReq, toRecommendWorkRequest, HomeTypeListItem, WorkCheckinDateRequest, WorkCheckinDateResponse, WorkSelectedListRequest, RecommendMonthlyPicksResponse, WorkNextIdResponse, WorkNextIdRequest, WorkAbleProductListRequest, WorkAbleProductListItem, WorkMineCollaborationListRequest, AllUserCreatorListRequest } from "types/work"
import { PaginationResponse } from 'types/common/axios'
import { WorkLikeRequest, WorkWallRequest, WorkLikeListsRequest, WorkLikeListsItem, GetWorkCategoryRequest, GetWorkCategoryResponse,  CreateWorkDraftRequest, UpdateWorkDraftRequest, WorkWallRespose, RecommendWorkListItem, WorkAbleAndTaResponse, AllUserCreatorListItem } from "types/user/work"
import { FavoriteDataListItem } from "types/favorite"


//返回id
export const apiCreateWorkDraft = async () => {
    return post<any, { id: number }>("/api/work_draft/create")
}

export const apiGetWorkDraftDetail = async (id: number) => {
    return get<{ id: number }, draftWorkDetailType>("/api/work_draft/detail/" + id)
}

export const apiRemoveMediaRelation = async (params: MediaDeleteRelationRequest) => {
    post<MediaDeleteRelationRequest, MediaDeleteRelationResponse>("/api/media/delete_relation", params)
}

export const deleteMedia = (id: number) =>
    post<number, never>(`/api/media/delete/${id}`)


export const getWorkList = async (params: WorkListRequest) => {
    return get<WorkListRequest, PaginationResponse<WorkListItem>>("/api/work/list", params)
}

// 作品、评论点赞
export const postWorkLikes = async (params: WorkLikeRequest) => {
    return post<WorkLikeRequest, {}>("/api/zan/flag", params)
}

// 获取用户作品点赞列表
export const getWorkLikeList = async (params: WorkLikeListsRequest) => {
    return get<WorkLikeListsRequest, PaginationResponse<WorkLikeListsItem>>("/api/like/data_list", params)
}


// 获取作品类目
export const getWorkCategory = (params: GetWorkCategoryRequest) => {
    return get<GetWorkCategoryRequest, GetWorkCategoryResponse>("/api/category/list", params)
}


// 创作草稿
export const createWorkDraft = (params: CreateWorkDraftRequest) => {
    return post<CreateWorkDraftRequest, { id: number }>("/api/work_draft/create", params)
}

// 创作草稿
export const updateWorkDraft = (params: UpdateWorkDraftRequest) => {
    return post<UpdateWorkDraftRequest, { id: number }>("/api/work_draft/update", params)
}

// 获取作品详情
export const getWorkDetail = async (id: number) =>
    get<{ id: number }, WorkDetailType>(`/api/work/detail/${id}`)


// 提交草稿
export const submitWorkDraft = (id: number) => {
    return post<number, never>(`/api/work_draft/submit/${id}`)
}

// 草稿详情
export const apiDraftWorkDetail = (id: number) =>
    get<{ id: number }, draftWorkDetailType>(`/api/work_draft/detail/${id}`)

// 历史记录
export const apiGetHistory = (params: getHistoyReq) =>
    get<getHistoyReq, PaginationResponse<FavoriteDataListItem>>(`/api/view/list`, params)

// 图墙
export const apiGetWorkWall = (params: WorkWallRequest) =>
    get<WorkWallRequest, WorkWallRespose>(`/api/recommend/work_wall`, params)

// 作品推荐
export const postToRecommendWork = (params: toRecommendWorkRequest) =>
    post<toRecommendWorkRequest, {}>("/api/recommend/create", params)



// 获取作品点赞列表
export const getRecommendWorkLikeList = async (params: {
    pageNumber: number,
    pageSize: number,
    categoryId?: number
    needTotal?: number
    tagId?: number
}) => {
    return get<{ pageNumber: number, pageSize: number }, PaginationResponse<RecommendWorkListItem>>("/api/recommend/work_list", params)
}

// 首页类目列表
export const getHomeTypeList = (params: { name?: string }) =>
    get<{ name?: string }, HomeTypeListItem[]>(`/api/mix_tag/mine`, params)

// 修改首页类目桩体
export const PostUpdateTypeStatus = (params: HomeUpdateTypeStatusRequest) =>
    post<HomeUpdateTypeStatusRequest, {}>(`/api/mix_tag/mine_flag`, params)

// 作品-相关作品和ta的作品
export const getAbleAndTaList = (params: { id: number, categoryId: number }) =>
    get<{ id: number, categoryId: number }, WorkAbleAndTaResponse>(`/api/work/able_and_ta`, params)

// 作品-相关作品和ta的作品
export const getWorkCheckinDays = (params: WorkCheckinDateRequest) =>
    get<WorkCheckinDateRequest, WorkCheckinDateResponse>(`/api/work/checkin_date`, params)

// 作品 精选作品
export const getWorkSelectedList = (params: WorkSelectedListRequest) =>
    get<WorkSelectedListRequest, PaginationResponse<WorkListItem>>(`/api/work/selected_list`, params)

// 获取作品分享图
export const getWorkShareImage = (id: number) =>
    post<{ id: number }, { qrCode: string, shareImageUrl: string }>(`/api/work/share_image/${id}`)

// 每月推荐
export const getMothlyPicks = () => 
    get<{}, RecommendMonthlyPicksResponse>(`/api/recommend/monthly_picks`)


//作品详情页获取下一个作品    

export const getNextWork = (params:WorkNextIdRequest) => 
    post<WorkNextIdRequest, WorkNextIdResponse>(`/api/work/next_id`,params)


//作品关联商品列表
export const getWorkRelatedProductList = (params:WorkAbleProductListRequest) => 
    get<WorkAbleProductListRequest,WorkAbleProductListItem[]>(`/api/work/able_product_list/${params.id}`)


//作品-授权商品列表
export const getWorkAuthorizedProductList = (params:WorkAbleProductListRequest) => 
    get<WorkAbleProductListRequest,WorkAbleProductListItem[]>(`/api/work/authorized_product_list/${params.id}`)

//复制作品内容到富文本
export const importContentFromWork = (params:{id:number}) =>    
  get<{id:number}, string>(`/api/mall_product/work_info/${params.id}`)


// 作品-共创者列表
export const apiGetWorkCreatorList = (params: AllUserCreatorListRequest) =>
    get<AllUserCreatorListRequest, AllUserCreatorListItem[]>(`/api/all_user/creator_list/`,params)


// 作品-共创者列表
export const apiGetUserCollaborativeWorkList = (params: WorkMineCollaborationListRequest) =>
    get<WorkMineCollaborationListRequest, WorkListItem[]>(`/api/work/mine_collaboration_list`,params)

