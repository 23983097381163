import { OrderPreCreateMallResponse, PublicProductDetail } from "types/product";
import { PreSubmitModalStyle } from "./style";
import {
  Button,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  message,
  Spin,
  Popover,
} from "antd";
import { useEffect, useState } from "react";
import { PayType } from "types/enums/commodity-pay-type";
import { UserAddressItem } from "types/user/address";
import { apiGetUserAddressList, apiPostDeleteAddress } from "api/user/address";
import { postCreateMall, postPreCreateMall } from "api/shop";
import {
  apiPostAliPayCommodity,
  apiPostWeixinPayCommodity,
} from "api/commodity";
import { useLocation, useNavigate } from "react-router-dom";
import AddressPopup from "../../components/address-popup";
import { formatMoney } from "views/user-shop/productManage/create/fn";
import { getUserAvailableCoupons } from "api/user";
import { CouponCanUseListItem } from "types/user";
import { DoubleRightOutlined } from '@ant-design/icons';
import CouponCard from "components/coupon-card";
import { initChatService, orderCreateSendMessage } from "utils/functions/chat";


type Props = {
  onCancal: () => void;
  detail?: PublicProductDetail;
  isShow: boolean;
  getWeixinCode: (code: string, orderNumber: string) => void;
  buyCount: number;
  skuId: number;
};
const PreSubmitModal = ({
  onCancal,
  isShow,
  detail,
  getWeixinCode,
  buyCount,
  skuId,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [payType, setPayType] = useState(1);
  const [addressList, setAddressList] = useState<UserAddressItem[]>([]);
  const [addressValue, setAddressValue] = useState(0);
  const [isShowAddressPopup, setIsShowAddressPopup] = useState(false);
  const [updateAddressId, setUpdateAddressId] = useState(0);
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = useState(true);
  const [availableCouponList, setAvailableCouponList] = useState<CouponCanUseListItem[]>();
  const [openCouponList, setOpenCouponList] = useState(false)
  const channelCode =
    new URLSearchParams(useLocation().search).get("channelCode") || "";
  const [preOrderDetail, setPreOrderDetail] =
    useState<OrderPreCreateMallResponse>();
  const payList = [
    {
      name: "微信支付",
      image: "https://cdn.ggac.com/img/copyright-pay-wx.png",
      id: 1,
    },
    {
      name: "支付宝",
      image: "https://cdn.ggac.com/img/copyright-pay-zfb.png",
      id: 2,
    },
  ];

  const getAddress = () => {
    apiGetUserAddressList().then((res) => {
      setAddressList(res);
      const value = res.find((item) => item.isDefault === 1);
      if (value && value.id && detail?.type === 2) {
        setAddressValue(value?.id);
      }
    });
  };

  useEffect(() => {
    getAddress();

  }, [isShow]);

  useEffect(() => {
    if (openCouponList) {
      getUserCouponList();
    }
  }, [openCouponList]);

  useEffect(() => {
    getPreOrderDetail({});
  }, [addressValue]);

  useEffect(() => {
    if (!detail) {
      return
    }
    if (detail.categoryList[0].code === 'yuegao') {
      initChatService(detail.sellerUserId)
    }
  }, [detail])

  const getUserCouponList = () => {
    console.log(preOrderDetail, preOrderDetail?.productAmount)

    preOrderDetail && getUserAvailableCoupons({ bizType: 100, productId: detail?.id, productAmount: preOrderDetail?.productAmount }).then((res) => {
      if (res.length > 0) {
        setAvailableCouponList(res);
      }
    });
  }

  const getPreOrderDetail = ({ couponCode }: { couponCode?: string }) => {
    setLoading(true);
    postPreCreateMall({
      couponCode: couponCode,
      num: buyCount,
      skuId: skuId,
      shippingAddressId: Boolean(addressValue) ? addressValue : undefined,
    })
      .then((res) => {
        setLoading(false);
        setPreOrderDetail(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (e: RadioChangeEvent) => {
    setAddressValue(e.target.value);
  };

  // 支付
  const pay = async (orderNumber: string) => {
    setLoading(true);
    if (PayType.COMMODITY_PAY_WEIXIN === payType) {
      const payDetail = await apiPostWeixinPayCommodity(String(orderNumber));
      getWeixinCode(payDetail.code_url, orderNumber);
    } else if (PayType.COMMODITY_PAY_ALI === payType) {
      // navigate(`/product/detail/${detail?.id}?orderNumber=${orderNumber}`)
      const payDetail = await apiPostAliPayCommodity({
        orderNumber: String(orderNumber),
        returnUrl: `user-center/shop/order/detail/${orderNumber}?from=ali`,
      });
      if (detail?.categoryList[0].code === 'yuegao') {
        Modal.confirm({
          title: '提示',
          content: <>
            <div>订单已创建，约稿商品请和卖家协商后再进行支付</div>
            <div>支付入口：个人中心-我的订单</div>
          </>,
          okText: "继续支付",
          cancelText: "取消",
          centered: true,
          onOk() {
            AliPay(payDetail)
          },
        });
      } else {
        AliPay(payDetail)
      }
    }
    setLoading(false);
  };

  const AliPay = async (payDetail: any) => {
    var form = payDetail.body;
    const div = document.createElement("div");
    div.innerHTML = form; //此处form就是后台返回接收到的数据
    document.body.appendChild(div);
    document.forms[0].submit();
  }

  const toPay = () => {
    if (!preOrderDetail) return;
    // 创建订单
    const params: any = {
      num: preOrderDetail.products[0].num,
      skuId: preOrderDetail.products[0].skuId,
      couponCode: preOrderDetail.couponCode
    };
    if (addressValue) {
      params.shippingAddressId = addressValue;
    }
    if (channelCode) {
      params.channelCode = channelCode;
    }
    postCreateMall(params)
      .then((res) => {
        if (preOrderDetail.amount === 0) {
          if (preOrderDetail && preOrderDetail.products[0].isPrivateInquiry) {
            message.success("提交成功");
          } else {
            message.success("支付成功");
          }
          navigate(`/user-center/shop/order/detail/${res.orderNumber}`);
          return;
        }
        pay(res.orderNumber);
        if (detail?.categoryList[0]?.code === 'yuegao') {
          orderCreateSendMessage(res)
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onAddressCancal = () => {
    setIsShowAddressPopup(false);
  };

  const updateAddress = (e: any, id: number) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShowAddressPopup(true);
    setUpdateAddressId(id);
    setIsCreate(false);
  };

  const addAddress = () => {
    setIsShowAddressPopup(true);
    setIsCreate(true);
  };

  const deleteAddress = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    // 删除地址
    Modal.confirm({
      title: "确定删除该地址？",
      content: "删除后无法找回",
      okText: "确定",
      cancelText: false,
      onOk: async () => {
        await apiPostDeleteAddress([Number(id)]);
        message.success("删除成功");
        getAddress();
      },
    });
  };

  const couponList = () => {
    return <div className="coupon-list" style={{ maxHeight: 380, overflowY: 'auto' }}>
      {availableCouponList?.map((item, index) => {

        return <div className="wrap" onClick={() => handleOpenCouponListChange(item)}>
          <CouponCard key={index} item={item} isActive={item.code === preOrderDetail?.couponCode ? true : false} />
        </div>
      })}
    </div>
  }

  const handleOpenCouponListChange = (item: CouponCanUseListItem) => {
    setOpenCouponList(false);
    if (item.code === preOrderDetail?.couponCode) {
      getPreOrderDetail({ couponCode: "-1" });
    } else {
      getPreOrderDetail({ couponCode: item.code });
    }

  }

  // useEffect(()=>{
  //   if(openCouponList && availableCouponList && preOrderDetail){
  //     let arr = [...availableCouponList]
  //     var index = arr.findIndex(function(element) {
  //       return element.code === preOrderDetail.couponCode;
  //     });
  //     if (index !== -1) {
  //       // Remove the element from its current position
  //       var element = arr.splice(index, 1)[0];

  //       // Add the element to the first position
  //       arr.unshift(element);
  //       setAvailableCouponList(arr);
  //     }

  //   }
  // },[openCouponList,preOrderDetail])

  return (
    <PreSubmitModalStyle>
      <Modal
        className="modal"
        centered={true}
        footer={null}
        onCancel={onCancal}
        getContainer={false}
        open={isShow}
        width={600}
      >
        {loading && (
          <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
        )}
        <div className="item">
          <div className="label">商品属性：</div>
          <div className="value">
            {preOrderDetail &&
              preOrderDetail.products.map((item) => {
                return (
                  <div key={item.skuId} className="sku-item active">
                    <div
                      className="cover"
                      style={{ backgroundImage: `url(${item.skuImage})` }}
                    />
                    <div className="name">{item.skuName}</div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="item">
          <div className="label">购买数量：</div>
          <div className="value">
            {preOrderDetail && preOrderDetail.products[0].num}
          </div>
        </div>
        {preOrderDetail && (preOrderDetail.products[0].isPrivateInquiry === 0) && (
          <Space size="large">
            <div className="item">
              <div className="label">商品金额：</div>
              <div className="value">
                {formatMoney(preOrderDetail.productAmount)}元
              </div>
            </div>
            {preOrderDetail?.deliveryFee > 0 && (
              <div className="item">
                <div className="label">运费：</div>
                <div className="value">
                  {formatMoney(preOrderDetail.deliveryFee)}元
                </div>
              </div>
            )}
            {preOrderDetail?.couponCode && preOrderDetail?.couponCode !== '-1' && (
              <Popover content={couponList} title="选择优惠券" placement="right" trigger="click"
                open={openCouponList}
                onOpenChange={(val: boolean) => { setOpenCouponList(val) }}>
                <div className="item coupon">
                  <div className="label">优惠券：</div>
                  <div className="value" style={{ color: 'red' }}>
                    - {formatMoney(preOrderDetail?.discountAmount)}元
                  </div>
                  <div className="more"><DoubleRightOutlined /></div>
                </div>
              </Popover>
            )}
            {preOrderDetail?.couponCode === '-1' && (
              <Popover content={couponList} title="选择优惠券" placement="right" trigger="click"
                open={openCouponList}
                onOpenChange={(val: boolean) => { setOpenCouponList(val) }}>
                <div className="item coupon">
                  <div className="label">优惠券：</div>
                  <div className="value">
                    不使用
                  </div>
                  <div className="more"><DoubleRightOutlined /></div>

                </div>
              </Popover>
            )}

          </Space>
        )}

        {preOrderDetail && (preOrderDetail.products[0].isPrivateInquiry === 1) && <div className="item">
          <div className="label">商品金额：</div>
          <div className="value">授权商品，请拍下后与卖家确认价格，待改价后再行支付</div>
        </div>}

        <div className="item">
          <div className="label">实付金额：</div>
          <div className="value">
            ￥
            {(preOrderDetail && (preOrderDetail.amount / 100).toFixed(2)) || 0}
          </div>
        </div>
        {preOrderDetail && preOrderDetail.amount !== 0 && (
          <div className="item">
            <div className="label">支付方式：</div>
            <div className="value">
              <div className="pay">
                {payList.map((item) => {
                  return (
                    <div
                      onClick={() => setPayType(item.id)}
                      className={`pay-item ${payType === item.id ? "active" : ""
                        }`}
                      key={item.id}
                    >
                      <img src={item.image} alt="" />
                      <div className="name">{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {detail?.type === 2 && (
          <div className="item">
            <div className="label">收货地址：</div>
            <div className="value">
              <div
                className="address"
                style={{
                  display: addressList.length === 0 ? "flex" : "block",
                  alignItems: "center",
                }}
              >
                <Radio.Group onChange={onChange} value={addressValue}>
                  {addressList.map((item) => {
                    return (
                      <div className="address-item" key={item.id}>
                        <Radio value={item.id} className="radio">
                          <div className="receiver">
                            <div className="left">
                              {" "}
                              {item.receiver}
                              <span
                                style={{ marginLeft: "5px" }}
                                className="phone"
                              >
                                {item.phone}{" "}
                              </span>
                            </div>
                            <div className="right">
                              {!!item.isDefault && (
                                <div className="tag yellow">默认地址</div>
                              )}
                              <div
                                className="tag color"
                                onClick={(e) => updateAddress(e, item.id)}
                              >
                                修改
                              </div>
                              <div
                                className="tag color"
                                onClick={(e) => deleteAddress(e, item.id)}
                              >
                                删除
                              </div>
                            </div>
                          </div>
                          <div className="desc">{item.fullAddress}</div>
                        </Radio>
                      </div>
                    );
                  })}
                </Radio.Group>
                {addressList.length < 5 && (
                  <div className="add-address" onClick={addAddress}>
                    添加地址
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {
          !(preOrderDetail && preOrderDetail.products[0].isPrivateInquiry) && <Button
            className="btn"
            type="primary"
            onClick={toPay}
            disabled={!addressValue && detail?.type === 2}
          >
            立即支付
          </Button>
        }
        {
          !!(preOrderDetail && preOrderDetail.products[0].isPrivateInquiry) && <Button
            className="btn"
            type="primary"
            onClick={toPay}
            disabled={!addressValue && detail?.type === 2}
          >立即提交</Button>
        }
      </Modal>
      <AddressPopup
        updateList={getAddress}
        isCreate={isCreate}
        onCancal={onAddressCancal}
        addressId={updateAddressId}
        isShow={isShowAddressPopup}
        onSave={() => getPreOrderDetail({})}
      />
    </PreSubmitModalStyle>
  );
};

export default PreSubmitModal;
