import { RouteObject } from "react-router-dom";
import ProductList from "views/user-shop/productManage";
import Create from "views/user-shop/productManage/create";
import OrderList from "views/user-shop/order/index"
import ProductDetail from 'views/user-shop/productManage/detail'
import OrderDetail from 'views/user-shop/order/detail'
import UserCenterLayout from "views/user-center/layout";
import Layout from "views/layout";
import PublicProductDetail from "views/user-center/product/detail"
import ShippingTemplate from "views/user-shop/shipping-template";
import EditShippingTemplate from "views/user-shop/shipping-template/edit";


export const shopRoutes: RouteObject[] = [

    {
        path: "/",
        element: <Layout></Layout>,
        children: [
            {
                path: '/user-center/shop',
                element: <UserCenterLayout></UserCenterLayout>,
                children: [
                    {
                        path: 'product-list',
                        element: <ProductList></ProductList>
                    },
                    {
                        path: 'product/update/:id',
                        element: <Create></Create>
                    },
                    {
                        path: 'product/create',
                        element: <Create></Create>
                    },
                    {
                        path: 'order-list',
                        element: <OrderList></OrderList>
                    },
                    {
                        path: 'seller-order/detail/:orderNumber',
                        element: <OrderDetail type="seller"></OrderDetail>
                    },
                    {
                        path: 'order/detail/:orderNumber',
                        element: <OrderDetail type="buyer"></OrderDetail>
                    },
                    {
                        path: 'shipping-template-list',
                        element: <ShippingTemplate ></ShippingTemplate>
                    },
                    {
                        path: 'shipping-template/create',
                        element: <EditShippingTemplate ></EditShippingTemplate>
                    },
                    {
                        path: 'shipping-template/update/:id',
                        element: <EditShippingTemplate ></EditShippingTemplate>
                    }
                    
                ]
            },
            // {
            //     path: '/user/:id/shop',
            //     element: <UserFrontPageLayout></UserFrontPageLayout>,
            //     children: [
            //         {
            //             path: 'product-list',
            //             element: <PublicProductList></PublicProductList>
            //         },
            //     ]
            // },
            {
                path: 'user-center/product/detail/:productId',
                element: <ProductDetail></ProductDetail>
            },
            {
                path: 'product/detail/:productId',
                element: <PublicProductDetail></PublicProductDetail>
            }
        ]
    }

]