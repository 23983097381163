import styled from 'styled-components'


export const CreateStyle = styled.div`
background-color: ${props => props.theme.colors.colorBgContainer};
margin: ${props => props.theme.space.marginXS}px 0;
.flex{
  display: flex;
  align-items: center;
}
.tips{
  color: ${props => props.theme.colors.colorTextTertiary};
  display: block;
}
.lastSendType {
  display: flex;
  align-items: center;
  width: 605px;
  .last-date {
    margin-bottom: 0;
  }
}

.yuegao {
  font-weight: bold;
  margin-bottom: 10px !important;
}

.ant-checkbox-group {
  row-gap: 5px;
  .ant-checkbox-wrapper  {
    min-width: 90px;
  }
}

.content{
  display: flex;
  .create{
    padding:${props => props.theme.space.paddingLG * 2}px 0;
    margin: 0 60px;
    
    .ant-input-number{
      width: 200px;
    }
    .title{
        font-weight: bold;
        font-size: ${props => props.theme.fontSizes.fontSizeHeading5}px;
        margin-bottom: ${props => props.theme.space.margin}px;
        cursor: pointer;
    }
    .btn{
        width: 100px;
        height: 30px;
        line-height: 30px;
        background-color: ${props => props.theme.colors.colorPrimary};
        color: #fff;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          padding-left:${props => props.theme.space.paddingXXS}px;
        }
    }
    .btn2{
        margin: 15px auto 0;
    }
    .button-wrap{
      display: flex;
      justify-content: center;
      padding-top: 20px;
      button {
        padding:  0 ${props => props.theme.space.paddingLG}px;
        width: 280px;
      }
    }
  }

  .agreement-tip {
    text-align: center;
    h6 {
      color: ${props => props.theme.colors.colorPrimary};
      margin: 0;
      display: inline-block;
      font-size: 13px;
    }
  }

  .border {
    border: 1px solid ${props => props.theme.colors.colorPrimary};
    padding: 20px;
    position: absolute;
    left: 46vw;
    top: 155px;
    width: 450px;
    .title {
      font-weight: bold;
      font-size: 13px;
      margin-top: 10px;
    }
    .table {
      border: 1px solid #000;
      margin-top: 5px;
      &-item {
        display: flex;
        
        &:first-child {
          background-color: #000;
          color: #fff;
        }
        font-size: 13px;
        div {
          flex: 1;
          text-align: center;
          border-right: 1px solid #000;
          border-top: 1px solid #000;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .description .ant-row {
    display: block !important;
  }
  .work-wrap {
    margin-top: ${props => props.theme.space.margin}px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    .work-item {
      width: 100px;
    }
    .cover {
      width: 100px;
      height: 100px;
      background-size: cover;
      position: relative;
      .warning{
        position: absolute;
        right:  4px;
        bottom: 2px;
        color: ${props => props.theme.colors.colorPrimary};
        cursor: pointer;
      }
    }
    .name {
        text-align: center;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
  }
}

.tag-list-wrapper{
  .title{
    font-size:  ${props => props.theme.fontSizes.fontSizeSM}px !important;
  }
}
  
`