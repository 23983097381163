function BuyerGuide() {
  return (
    <div>
      <h4>实物商品发货</h4>
      <p>由商品发布者自行包装、发货。发货时间、物流情报请以发布者说明为准。</p>

      <h4>虚拟商品发货</h4>
      <p>交易成立后可在PC端个人中心-我的订单-查看详情中获取。</p>

      <h4>商品品相</h4>
      <p>
        若商品发布者无特别说明，请以预览图、商品描述展示为准。如有任何疑问可在购买前联系发布者咨询。
      </p>

      <h4>支付方式</h4>
      <p>本站支持支付宝、微信付款。</p>

      <h4>退货换货</h4>
      <p>实物商品退换标准和方案请以商品发布者说明为准。</p>
      <p>资源商品，一经售出无法退换。</p>
    </div>
  );
}

export default BuyerGuide;
