import styled from "styled-components";

export const SuperInteractionStyle = styled.div`
        margin: ${(props)=> props.theme.space.marginLG}px 0;
        .ant-btn-primary{
            &:disabled{
                border: 1px solid #D9D9D9;
                color: #202020;
                background-color: #fff;
            }
        }

`