import { orderDetailResponse, SellerOrderListResponse } from "types/product"
import { UpadtePriceStyle } from "./style"
import { formatMoney } from "views/user-shop/productManage/create/fn"
import { Button, InputNumber, message } from "antd"
import { useEffect, useState } from "react"
import { updateOrderAmount } from "api/shop"

const UpadtePrice = ({ detail, refreshOrder }: { detail: SellerOrderListResponse | orderDetailResponse, refreshOrder: () => void }) => {
    const [price, setPrice] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const onChange = (e: number) => {
        setPrice(e)
    }

    useEffect(() => {
        setPrice(Number(formatMoney(detail.amount)))
    }, [detail.amount])

    const submit = () => {
        setIsLoading(true)
        updateOrderAmount({ orderNumber: detail.orderNumber, amount: price }).then(res => {
            message.success("修改成功")
            refreshOrder()
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return <UpadtePriceStyle>
        <div>当前价格：{formatMoney(detail.amount)}</div>
        <div style={{ margin: "15px 0" }}>改后价格<InputNumber style={{ margin: "0 8px" }} min={0} max={100000} value={price} onChange={(e) => onChange(Number(e))} />元</div>
        <div style={{ textAlign: "center", marginTop: "20px" }}><Button onClick={submit} size="middle" type="primary" loading={isLoading}>提交改价</Button></div>
    </UpadtePriceStyle>
}

export default UpadtePrice