import { Input } from "antd"
import { MallSelectStyle } from "./style"
import { queryUrl } from "utils/functions/common"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { RiArrowDownSLine, RiCloseCircleLine, RiCloseLine } from "react-icons/ri"

const MallSelect = ({ option }: { option: { name: string, id: string }[] }) => {

    const navigate = useNavigate()
    const themeStyle = new URLSearchParams(useLocation().search).get("themeStyle")
    const [themeStyleArr, setthemeStyleArr] = useState<string[]>([])
    const [themeStyleLableArr, setThemeStyleLableArr] = useState<string[]>([])
    const ref = useRef() as React.MutableRefObject<any>;
    const [isShow, setIsShow] = useState(false)

    const changeThemeStyle = (e: string) => {
        if (themeStyleArr.includes(e)) {
            const index = themeStyleArr.findIndex(item => item === e)
            themeStyleArr.splice(index, 1)
        } else {
            themeStyleArr.push(e)
        }
        const query = queryUrl({ themeStyle: themeStyleArr.join(",") || "", pageNumber: "" })
        navigate(`?${query}`)
    }

    useEffect(() => {
        const splitValue = themeStyle ? themeStyle.split(",") : []
        setthemeStyleArr(splitValue)
        setThemeStyleLableArr([])
        option.forEach(item => {
            if (!!splitValue.find(sub => sub == item.id)) {
                // themeStyleLableArr.push(item.name)
                setThemeStyleLableArr(value => [...value, item.name])
            }
        })
    }, [themeStyle])

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const clear = () => {
        const query = queryUrl({ themeStyle: "", pageNumber: "" })
        navigate(`?${query}`)
    }

    return <MallSelectStyle>
        <div className="search-wrap" ref={ref}>
            <div className="input-wrap">
                <Input onClick={() => setIsShow(true)} placeholder="请选择题材风格" value={themeStyleLableArr.length > 0 ? themeStyleLableArr.join(`、`) : ""} style={{ cursor: "pointer" }} readOnly></Input>
                <div className="icon">
                    {
                        themeStyleLableArr && themeStyleLableArr.length > 0 && <RiCloseCircleLine size={15} onClick={clear} />
                    }
                    <RiArrowDownSLine onClick={() => setIsShow(true)} size={20} />
                </div>
            </div>
            {
                isShow && <div className="popup-wrap">
                    <div className="popup">
                        {
                            option.map(item => {
                                return <div onClick={() => changeThemeStyle(item.id)} className={`popup-item ${themeStyleArr.includes(item.id) ? 'active' : ''}`} key={item.id}>{item.name}</div>
                            })
                        }
                    </div>
                </div>
            }

        </div>
    </MallSelectStyle>
}

export default MallSelect