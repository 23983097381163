import {
  Avatar,
  Badge,
  Form,
  Modal,
  Spin,
  Input as AInput,
  Button,
  Radio,
  UploadFile,
  Empty,
} from "antd";
import { ChatRoomStyle } from "./style";
import TencentCloudChat, { ChatSDK, Message } from "@tencentcloud/chat";
import {
  apiCreateOrderQADetail,
  apiGetChatHistory,
  apiGetOrderQADetail,
  apiRegisterImUser,
  getIMLoginInfo,
  getImAppid
} from "api/im";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Input } from "react-chat-elements";
import EmojiPicker from "components/emoji-picker";
import { emojiParse } from "utils/functions/common";
import { RiFolder3Line, RiImage2Line } from "react-icons/ri";
import TIMUploadPlugin from "tim-upload-plugin";
import { MessageItem } from "./components/messageItem";
import { PicturesWall } from "components/uploadImageList";
import { getFileUrlByCosUpload } from "config/cos";
import { getRandomKey } from "config/utils/randomKey";
import { useDispatch } from "react-redux";

import { fireUnreadUpdateEvent } from "utils/store/reducer/im";

var chat: ChatSDK;

type OrderQAForm = {
  description: string;
  imageList: UploadFile[];
  orderNumber: string;
  type: number;
};

export const ChatRoom = (props: {
  show: boolean;
  close: () => void;
  toUserId?: number;
  orderNumber?: string;
}) => {
  const msgRef = useRef<any>(null);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [msgList, setMsgList] = useState<any[]>([]);
  const [conversationList, setConversationList] = useState<any>([]);
  const [conversationID, setConversationID] = useState<string>("");
  const [nextReqMessageID, setNextReqMessageID] = useState<string>("");
  const [hasMore, setHasMore] = useState(true);
  const [toUserId, setToUserId] = useState<string>(
    props.toUserId ? props.toUserId + "" : ""
  );
  const [hasHistory, setHasHistory] = useState(true);
  const [msgTime, setMsgTime] = useState<number>(new Date().getTime());
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [qaShow, setQAShow] = useState(false);
  const [form] = Form.useForm<OrderQAForm>();
  const [incomingMsgs, setIncomingMsgs] = useState<Message[]>();


  const dispatch = useDispatch();



  // ready后查询聊天信息初始化会话列表
  const onSdkReady = useCallback((event: any) => {
    //监听会话列表更新，需要SDK Ready后才能调用，ready的时候会自动触发一次
    //比较奇怪的是，这个事件会在收到 对方消息后，会触发2次

    console.log(1111)

    chat.on(
      TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED,
      onConversationListUpdated
    );
    chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);

    //如果传了UserId
    if (toUserId) {
      //注册用户（没有会新建）  
      apiRegisterImUser(Number(toUserId)).then((res) => {
        //如果没有会话，这个方法可以创建对话
        chat.getConversationProfile('C2C' + toUserId);
      });
    }

  }, [toUserId])
  const initChatService = useCallback(async () => {
    //初始化
    await getImAppid().then((appIdRes) => {
      let options = {
        SDKAppID: appIdRes.appId, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
      };

      // if(!chat) {
      // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
      chat = TencentCloudChat.create(options); // SDK 实例通常用 tim 表示
      // }

      // 设置 SDK 日志输出级别，详细分级请参见 <a href="https://web.sdk.qcloud.com/im/doc/en/SDK.html#setLogLevel">setLogLevel 接口的说明</a>
      chat.setLogLevel(1); // 普通级别，日志量较多，接入时建议使用
      // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
      chat.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
      //登陆
      //获取自己IM信息
      getIMLoginInfo().then((res) => {
        console.log(res.userId === Number(chat.getLoginUser()))
        // if (res.userId === Number(chat.getLoginUser())) {
        //   console.log(2222)
        //   chat.on(TencentCloudChat.EVENT.SDK_READY, onSdkReady);
        //   return
        // }
        let promise = chat.login({
          userID: res.userId + "",
          userSig: res.userSign,
        });
        promise
          .then(function (imResponse) {
            console.log("自己登录成功", imResponse.data); // 登录成功
            if (imResponse.data.repeatLogin === true) {
              // 标识账号已登录，本次登录操作为重复登录。
              console.log(imResponse.data.errorInfo);
            }
          })
          .catch(function (imError) {
            console.warn("login error:", imError); // 登录失败的相关信息
          });
      });

      chat.on(TencentCloudChat.EVENT.SDK_READY, onSdkReady);

    });


  }, [onSdkReady])

  useEffect(() => {
    if (props.show) {
      //初始化SDK，生成chat单例
      initChatService()
    }
  }, [props.show, initChatService]);


  useEffect(() => {
    if (props.orderNumber) {
      setLoading(true);
      apiGetOrderQADetail(props.orderNumber)
        .then((res) => {
          setQAShow(true);
          const imageList = res?.imageList;
          const arr: UploadFile[] = [];
          imageList?.forEach((item, index) => {
            arr.push({
              uid: String(index),
              url: item,
              name: item,
            });
          });
          form.setFieldsValue({
            orderNumber: res.orderNumber, //订单编号
            description: res.description, //问题描述
            type: res.type, //创作领域：1：场景，2：人物，3：2D， 4：3D
            imageList: arr, //图片列表：最多5张
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.orderNumber, form]);



  const onConversationListUpdated = (event: any) => {
    console.log('===腾讯获取会话列表事件，将数据写到本地状态===');
    setConversationList([...event.data]);
    setLoading(false);
    // Conversation 数据结构详情请参考 https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Conversation.html
  };

  useEffect(() => {
    console.log(`===处理会话列表的激活状态逻辑===conversationID:${conversationID}`);
    let currentConversation = conversationList.find((item: any) => {
      return item.conversationID === conversationID
    })

    if (!currentConversation && conversationList.length > 0) {
      console.log('当前并无激活会话，默认激活第一个会话');

      if (props.toUserId) {
        const id = conversationList.find((item: any) => item.userProfile.userID === String(props.toUserId))?.conversationID
        console.log('默认激活会话', id, conversationList, props.toUserId)
        setConversationID(id)
        setToUserId(String(props.toUserId))
      } else {
        setConversationID(conversationList[0].conversationID)
        setToUserId(conversationList[0].userProfile.userID);
      }

    }
    //向头部更新未读事件
    dispatch(fireUnreadUpdateEvent(Date.now()))
  }, [conversationList, conversationID, dispatch])

  const onClickChangeConversation = (item: any) => {

    console.log('onClickChangeConversation', item.conversationID);
    if (item.conversationID === conversationID) {
      return;
    }
    setHasHistory(true);
    setMsgList([]);
    setHasMore(true);
    setNextReqMessageID("");
    setToUserId(item.userProfile.userID);
    setConversationID(item.conversationID);
  };

  const initMessageList = useCallback(() => {
    //初始化列表
    setMsgList([]);
    setHasMore(true);
    setNextReqMessageID("");
    //获取消息列表
    getMessageList();
  }, [conversationID])

  //conversationID 变化时，更新对应的消息列表
  useEffect(() => {
    console.log("conversationID变化后更新对应的消息列表", conversationID);
    if (conversationID && conversationID !== "") {
      chat.setMessageRead({ conversationID: conversationID });
      initMessageList()
    }
  }, [conversationID, initMessageList]);


  const handleEnterKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onClickSendMsg()
    }
  }
  const onClickSendMsg = () => {

    msgRef.current.focus();

    if (msg === "") {
      return;
    }

    //console.log(toUserId);
    let message = chat.createTextMessage({
      to: String(toUserId),
      conversationType: TencentCloudChat.TYPES.CONV_C2C,
      // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
      // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
      payload: {
        text: emojiParse(msg),
      },
      // 如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
      // needReadReceipt: true
      // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
      // cloudCustomData: 'your cloud custom data'
    });
    // 2. 发送消息
    let promise = chat.sendMessage(message);
    promise
      .then(function (imResponse) {
        // 发送成功
        setMsg("");
        msgRef.current.value = "";
        setMsgList((pre) => [imResponse.data.message, ...pre]);
        //更新会话列表（发送消息不会触发会话列表更新事件）
        //getConversationList();
        document.getElementById("chat-room-scrollable")?.scrollTo({ top: 0 });
      })
      .catch(function (imError) {
        // 发送失败
        console.warn("sendMessage error:", imError);
      });
  };

  const getMessageList = () => {
    if (!hasMore && hasHistory) {
      getHistoryMsgList();
      return;
    }
    if (!hasMore) {
      return;
    }

    chat
      .getMessageList({
        conversationID: conversationID,
        nextReqMessageID: nextReqMessageID,
      })
      .then((imResponse) => {

        const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
        setHasMore(!isCompleted);
        const messageList = imResponse.data.messageList.reverse(); // 消息列表。
        setMsgList((pre) => [...pre, ...messageList]);
        if (messageList.length > 0) {
          setMsgTime(messageList[messageList.length - 1].time * 1000);
        }

        //console.log(msgList.length);
        const nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        setNextReqMessageID(nextReqMessageID);

        // if(isCompleted) {
        //   getHistoryMsgList()
        // }

      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onMessageReceived = function (event: { data: Message[] }) {
    setIncomingMsgs(event.data)
    console.warn("====收到腾信的消息事件，把数据写入本地状态=======", event.data);
  };

  useEffect(() => {
    if (!incomingMsgs) {
      return
    }
    console.error("====收到腾信的消息事件，处理逻辑=======", incomingMsgs[0].conversationID, '---++++----', conversationID);

    //如果是当前对话，清除未读，如果不是当前对话，什么也不要做
    if (incomingMsgs[0].conversationID === conversationID) {
      chat.setMessageRead({ conversationID: conversationID });
      dispatch(fireUnreadUpdateEvent(Date.now()))
      const messageList = incomingMsgs;
      messageList.forEach((message: Message) => {
        setMsgList((pre) => [message, ...pre]);
      });
    }
    document.getElementById("chat-room-scrollable")?.scrollTo({ top: 0 });
  }, [incomingMsgs, conversationID, dispatch]);



  const appendEmoji = (item: { url: string; title: string }) => {
    let startCursor = msgRef.current.selectionStart; // 开始光标位置
    let endCursor = msgRef.current.selectionEnd; // 结束光标位置
    msgRef.current.value =
      msgRef.current.value.substring(0, startCursor) +
      item.title +
      msgRef.current.value.substring(endCursor);
    setMsg(msgRef.current.value);
  };

  const onClickCloseChatRoom = () => {
    if (!chat) {
      return
    }
    chat.off(TencentCloudChat.EVENT.SDK_READY, onSdkReady);
    chat.off(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);
    chat.off(
      TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED,
      onConversationListUpdated
    );
    chat.logout();
    chat.destroy().finally(() => {
      props.close();
    });
  };

  const onChangeMsg = (e: ChangeEvent<HTMLTextAreaElement>) => {
    console.log('onChangeMsg', e.target.value);
    setMsg(e.target.value);
  };

  const getHistoryMsgList = () => {
    let params = {
      msgTime: msgTime,
      needTotal: 1, //是否需要总数；默认需要，不需要总时，效率高
      pageNumber: pageNumber, //页码，默认1
      pageSize: 15, //页条数，最大100，默认10
      toAccount: toUserId, //接收方
    };
    apiGetChatHistory(params).then((res) => {
      console.log(res);
      let messageList = res.pageData.map((item) => {
        return {
          ID: item.eventTime,
          type: item.msgBody[0].MsgType,
          payload: { text: item.msgBody[0].MsgContent.Text },
          conversationID: conversationID,
          conversationType: TencentCloudChat.TYPES.CONV_C2C,
          to: item.toAccount,
          from: item.fromAccount,
          flow: "",
          time: item.msgTime,
          status: "",
          isRevoked: false,
          priority: TencentCloudChat.TYPES.ALLOW_TYPE_ALLOW_ANY,
          nick: "",
          avatar: item.fromUserInfo.avatarUrl,
        };
      });
      setMsgList((pre) => [...pre, ...messageList]);
      setTotalPage(res.totalPage);
      if (pageNumber < res.totalPage) {
        setPageNumber((pre) => pre + 1);
      } else {
        setHasHistory(false);
      }
    });
  };


  const onClickCloseQA = () => {
    setQAShow(false);
  };

  const onClickSubmitQA = () => {
    form.validateFields().then(async (values) => {
      let { description, imageList, type } = values;

      let descUrlsArr: Promise<{ url: string }>[] = [];
      imageList.forEach((item) => {
        if (item.originFileObj) {
          descUrlsArr.push(
            getFileUrlByCosUpload(
              item.originFileObj!,
              `ggac/shop/order/qaImageList/${getRandomKey(
                item.originFileObj!.name
              )}`
            )
          );
        }
      });

      let res = await Promise.all(descUrlsArr);
      let imgArr: string[] = [];
      imageList.forEach((item) => {
        if (!item.originFileObj) {
          if (item.url) {
            imgArr.push(item.url);
          }
        }
      });

      console.log(res);
      let params = {
        orderNumber: props.orderNumber!,
        imageList: [...imgArr, ...res.map((item) => item.url)],
        type: type,
        description: description,
      };
      // @ts-ignore
      apiCreateOrderQADetail(params).then((res) => {
        let message = chat.createCustomMessage({
          to: toUserId + "",
          conversationType: TencentCloudChat.TYPES.CONV_C2C,
          // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
          // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
          payload: {
            data: params.type + "",
            description: params.description,
            extension: JSON.stringify(params.imageList),
          },
          // 如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
          // needReadReceipt: true
          // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
          // cloudCustomData: 'your cloud custom data'
        });

        // 2. 发送消息
        let promise = chat.sendMessage(message);
        promise
          .then(function (imResponse) {
            console.log(imResponse);
            // 发送成功
            setMsgList((pre) => [imResponse.data.message, ...pre]);
            document
              .getElementById("chat-room-scrollable")
              ?.scrollTo({ top: 0 });
            setQAShow(false);
          })
          .catch(function (imError) {
            // 发送失败
            console.warn("sendMessage error:", imError);
          });
      });

      console.log(values);
    });
  };

  const onClickChooseFile = () => {
    let imageInput = document.createElement("input");
    imageInput.type = "file";
    imageInput.accept = "";
    imageInput.click();
    imageInput.addEventListener("change", (e: any) => {
      // 通过控制台打印
      console.log(e.target.files[0])
      msgRef.current.focus();
      let message
      if (e.target.files[0].type.includes('video')) {
        message = chat.createVideoMessage({
          to: toUserId,
          conversationType: TencentCloudChat.TYPES.CONV_C2C,
          payload: {
            file: imageInput,
          },
        });
      } else if (e.target.files[0].type.includes("image")) {
        message = chat.createImageMessage({
          to: toUserId,
          conversationType: TencentCloudChat.TYPES.CONV_C2C,
          payload: {
            file: imageInput,
          },
        });
      } else if (e.target.files[0].type.includes("audio")) {
        message = chat.createAudioMessage({
          to: toUserId,
          conversationType: TencentCloudChat.TYPES.CONV_C2C,
          payload: {
            file: imageInput,
          },
        });
      } else {
        message = chat.createFileMessage({
          to: toUserId,
          conversationType: TencentCloudChat.TYPES.CONV_C2C,
          payload: {
            file: imageInput,
          },
        });
      }
      // if (msg == "") {
      //   return;
      // }

      // 2. 发送消息
      let promise = chat.sendMessage(message);
      promise
        .then(function (imResponse) {
          console.log(imResponse);
          // 发送成功
          setMsgList((pre) => [imResponse.data.message, ...pre]);
          document.getElementById("chat-room-scrollable")?.scrollTo({ top: 0 });
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    });
  }

  const onClickChooseImage = () => {
    let imageInput = document.createElement("input");
    imageInput.type = "file";
    imageInput.accept = "image/*";
    imageInput.click();
    imageInput.addEventListener("change", (e: any) => {
      // 通过控制台打印
      console.log(e.target.files);

      msgRef.current.focus();

      // if (msg == "") {
      //   return;
      // }

      let message = chat.createImageMessage({
        to: toUserId,
        conversationType: TencentCloudChat.TYPES.CONV_C2C,
        // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
        // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          file: imageInput,
        },
        // 如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
        // needReadReceipt: true
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
        // cloudCustomData: 'your cloud custom data'
      });
      // 2. 发送消息
      let promise = chat.sendMessage(message);
      promise
        .then(function (imResponse) {
          console.log(imResponse);
          // 发送成功
          setMsgList((pre) => [imResponse.data.message, ...pre]);
          document.getElementById("chat-room-scrollable")?.scrollTo({ top: 0 });
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    });
  };

  return (
    <ChatRoomStyle>
      <Modal
        title="发起提问"
        width={800}
        open={qaShow}
        onCancel={onClickCloseQA}
        footer={
          <div>
            <Button type="primary" onClick={onClickSubmitQA}>
              提交
            </Button>
          </div>
        }
      >
        <div className="qa-container">
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            // requiredMark={true}
            // size="large"
            className="form-area"
            form={form}
          >
            <div className="form-section">
              <Form.Item
                label="请选择你的创作领域"
                name="type"
                rules={[{ required: true, message: "请选择创作领域" }]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={1}>场景</Radio.Button>
                  <Radio.Button value={2}>人物</Radio.Button>
                  <Radio.Button value={3}>2D</Radio.Button>
                  <Radio.Button value={4}>3D</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="问题描述"
                name="description"
                rules={[{ required: true, message: "请输入问题描述" }]}
              >
                <AInput.TextArea placeholder="请输入问题描述" />
              </Form.Item>

              <Form.Item label="添加图片" name="imageList">
                <PicturesWall maxLength={5} listType="picture-card" />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
      <div className="chat-room-container">
        <Modal
          className="chat-room-modal"
          getContainer={false}
          width={1000}
          title={"聊天"}
          open={props.show}
          onCancel={onClickCloseChatRoom}
          footer={null}
        >
          <Spin spinning={loading}>
            {conversationList.length > 0 && (
              <div className="chat-container">
                <div className="left-wrapper">
                  <div className="user-list-wrapper">
                    {conversationList.map((item: any) => {
                      return (
                        <div
                          key={item.conversationID}
                          onClick={() => onClickChangeConversation(item)}
                          className={
                            item.conversationID === conversationID
                              ? "user-list-item user-list-item-active"
                              : "user-list-item"
                          }
                        >
                          <div className="smallsmallsmall">
                            <Badge size="small" count={item.unreadCount}>
                              <Avatar
                                size={36}
                                shape="square"
                                src={item.userProfile.avatar}
                              ></Avatar>
                            </Badge>
                          </div>
                          <div className="con-right">
                            <div className="con-top">
                              {item.userProfile.nick}
                            </div>
                            {/* <div className="bottom"></div> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="right-wrapper">
                  <div className="message-wrapper" id="chat-room-scrollable">
                    <div style={{ flex: 1 }}></div>
                    <InfiniteScroll
                      dataLength={msgList.length}
                      next={getMessageList}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                      }} //To put endMessage and loader to the top.
                      inverse={true}
                      hasMore={hasHistory}
                      loader={null}
                      scrollableTarget="chat-room-scrollable"
                    >
                      {msgList.map((item, index) => {
                        return (
                          <MessageItem key={index} msg={item}></MessageItem>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                  <div className="input-wrapper">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        justifyContent: "end",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "10px"
                        }}
                      >
                        <EmojiPicker appendEmoji={appendEmoji} />

                        <RiImage2Line
                          onClick={onClickChooseImage}
                          style={{ marginBottom: "4px" }}
                          size={20}
                          color="rgb(255, 199, 18)"
                        ></RiImage2Line>

                        <RiFolder3Line
                          onClick={onClickChooseFile}
                          style={{ marginBottom: "4px" }}
                          size={20}
                          color="rgb(255, 199, 18)"
                        ></RiFolder3Line>
                      </div>

                    </div>
                    <Input
                      inputStyle={{ height: "100px", padding: "2px" }}
                      referance={msgRef}
                      autofocus={true}
                      maxHeight={100}
                      minHeight={100}
                      onChange={(e: any) => onChangeMsg(e)}
                      onKeyDown={handleEnterKeyDown}
                      multiline={true}
                      autoHeight={false}
                      rightButtons={
                        <div
                          onClick={onClickSendMsg}
                          style={{
                            padding: "4px 18px",
                            backgroundColor: "rgb(255, 199, 18)",
                            borderRadius: "4px",
                            cursor: "pointer",
                            marginTop: "50px"
                          }}
                        >
                          {" "}
                          发送
                        </div>
                      }
                    />
                    {/* <TextArea rows={4} ref={msgRef} value={msg} onChange={(e) =>onChangeMsg(e)}  /> 
                                    <div className="send-msg-btn">
                                        <Button type="primary" onClick={onClickSendMsg}>发送</Button>
                                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </Spin>
          {conversationList.length === 0 && !loading &&
            <div className="empty">
              <Empty description="暂无聊天"></Empty></div>}
        </Modal>
      </div>
    </ChatRoomStyle>
  );
};
