import { Button, Input, Select, Modal, message, Pagination } from 'antd'

import { useState, useEffect, useCallback, useMemo } from 'react'
import { OrderStyle } from './style'
import { OrderSearchType, orderSearchArr } from 'types/enums/order-types'
import { useNavigate } from 'react-router-dom'
import CommonTabs from 'ui-lib/tab/common-tab'
import { apiGetOrderList, postConfirmOrder, postResetOrder } from 'api/shop'
import {
  SellerOrderListRequest,
  OrderListResponse,
  OrderListRequest,
} from 'types/product'
import { queryUrl } from 'utils/functions/common'
import { NoData } from 'components/no-data'
import { ChatRoom } from 'components/chat'
import { RiChat1Line } from 'react-icons/ri'
import RedundApplyModal from './redundApplyModal'
import { translateRefundStatus } from './fn'
import MediationApplyModal from './mediationApplyModal'
import ModifyAddressModal from './modifyAddress'
import dayjs from 'dayjs'
import { initChatService, logoutChat, orderReceiptOfGoodsSendMessage } from "utils/functions/chat";
const { Option } = Select

const MyOrderList = () => {
  const [list, updateList] = useState<OrderListResponse[]>([])
  const [total, setTotal] = useState(0)
  const { Search } = Input
  const navigate = useNavigate()
  let pageNumber =
    new URLSearchParams(window.location.search).get('pageNumber') || 1
  let name = new URLSearchParams(window.location.search).get('name')
  let state = new URLSearchParams(window.location.search).get('state') || -1
  let productName =
    new URLSearchParams(window.location.search).get('productName') || ''
  let sellerUsername =
    new URLSearchParams(window.location.search).get('sellerUsername') || ''
  let orderNumber =
    new URLSearchParams(window.location.search).get('orderNumber') || ''
  let reviewState = new URLSearchParams(window.location.search).get(
    'reviewState'
  )
  const [loading, setLoading] = useState(false)
  const [chatModalOpen, setChatModalOpen] = useState(false)
  const [refundModalOpen, setRefundModalOpen] = useState(false)
  const [mediationModalOpen, setMediationModalOpen] = useState(false)
  const [modifyAddressModalOpen, setModifyAddressModalOpen] = useState(false)
  const [toUserId, setToUserId] = useState<number>()
  const [key, setKey] = useState(0)
  const [currentOrder, setCurrentOrder] = useState<OrderListResponse>()
  const [searchName, setSearchName] = useState('')

  const pageSize = 5

  const submitParams: SellerOrderListRequest = useMemo(() => {
    return {
      pageNumber: Number(pageNumber),
      pageSize,
      state: Number(state),
      name,
    }
  }, [pageNumber, state, name])

  const getList = useCallback(
    async (params?: OrderListRequest) => {
      const { pageNumber, pageSize, state } = submitParams
      const paramsReq: any = Object.assign(
        {
          pageNumber,
          pageSize,
          name,
          state,
          orderNumber,
          productName,
          sellerUsername,
        },
        params
      )
      if (reviewState !== null && reviewState !== undefined) {
        paramsReq.reviewState = reviewState
      }
      if (state === undefined || state === -1 || state === -2) {
        delete paramsReq.state
      }
      if (state === -2) {
        paramsReq.isRefund = 1
      }
      const result = await apiGetOrderList(paramsReq)
      updateList(result.pageData)
      setTotal(result.totalSize)
      if (productName) {
        setSelectOrderType(OrderSearchType.ORDER_SEARCH_COMMODITY_NAME)
        setSearchName(productName)
      } else if (sellerUsername) {
        setSelectOrderType(OrderSearchType.ORDER_SEARCH_USERNAME)
        setSearchName(sellerUsername)
      } else if (orderNumber) {
        setSelectOrderType(OrderSearchType.ORDER_SEARCH_PRODUCT_NAME)
        setSearchName(orderNumber)
      }
    },
    [submitParams, orderNumber, productName, sellerUsername, reviewState]
  )

  useEffect(() => {
    getList()
  }, [getList])

  // 分页
  const changePage = (detail: any) => {
    const query = queryUrl({ pageNumber: detail })
    navigate(`?${query}`)
  }

  const [selectOrderType, setSelectOrderType] = useState(1)
  const selectSearchOrderTyle = (e: number) => {
    setSelectOrderType(e)
  }

  useEffect(() => {
    orderSearchArr[1].name = '卖家名称'
  }, [])

  const selectBefore = (
    <Select value={selectOrderType} onSelect={(e) => selectSearchOrderTyle(e)}>
      {orderSearchArr.map((item) => {
        return (
          <Option key={item.value} value={item.value}>
            {item.name}
          </Option>
        )
      })}
    </Select>
  )

  const toDetail = (detail: OrderListResponse) => {
    if (detail.orderType !== 100) {
      return
    }
    navigate(`/product/detail/${detail.productId}`)
  }

  const toOrderDetail = (detail: OrderListResponse) => {
    console.log(submitParams.state)
    navigate(
      `/user-center/shop/order/detail/${detail.orderNumber}?from=${submitParams.state === -1 || submitParams.state === undefined
        ? 'all'
        : ''
      }`
    )
  }

  const toPay = (detail: OrderListResponse, type: number) => {
    if (detail.isPrivateInquiry && detail.amount === 0) {
      message.warning("请联系商家修改价格")
      return
    }
    navigate(
      `/user-center/shop/order/detail/${detail.orderNumber}?state=${type}`
    )
  }

  const onSearch = (value: string) => {
    if (selectOrderType === OrderSearchType.ORDER_SEARCH_COMMODITY_NAME) {
      const query = queryUrl({
        productName: value,
        pageNumber: 1,
        sellerUsername: '',
        orderNumber: '',
      })
      navigate(`?${query}`)
    } else if (selectOrderType === OrderSearchType.ORDER_SEARCH_USERNAME) {
      const query = queryUrl({
        sellerUsername: value,
        pageNumber: 1,
        productName: '',
        orderNumber: '',
      })
      navigate(`?${query}`)
    } else if (selectOrderType === OrderSearchType.ORDER_SEARCH_PRODUCT_NAME) {
      const query = queryUrl({
        orderNumber: value,
        pageNumber: 1,
        productName: '',
        sellerUsername: '',
      })
      navigate(`?${query}`)
    }
  }

  const submitReceipt = (detail: OrderListResponse) => {

    Modal.confirm({
      title: '确认收货',
      content: '确认收货后钱会打给商家，请谨慎操作！',
      okText: '确认',
      cancelText: '取消',
      onCancel() {
        if (detail.isManuscript) {
          logoutChat()
        }
      },
      centered: true,
      onOk() {
        setLoading(true)
        postConfirmOrder(detail.orderNumber)
          .then(() => {
            message.success('确认收货成功')
            getList()
            if (detail.isManuscript) {
              orderReceiptOfGoodsSendMessage(detail, () => logoutChat)
            }
          })
          .finally(() => {
            setLoading(false)
          })
      },
    })
    if (!!detail.isManuscript) {
      initChatService(detail.sellerUserId)
    }
  }

  const reset = (detail: OrderListResponse) => {
    Modal.confirm({
      title: '确定取消？',
      content: '取消订单后 订单失效！',
      okText: '确定',
      cancelText: '取消',
      onCancel() { },
      centered: true,
      onOk() {
        setLoading(true)
        postResetOrder(detail.orderNumber)
          .then(() => {
            message.success('取消成功')
            getList()
          })
          .finally(() => {
            setLoading(false)
          })
      },
    })
  }

  const onClickOpenChat = (detail: OrderListResponse) => {
    setCurrentOrder(detail)
    setChatModalOpen(true)
  }

  const checkTab = (detail: { key: number | string; title: string }) => {
    if (detail.key === 3) {
      navigate(`?state=${detail.key}&&reviewState=0`)
      return
    }
    navigate(`?state=${detail.key}`)
  }

  useEffect(() => {
    if (state === '3' && reviewState === '0') {
      setKey(3)
      return
    } else if (state === '3' && reviewState === '1') {
      setKey(4)
      return
    }
    setKey(Number(state))
  }, [state, reviewState])

  const statusTabs = [
    {
      key: -1,
      title: '全部',
    },
    {
      key: 0,
      title: '待支付',
    },
    {
      key: 1,
      title: '待发货',
    },
    {
      key: 2,
      title: '待收货',
    },
    {
      key: 3,
      title: '待评价',
    },
    {
      key: -2,
      title: '售后中',
    },
  ]

  const applyRefund = (detail: OrderListResponse) => {
    setCurrentOrder(detail)
    setRefundModalOpen(true)
  }

  const applyMediation = (detail: OrderListResponse) => {
    setCurrentOrder(detail)
    setMediationModalOpen(true)
  }

  const modifyAddress = (detail: OrderListResponse) => {
    setCurrentOrder(detail)
    setModifyAddressModalOpen(true)
  }

  return (
    <OrderStyle>
      <div className="table-wrap">
        {chatModalOpen && (
          <ChatRoom
            show={chatModalOpen}
            close={() => setChatModalOpen(false)}
            toUserId={toUserId}
          ></ChatRoom>
        )}
        <div className="main">
          <div className="main-top">
            <CommonTabs
              tabs={statusTabs}
              defaultKey={key}
              onClick={(item) => checkTab(item)}
            ></CommonTabs>
            <div className="actions">
              <Search
                addonBefore={selectBefore}
                placeholder="请输入关键字"
                allowClear
                onChange={(e) => setSearchName(e.target.value)}
                value={searchName}
                onSearch={(e) => onSearch(e)}
              />
            </div>
          </div>
          <div className="main-list">
            <div className="heading">
              <span>商品信息</span>
              <span>单价</span>
              <span>数量</span>
              <span>商家</span>
              <span>订单状态</span>
              <span>售后状态</span>
              <span>付款金额</span>
            </div>
            <div className="list">
              {list.length === 0 && <NoData />}
              {list.map((item) => {
                return (
                  <div key={item.orderNumber}>
                    <div className="list-item">
                      <div className="top">
                        <span>订单编号：{item.orderNumber}</span>
                        <span>下单时间：{item.createTime}</span>
                      </div>
                      <div className="row">
                        <div className="row-cell">
                          <div className="cell-card">
                            <div className="thumbnail-wrap">
                              {(item.orderType === 1 ||
                                item.orderType === 100) && (
                                  <img
                                    className="thumbnail"
                                    onClick={() => toDetail(item)}
                                    src={item.productImage}
                                    alt=""
                                  />
                                )}
                              {item.orderType === 5 && (
                                <img
                                  src="https://cdn-prd.ggac.com/ggac/assets/image/icon/banquan-DCI.svg"
                                  className="icon"
                                  alt=""
                                />
                              )}
                              {(item.orderType === 2 ||
                                item.orderType === 4) && (
                                  <img
                                    src="https://cdn-prd.ggac.com/ggac/assets/image/icon/banquan-copyright.svg"
                                    className="icon"
                                    alt=""
                                  />
                                )}
                              {item.orderType === 3 && (
                                <img
                                  src="https://cdn-prd.ggac.com/ggac/assets/image/icon/banquan-lian.svg"
                                  className="icon"
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="title_">
                              {item.orderType === 100 && (
                                <a href={`/product/detail/${item.productId}`}>
                                  <span style={{ color: '#000' }}>
                                    {item.productName}
                                  </span>{' '}
                                  <br />
                                  {item.skuName}
                                </a>
                              )}
                              {item.orderType !== 100 && (
                                <>{item.productName}</>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row-cell">
                          ￥{(item.rmbPrice / 100).toFixed(2)}
                        </div>
                        <div className="row-cell">{item.num}</div>
                        <div
                          className="row-cell user-chat-label"
                          onClick={() => {
                            setToUserId(item.sellerUserId)
                            setChatModalOpen(true)
                          }}
                        >
                          <RiChat1Line className="icon"></RiChat1Line>
                          <div>{item.sellerUsername}</div>
                        </div>
                        {item.state === 0 && (
                          <div className="row-cell">待付款</div>
                        )}
                        {item.state === 1 && item.orderType === 100 && (
                          <div className="row-cell status-cell">
                            待发货
                            {
                              !!item.lastSendTime && <div className='lastSendTime'>
                                {dayjs(item.lastSendTime).format("YYYY年MM月DD日 HH时")}前发货
                              </div>
                            }
                          </div>
                        )}
                        {item.state === 1 && item.orderType === 1 && (
                          <div className="row-cell">生成中</div>
                        )}

                        {item.state === 2 && (
                          <div className="row-cell">{(item.isAuthorization === 1 || item.isManuscript === 1) ? '已支付' : "待收货"}</div>
                        )}
                        {item.state === 3 &&
                          (item.reviewState === 0 || !item.reviewState) &&
                          item.orderType === 100 && (
                            <div className="row-cell">待评价</div>
                          )}
                        {item.state === 3 &&
                          (item.reviewState === 0 || !item.reviewState) &&
                          item.orderType !== 100 && (
                            <div className="row-cell">已完成</div>
                          )}

                        {item.state === 3 && item.reviewState === 1 && (
                          <div className="row-cell">已完成</div>
                        )}

                        {item.state === 4 && (
                          <div className="row-cell">
                            {item.closeType === 1 ? '逾期未支付' : ''}
                            {item.closeType === 2 ? '买家取消' : ''}
                            {item.closeType === 3 ? '卖家取消' : ''}
                            {item.closeType === 4 ? '平台取消' : ''}
                          </div>
                        )}

                        {item.refundFlag > 0 && (
                          <div className="row-cell" style={{ color: 'red' }}>
                            {translateRefundStatus(item.refundStatus)}
                          </div>
                        )}
                        {item.refundFlag === 0 && (
                          <div className="row-cell">无</div>
                        )}
                        <div className="row-cell">
                          ￥{(item.amount / 100).toFixed(2)}
                        </div>
                      </div>
                      {item.orderType === 100 && (
                        <div className="actions btns">
                          {item.state === 1 && (
                            <Button
                              onClick={() => modifyAddress(item)}
                              type="primary"
                            >
                              修改地址
                            </Button>
                          )}
                          <Button
                            type="primary"
                            onClick={() => toOrderDetail(item)}
                          >
                            查看详情
                          </Button>
                          {item.state === 0 && (
                            <Button onClick={() => reset(item)} type="primary">
                              取消订单
                            </Button>
                          )}
                          {item.state === 0 && (
                            <Button
                              type="primary"
                              onClick={() => toPay(item, 0)}
                            >
                              立即支付
                            </Button>
                          )}
                          {item.state === 3 && item.reviewState === 0 && (
                            <Button
                              type="primary"
                              onClick={() => toPay(item, 3)}
                            >
                              去评价
                            </Button>
                          )}
                          {item.state === 2 && (
                            <Button
                              loading={loading}
                              onClick={() => submitReceipt(item)}
                              type="primary"
                            >
                              确定收货
                            </Button>
                          )}
                          {(item.state === 1 || item.state === 2) &&
                            item.productType === 3 && (
                              <Button
                                onClick={() => onClickOpenChat(item)}
                                type="primary"
                              >
                                立即咨询
                              </Button>
                            )}

                          {item.canApplyRefundType === 1 && (
                            <Button
                              onClick={() => applyRefund(item)}
                              type="primary"
                            >
                              申请退款
                            </Button>
                          )}
                          {item.canApplyRefundType === 2 && (
                            <Button
                              onClick={() => applyMediation(item)}
                              type="primary"
                            >
                              平台介入
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            <Pagination
              style={{ textAlign: 'center', margin: '24px 0 24px 0' }}
              hideOnSinglePage={true}
              current={Number(pageNumber)}
              pageSize={pageSize}
              onChange={changePage}
              total={total}
              showSizeChanger={false}
            />
            {currentOrder && (
              <RedundApplyModal
                detail={currentOrder}
                visible={refundModalOpen}
                onSubmit={() => {
                  getList()
                  setRefundModalOpen(false)
                }}
                onClose={() => setRefundModalOpen(false)}
              ></RedundApplyModal>
            )}

            {currentOrder && (
              <MediationApplyModal
                detail={currentOrder}
                visible={mediationModalOpen}
                onSubmit={() => {
                  getList()
                  setMediationModalOpen(false)
                }}
                onClose={() => setMediationModalOpen(false)}
              ></MediationApplyModal>
            )}
            {currentOrder && (
              <ModifyAddressModal
                orderItem={currentOrder}
                isShow={modifyAddressModalOpen}
                onCancal={() => setModifyAddressModalOpen(false)}
                onFinish={() => {
                  getList()
                  setModifyAddressModalOpen(false)
                }}
              ></ModifyAddressModal>
            )}
          </div>
        </div>
      </div>
    </OrderStyle>
  )
}

export default MyOrderList
