import { PublicProductListItem } from "types/product";
import ProductCardLayout from "../layout";
import { Button } from "antd";

function PublicProductCard({ item }: { item: PublicProductListItem }) {
  const toDetail = () => {
    window.open(`/product/detail/${item.id}`)
  }

  const UiObject = {
    coverUrl: item.coverUrl,
    title: item.name,
    subtitle: item.categoryName,
    productLink: "",
    realPrice: item.rmbPrice,
    id: item.id,
    isSoldOut: item.isSoldOut,
    bottomLeftNode:  <div className="real">
    <span className="sign">￥</span>
    <span>{item.rmbPrice && (item.rmbPrice / 100).toFixed(2)}</span>
  </div>,
    bottomRightNode:  <div className="action">
    {
      item.isSoldOut === 1 && <Button type="primary" onClick={toDetail} size="middle" disabled={true} style={{ height: 30, padding: '0 14px' }}>已售罄</Button>
    }
    {
      item.isSoldOut === 0 && <Button type="primary" size="middle" onClick={toDetail} style={{ height: 30, padding: '0 14px' }}>立即购买</Button>
    }
  </div>
  };

  return ProductCardLayout({ item: UiObject });
}

export default PublicProductCard;
