import { WorkContentStyle } from "./style";
import "@egjs/react-view3d/css/view3d-bundle.min.css";
import { WorkDetailMedieListItem, WorkDetailType } from "types/work";
import "react-photo-view/dist/react-photo-view.css";
import {
  isImg,
  isImgEfx,
  isThreedGlb,
  isThreedMview,
  isVideo,
  isWorkAudio,
  reorderMediaList,
  translateContestMediaMark,
} from "./fn";
import VideoContentDisplay from "./video";
import { Suspense, useEffect, useState } from "react";
import React from "react";
import { Divider, Space, Spin, Tooltip } from "antd";

import Image from '../../../../lib/ant-image/index'

import DetailHeader from "../header";
import { truncateText } from "utils/functions/common";
import PhotoDisplayAnt from "./photo-ant";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import MarmosetDisplay from "./3d/marmoset";
const ThreeDContentDisplayComponent = React.lazy(() => import("./3d"));

function WorkContent(props: { detail: WorkDetailType }) {
  const [dataList, setDatalist] = useState<WorkDetailMedieListItem[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const mediaMarks: string[] = [];

  const [imageList, setImageList] = useState<WorkDetailMedieListItem[]>([])

  useEffect(() => {
    //只有新数据的比赛 才需要做reorder处理，老数据及练习作品都直接返回排序
    if (
      props.detail.isContest &&
      props.detail?.mediaList &&
      props.detail.orderType === 0
    ) {
      setDatalist(reorderMediaList(props?.detail?.mediaList));
      setImageList(reorderMediaList(props?.detail?.mediaList).filter((item_) => isImg(item_)))
    } else {
      setDatalist(props?.detail?.mediaList);
      setImageList(props?.detail?.mediaList.filter((item_) => isImg(item_)))
    }
  }, [props.detail]);

  const getCategory = () => {
    return (
      <>
        {props.detail.dictMap?.mediaCategory}
        <span> | </span>
        {props.detail.categoryList?.[0]?.name}{" "}
        {props.detail.categoryList?.length > 1 && <span> | </span>}
        {props.detail.categoryList?.[1]?.name}
      </>
    );
  };

  const handleOnClickPreviewIcon = (item: WorkDetailMedieListItem) => {
    const imgIndex = dataList?.filter((item_) => isImg(item_)).findIndex((item_) => {
      return item_.id === item.id;
    });
    setCurrentIndex(imgIndex ?? 0);
    setPreviewVisible(true);
  };


  const loadingImage = (item: WorkDetailMedieListItem) => {
    if (item.isLoadingOrigin) {
      return
    }
    const index = dataList.findIndex(sub => sub.id === item.id)
    dataList[index].isLoadingOrigin = true
    setDatalist([...dataList])
  }

  return (
    <WorkContentStyle>
      <div className="header">
        <div className="header-left">
          <div className="header-top">
            <div className="title" title={props.detail.title}>
              {truncateText(props.detail.title, 22)}
            </div>
            <div className="meta">
              <Space>
                {props.detail.actionCount?.hotLevel === 1 && (
                  <div className="roundIcon red-light">
                    <Tooltip title="热门作品">
                      <img
                        style={{ cursor: "pointer" }}
                        src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-hot-icon.png"
                        alt=""
                      />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.hotLevel === 2 && (
                  <div className="roundIcon red">
                    <Tooltip title="超火作品">
                      <img
                        style={{ cursor: "pointer" }}
                        src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-hot-icon2.png"
                        alt=""
                      />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.hotLevel === 3 && (
                  <div className="roundIcon rank-yellow">
                    <Tooltip title="榜单作品">
                      <img
                        style={{ cursor: "pointer" }}
                        src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-rank-icon.png"
                        alt=""
                      />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.recommendLevel === 1 && (
                  <div className="roundIcon yellow selected">
                    <Tooltip title="精选作品">
                      <img
                        style={{ cursor: "pointer" }}
                        src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-photo-wall-icon.png"
                        alt=""
                      />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.recommendLevel === 2 && (
                  <div className="roundIcon blue">
                    <Tooltip title="图墙推荐">
                      <img
                        style={{ cursor: "pointer" }}
                        src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-recommend-icon.png"
                        alt=""
                      />
                    </Tooltip>
                  </div>
                )}
                {props.detail.actionCount?.recommendLevel === 3 && (
                  <div className="roundIcon yellow">
                    <Tooltip title="首页推荐">
                      <img
                        style={{ cursor: "pointer" }}
                        src="https://cdn-prd.ggac.com/ggac/assets/image/icon/work-photo-wall-icon2.png"
                        alt=""
                      />
                    </Tooltip>
                  </div>
                )}
              </Space>
            </div>
          </div>
          <div className="category">{getCategory()}</div>
        </div>
        {!!props.detail.isContest && (
          <div className="header-right">
            <DetailHeader detail={props.detail}></DetailHeader>
          </div>
        )}
      </div>
      <Divider></Divider>
      <div
        className="desc"
        style={{ textAlign: "left" }}
        dangerouslySetInnerHTML={{
          __html:
            props.detail.description &&
            props.detail.description.replace(/\n/g, "<br/>"),
        }}
      >

      </div>
      {props.detail?.contestWork && props.detail?.contestWork!.content && (
        <div
          className="html-content"
          dangerouslySetInnerHTML={{
            __html: props.detail?.contestWork!.content,
          }}
        ></div>
      )}

      <Image.PreviewGroup
        preview={{
          visible: previewVisible,
          current: currentIndex,
          onChange: (current: number, prevCurrent: number) => {
            setCurrentIndex(current);
          },
          onVisibleChange: (visible: boolean, prevValue: boolean, current: number) => {
            setPreviewVisible(visible);
            setCurrentIndex(current);
          },
          countRender: (current: number, total: number) => <>{current}/{total}</>,
          scaleStep: 1,
          rootClassName: "work-image-preview",
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: {
                onFlipY,
                onFlipX,
                onRotateLeft,
                onRotateRight,
                onZoomOut,
                onZoomIn,
              },
            }
          ) => (
            <Space size={12} className="toolbar-wrapper" style={{ backgroundColor: "rgba(0,0,0,.5)", padding: "5px 10px", color: "#fff", borderRadius: "10px" }}>
              {
                <div style={{ fontSize: "16px", cursor: "pointer" }} onClick={() => { loadingImage(imageList[currentIndex]) }}>
                  {imageList[currentIndex].isLoadingOrigin ? '原图已加载' : '加载原图'}
                </div>
              }
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            </Space>
          ),
        }}
      >
        {props.detail.orderType === 0 &&
          dataList &&
          dataList.map((item, index) => {
            //这个处理是让比赛图片标签只显示一次
            let node = (
              <div className="contentObject" key={index}>
                {/* {translateContestMediaMark(item) &&
                  !mediaMarks.includes(item.mark) && (
                    <div className="caption-0">
                      {translateContestMediaMark(item)}
                    </div>
                  )} */}
                <div className="media">
                  {isImg(item) && (
                    <div className="fit">
                      <PhotoDisplayAnt
                        mediaItem={item}
                        onClickPreviewIcon={handleOnClickPreviewIcon}
                      ></PhotoDisplayAnt>
                    </div>
                  )}
                  {isImgEfx(item) && (
                    <VideoContentDisplay
                      url={item.params?.specialEffectVideo}
                    ></VideoContentDisplay>
                  )}
                  {isVideo(item) && (
                    <div className="fit">
                      <VideoContentDisplay
                        url={item.resizedUrl ?? item.url}
                        cover={item?.coverUrl}
                      ></VideoContentDisplay>
                    </div>
                  )}
                  {isThreedGlb(item) && (
                    <Suspense fallback={<Spin />}>
                      <ThreeDContentDisplayComponent
                        mediaItem={item}
                      ></ThreeDContentDisplayComponent>
                    </Suspense>
                  )}

                  {isThreedMview(item) && (
                    <MarmosetDisplay url={item.url} width={640} height={480}></MarmosetDisplay>
                  )}
                  {
                    isWorkAudio(item) && <>
                      <div style={{ textAlign: "center" }}>
                        <audio
                          src={item.url}
                          controls
                          id={`audio-${index + 1}`}
                        />
                      </div>
                    </>
                  }

                </div>
                <div className="caption">{item.caption}</div>
              </div>
            );
            mediaMarks.push(item.mark); //这个处理是让比赛图片标签只显示一次
            return node;
          })}

        {/* //老比赛数据排序 */}
        {props.detail.orderType === 1 &&
          dataList &&
          dataList.map((item, index) => {
            let node = (
              <div className="contentObject" key={index}>
                <div className="media">
                  {isImg(item) && (
                    <div className="fit">
                      <PhotoDisplayAnt
                        mediaItem={item}
                        onClickPreviewIcon={handleOnClickPreviewIcon}
                      ></PhotoDisplayAnt>
                    </div>
                  )}
                  {isVideo(item) && (
                    <div className="fit">
                      <VideoContentDisplay
                        url={item.resizedUrl ?? item.url}
                        cover={item?.coverUrl}
                      ></VideoContentDisplay>
                    </div>
                  )}
                  {isThreedGlb(item) && (
                    <Suspense fallback={<Spin />}>
                      <ThreeDContentDisplayComponent
                        mediaItem={item}
                      ></ThreeDContentDisplayComponent>
                    </Suspense>
                  )}
                  {isThreedMview(item) && (

                    <MarmosetDisplay url={item.url} width={640} height={480}></MarmosetDisplay>

                  )}

                  {
                    isWorkAudio(item) && <>
                      <div style={{ textAlign: "center" }}>
                        <audio
                          style={{ width: "500px" }}
                          src={item.url}
                          controls
                          id={`audio-${index + 1}`}
                        />
                      </div>
                    </>
                  }

                </div>
                <div className="caption">{item.caption}</div>
              </div>
            );
            return node;
          })}
      </Image.PreviewGroup>
    </WorkContentStyle>
  );
}

export default WorkContent;
