import { Affix, Button, Form, Input,  Space, Spin, message } from "antd";

import { useEffect, useState } from "react";
import { TagSelect } from "components/tagSelect";
import { ImageCrop } from "components/crop-img";
import { getFileUrlByCosUpload } from "config/cos";
import { getFileName } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import { apiDraftWorkDetail } from "api/work";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SubUpdateStyle } from "./style";
import { SubPicturesWall } from "views/contest-form/components/uploadImageList"
import { SubVideoWall } from "views/contest-form/components/uploadVideoList";
import { apiCreateSubWork, apiGetMediaField, apiGetWorkField, apiUpdateSubWork } from "api/sub";
import { SignupField, UploadMediaField } from "types/sub/application";
import { SingleSelect } from "views/contest-form/components/single-select";
import { CategorySecondSelect } from "views/contest-form/components/category-second-select";
import { ListItem } from "views/work/components/comboUpload/type";
import { MediaListItem } from "types/media";
import { SubChildrenSelect } from "views/contest-form/components/children-select";
import { CreationWare } from "views/work/update/creationWare";

type WorkUpdateForm = {
  detailSupplementImage?: ListItem[]
  draftImage?: ListItem[]
  effectImage?: ListItem[]
  effectVideo?: ListItem[]
  processImage?: ListItem[]
  textureImage?: ListItem[]
  threeDImage?: ListItem[]

  theme: number[]
  team: number[]
  mediaCategory: number[]

  title: string
  tagList: string[]
  categoryId: number
  coverUrl: {
    file?: File,
    imgUrl?: string,
  }
  description: string
  hardwareList?: string[]
  softwareList?: string[]
}
export const YcwlontestUpload = () => {
  const navigate = useNavigate()
  const [top] = useState(64);

  const [form] = Form.useForm<WorkUpdateForm>();

  // const theme = Form.useWatch('theme', { form, preserve: true });
  // const team = Form.useWatch('team', { form, preserve: true });
  const mediaCategory = Form.useWatch('mediaCategory', { form, preserve: true });

  const [coverUrlFile, setCoverUrlFile] = useState<{ file?: File, imgUrl?: string }>()

  const [loading, setLoading] = useState(false)

  const [fieldList, setFieldList] = useState<SignupField[]>()

  const [mediaFieldList, setMediaFieldList] = useState<UploadMediaField[]>([])

  const [cate, setCate] = useState<string>('')


  let { id } = useParams()

  let [searchParams, setSearchParams] = useSearchParams();
  const workId = searchParams.get("workId")

  const mediaNameList = [
    'effectVideo',
    'effectImage',
    'draftImage',
    'processImage',
    'textureImage',
    'threeDImage',
    'detailSupplementImage',
  ]

  useEffect(() => {
    let mediaCategory = form.getFieldValue('mediaCategory')
    if (form.getFieldValue('mediaCategory')) {
      apiGetMediaField({ mediaCategory: mediaCategory[0] }).then(res => {
        setMediaFieldList(res)
      })
    } else {
      apiGetMediaField().then(res => {
        setMediaFieldList(res)
      })
    }


  }, [mediaCategory])

  const getItem = (list: MediaListItem[]) => {
    return list?.map(item => {
      return {
        id: item.id,
        url: item.url,
        key: item.uuid,
        percent: 100,
        coverUrl: item.coverUrl,
        type: item.type,
        caption: item.caption,
        params: {
          hdrImageUrl: item.params?.hdrImageUrl,
          threeDBgUrl: item.params?.threeDBgUrl,
          specialEffectTemplateId: item.params?.specialEffectTemplateId,
          specialEffectVideo: item.params?.specialEffectVideo,
        }
      }
    }
    )
  }

  useEffect(() => {

  }, [id])

  useEffect(() => {
    if (id) {
      let tempCate = 'theme'
      apiGetWorkField(+id!).then(resField => {
        setFieldList(resField)
        resField.forEach(item => {
          if (item.hasCategory) {
            tempCate = item.code
            setCate(item.code)
          }
        }
        )

        if (workId) {
          apiDraftWorkDetail(+workId!).then(res => {
            let { categoryList, title,  description, hardwareList, softwareList, mediaCategory,  contestWork } = res
            let { theme, detailSupplementImage, draftImage, effectImage, effectVideo, processImage, textureImage, threeDImage, team } = contestWork
            setCoverUrlFile({
              file: undefined,
              imgUrl: res.coverUrl
            })

            console.log([theme!, categoryList[categoryList.length - 1].id])

            form.setFieldsValue({
              title: title, //标题
              theme: [theme!],
              mediaCategory: [mediaCategory],
              team: [team],
              detailSupplementImage: getItem(detailSupplementImage),
              draftImage: getItem(draftImage),
              effectImage: getItem(effectImage),
              effectVideo: getItem(effectVideo),
              processImage: getItem(processImage),
              textureImage: getItem(textureImage),
              threeDImage: getItem(threeDImage),
              description: description, //描述
              categoryId: categoryList && categoryList[categoryList.length - 1].id, // 类目ID
              softwareList: softwareList?.map(item => item.name),
              hardwareList: hardwareList?.map(item => item.name), // 软硬件标签
              tagList: res.tagList?.map(item => item.name),
            })
            // @ts-ignore
            let arr = form.getFieldValue(tempCate)
            arr.push(categoryList[categoryList.length - 1].id)

            form.setFieldValue(tempCate, arr)
            console.log(form.getFieldValue(tempCate))
          })
        }
      })
    }

  }, [id, workId])


  const getComponent = (item: SignupField) => {
    if (item.code === 'theme' || item.code === 'team' || item.code === 'mediaCategory') {
      if (item.hasCategory) {
        return <Form.Item
          key={item.code}
          label={item.name}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <CategorySecondSelect data={item.options}  ></CategorySecondSelect>
        </Form.Item>
      } else if (item.hasSecondOption) {
        return <Form.Item
          key={item.code}
          label={item.name}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <SubChildrenSelect data={item.options} multiple={false}></SubChildrenSelect>
        </Form.Item>
      } else {
        return <Form.Item
          key={item.code}
          label={item.name}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <SingleSelect data={item.options} multiple={false}></SingleSelect>
        </Form.Item>
      }
    } else {
      const userInfo = JSON.parse(localStorage.getItem('user_info')!)
      if (item.code === 'mobile') {
        form.setFieldValue(item.code, userInfo.mobile)
      }
      if (item.code === 'nickname') {
        form.setFieldValue(item.code, userInfo.username)
      }
      return <Form.Item
        key={item.code}
        label={item.name}
        name={item.code}
        rules={[{ required: !!item.isRequired }]}
      >
        <Input placeholder={`请填写您的${item.name}`}></Input>
      </Form.Item>
    }

  }

  const getMediaComponent = (code: string) => {
    let item = mediaFieldList.find(element =>
      element.code === code
    )
    if (item) {
      if (item.name.includes('视频')) {
        return <Form.Item
          key={item.code}
          label={item.name}
          name={item.code}
          required={item.minCount > 0}
          rules={[{
            validator: (rules, value, callback) => {
              if (!value) {
                callback()
              }
              if ((Array.isArray(value) && value.length >= item!.minCount) || (!Array.isArray(value) && item!.minCount === 0)) {
                console.log(value)
                callback()
              } else {
                callback(`至少上传：${item!.minCount} 个 ${item!.name}`)
              }

            }
          }]}
        >
          <SubVideoWall maxLength={20} mark={item.code} />
        </Form.Item>
      } else {
        return <Form.Item
          key={item.code}
          label={item.name}
          name={item.code}
          required={item.minCount > 0}
          rules={[{
            validator: (rules, value, callback) => {
              if ((Array.isArray(value) && value.length >= item!.minCount) || (!Array.isArray(value) && item!.minCount === 0)) {
                console.log(value)
                callback()
              } else {
                callback(`至少上传：${item!.minCount} 张 ${item!.name}`)
              }
            }
          }]}
        >
          <SubPicturesWall maxLength={20} mark={item.code} />
        </Form.Item>

      }
    }

  }


  const onClickSave = (submit: number, isPre?: boolean) => {
    if (!coverUrlFile?.imgUrl) {
      message.warning('请上传封面！')
      return
    }

    if (workId) {
      form
        .validateFields()
        .then(async (values) => {
          let { title, tagList, description, hardwareList, softwareList, detailSupplementImage, draftImage,
            effectImage, effectVideo, processImage, textureImage, threeDImage, theme, team, mediaCategory
          } = values
          let val = values[cate as 'theme' || 'team' || 'mediaCategory']
          let categoryId = val && val[1]

          let params:any = {
            draftId: +workId!,
            theme: theme && theme[0],
            team: team && team[0],
            mediaCategory: mediaCategory && mediaCategory[0],
            contestId: Number(id),
            coverUrl: coverUrlFile?.imgUrl!,
            tagList: tagList,
            softwareList: softwareList,
            description: description, //描述
            title: title, //标题
            categoryId: categoryId, // 类目ID
            hardwareList: hardwareList, // 软硬件标签
            detailSupplementImage: detailSupplementImage?.map(item => item.id),
            draftImage: draftImage?.map(item => item.id),
            effectImage: effectImage?.map(item => item.id),
            effectVideo: effectVideo?.map(item => item.id),
            processImage: processImage?.map(item => item.id),
            textureImage: textureImage?.map(item => item.id),
            threeDImage: threeDImage?.map(item => item.id),
            publishType: !!isPre? 1 : submit
          }

          if(submit !== 0){
            params.submit = 1
          }

          apiUpdateSubWork(params).then(res => {
            if(!!isPre) {
              window.open(`/work/preview/${workId}`)
              return
            }
            message.success('编辑成功！')
            navigate('/user-center/home/work/draft/list')
          })

          // if (status === 'pre') {
          //   window.open(`/work/preview/${id}`)
          //   return
          // }
          // navigate('/user-creator-center/work/draft/list')
          // message.success('编辑草稿成功！')
        })

    } else {


      form
        .validateFields()
        .then(async (values) => {
          let { title, tagList, description, hardwareList, softwareList, detailSupplementImage, draftImage,
            effectImage, effectVideo, processImage, textureImage, threeDImage, theme, team, mediaCategory
          } = values
          let val = values[cate as 'theme' || 'team' || 'mediaCategory']
          let categoryId = val && val[1]

          let params:any = {
            theme: theme && theme[0],
            team: team && team[0],
            mediaCategory: mediaCategory && mediaCategory[0],
            contestId: Number(id),
            coverUrl: coverUrlFile?.imgUrl!,
            tagList: tagList,
            softwareList: softwareList,
            description: description, //描述
            title: title, //标题
            categoryId: categoryId, // 类目IDWE
            hardwareList: hardwareList, //W 软硬件标签
            detailSupplementImage: detailSupplementImage?.map(item => item.id),
            draftImage: draftImage?.map(item => item.id),
            effectImage: effectImage?.map(item => item.id),
            effectVideo: effectVideo?.map(item => item.id),
            processImage: processImage?.map(item => item.id),
            textureImage: textureImage?.map(item => item.id),
            threeDImage: threeDImage?.map(item => item.id),
            publishType: !!isPre? 1 : submit
          }

          if(submit !== 0){
            params.submit = 1
          }
          
          apiCreateSubWork(params).then(res => {
            if(!!isPre) {
              navigate(`/contest/upload/${id}?workId=${res.id}`)
              window.open(`/work/preview/${res.id}`)
              return
            }
            message.success('创建成功')
            navigate('/user-center/home/work/draft/list')
          })

          // if (status === 'pre') {
          //   window.open(`/work/preview/${id}`)
          //   return
          // }
          // navigate('/user-creator-center/work/draft/list')
          // message.success('编辑草稿成功！')
        })
    }
  }

  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      setLoading(true)
      let urlKey = getFileName(CosDirectoryType.workCover, params.file.name)
      let res = await getFileUrlByCosUpload(
        params.file!,
        urlKey
      ).finally(() => {
        setLoading(false)
      })
      setCoverUrlFile({
        file: undefined,
        imgUrl: res.url
      })

    }
  }

  return (
    <SubUpdateStyle>
      <div className="main-container">
        <div className="left">
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            requiredMark={true}
            className="form-area"
            form={form}
          >
            <div className="form-section">

              <Form.Item
                label="作品标题"
                name="title"
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="为您的作品起一个好听的名字" />
              </Form.Item>
              {fieldList?.map(item => getComponent(item))}

              {mediaNameList?.map(item => getMediaComponent(item))}

              <Form.Item label="作品描述" name="description">
                <Input.TextArea />
              </Form.Item>
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.categoryIds !== currentValues.categoryIds
              }
            >
              {({ getFieldValue }) =>
                getFieldValue(cate) ? (
                  <div className="form-section">
                    <Form.Item label="标签" name="tagList" >
                      <TagSelect dataTable="work" categoryId={form.getFieldValue(cate) && form.getFieldValue(cate)![1]} maxCount={5}></TagSelect>
                    </Form.Item>
                  </div>
                ) : null
              }
            </Form.Item>
            <div className="form-section">
              <div className="custom-title">创作工具</div>
              <Form.Item label="软件" name="softwareList">
                <CreationWare
                  type={1}
                  selectMode="multiple"
                ></CreationWare>
              </Form.Item>
              <Form.Item label="硬件" name="hardwareList">
                <CreationWare
                  type={2}
                  selectMode="multiple"
                ></CreationWare>
              </Form.Item>
            </div>
          </Form>

        </div>
        <div className="right">
          <div className="card">
            <div className="header"><span style={{ color: 'red', marginRight: '5px' }}>*</span>作品封面</div>
            <div className="image-crop-wrapper">
              <Spin spinning={loading}>
                <ImageCrop value={coverUrlFile} onChange={(params: { imgUrl: string; file: File | undefined }) => {
                  setCoverUrlFile({ ...params })
                }} uploadImage={upload} />
              </Spin>
            </div>
          </div>


          <Affix offsetTop={top}>
            <div className="card">
              {/* <div className="header">发布选项</div> */}

              <div className="footer">
                <div className="choosePublic">

                </div>
                <Space direction="vertical" className="block-btn" >
                  {/* <Button onClick={() => onClickSave(1, true)} block >
                    预览
                  </Button> */}
                  <Button onClick={() => onClickSave(1)} block >
                    阶段发布
                  </Button>
                  <Button type="primary" onClick={() => onClickSave(2)} block >
                    最终提交
                  </Button>
                </Space>
              </div>
            </div>
          </Affix>
        </div>
      </div>
    </SubUpdateStyle>
  );
}

