import {
  Menu,
  MenuProps,
  Tooltip,
  Upload,
  UploadProps,
  message,
} from "antd";
import { UserCenterLayoutStyle } from "./style";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { RiArrowRightSLine, RiEditLine } from "react-icons/ri";
import {
  determinRightPannelWidth,
  determineUserBackground,
  mapCurrentPath,
} from "./fn";
import { getFileUrlByCosUpload } from "config/cos";
import { ProgressInfo } from "cos-js-sdk-v5";
import { makeFilePath } from "utils/functions/file";
import { apiUserDetail, postUserDetail } from "api/user/detail";
import { userAllDetail } from "types/user/detail";
import UserCenterProfile from "./user-profile";
import { UserBaseInfo } from "types/user";
import { activeGetLocalUserInfo } from "utils/functions/common";

type MenuType = {
  key: string;
  icon: React.ReactNode;
  label: string;
  children?: MenuType[];
};

function UserCenterLayout() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

  const itemsData: MenuType[] = [
    { key: "/user-center/home/work/list", icon: <></>, label: "中心首页" },
    { key: "/user-center/badges", icon: <></>, label: "成就徽章" },
    { key: "/user-center/certificates", icon: <></>, label: "荣誉证书" },
    {
      key: "setting",
      icon: <></>,
      label: "资料设置",
      children: [
        { key: "/user-center/user-detail", icon: <></>, label: "基本信息" },
        { key: "/user-center/authentication/idCard", icon: <></>, label: "实名认证" },
        { key: "/user-center/address", icon: <></>, label: "收货地址" },
        { key: "/user-center/account", icon: <></>, label: "账号设置" },
      ],
    },
    { key: "/user-center/redeem", icon: <></>, label: "G分兑换" },
    // { key: "/user-center/user-order", icon: <></>, label: "我的账户" },
    { key: "/user-center/certified-author", icon: <></>, label: "认证作者" },
    { key: "/user-center/wallet/index", icon: <></>, label: "我的钱包" },
    { key: "/user-center/shop/product-list", icon: <></>, label: "我的店铺" },
    { key: "/user-center/order?state=-1", icon: <></>, label: "我的订单" }
  ];

  const [items, setItems] = useState(itemsData)

  const onClick: MenuProps["onClick"] = (item) => {
    setCurrentPath(item.key);
    navigate(item.key);
  };
  let location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [userDetail, setUserDetail] = useState<userAllDetail>();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);


  useEffect(() => {
    if (!userInfo) {
      return
    }
    // if (userInfo?.shopId && userInfo?.shopId > 0) {
    //   const index = items.findIndex(item => item.key === '/user-center/shop/product-list')
    //   if (index > -1) {
    //     return
    //   }
    //   items.push({ key: "/user-center/shop/product-list", icon: <></>, label: "我的店铺" })
    //   setItems([...items])
    // }

    const index = items.findIndex(item => item.key === '/user-center/nft-album')
    if (index > -1) {
      return
    }
    items.push({ key: "/user-center/nft-album", icon: <></>, label: "我的图鉴" })
    setItems([...items])

  }, [userInfo, items])

  const props: UploadProps = {
    beforeUpload: (file) => {
      setLoading(true);
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("只允许上传 JPG/PNG 格式的文件!");
        setLoading(false);
        return;
      }
      if (file.size > 5242880) {
        message.error("图片大小不能超过5M");
        setLoading(false);
        return;
      }
      getFileUrlByCosUpload(
        file as File,
        makeFilePath("background", file?.name ?? ""),
        (progressData: ProgressInfo, key: string) => { }
      ).then((res) => {
        saveUserBackground(res.url);
      });
    },
    showUploadList: false,
  };

  const saveUserBackground = (url: string) => {
    postUserDetail({ bgImage: url }).then(() => {
      getUserDetail();
    });
  };

  useEffect(() => {
    getUserDetail();
    activeGetLocalUserInfo();
  }, []);

  const getUserDetail = () => {
    apiUserDetail().then((res) => {
      setUserDetail(res);
    });
  };

  return (
    <UserCenterLayoutStyle>
      {/* <div className="layout-padding"></div> */}
      <div
        className="banner"
        style={{
          backgroundImage: `url(${determineUserBackground(
            userDetail?.userInfo?.bgImage ?? ""
          )})`,
        }}
      >
        <div className="actions">
          <Tooltip title="自定义背景，建议比例 1920x300">
            <Upload {...props}>
              <div className="item">
                <RiEditLine className="icon-btn"></RiEditLine>
              </div>
            </Upload>
          </Tooltip>
        </div>

        <div className="to-user-frontpage">
          <div
            className="link"
            onClick={() => navigate(`/user/${userDetail?.id}`)}
          >
            <span>前往我的主页</span>
            <RiArrowRightSLine className="icon"></RiArrowRightSLine>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="left-pannel">
          <UserCenterProfile from="user-center" isCanEdit={true} type="user-center"></UserCenterProfile>
          <Menu
            onClick={onClick}
            mode="inline"
            items={items}
            className="menu"
            selectedKeys={[mapCurrentPath(currentPath)]}
          />
        </div>
        <div className="right-pannel">
          <div className="wrapper" style={{ width: determinRightPannelWidth(location.pathname) }}>

            <Outlet />
          </div>

        </div>
      </div>
    </UserCenterLayoutStyle>
  );
}

export default UserCenterLayout;
