import { MallReviewListResponse, orderDetailResponse } from "types/product";
import { OrderStateStyle } from "./style";
import { RiInformationLine } from "react-icons/ri";
import { Button, Modal, message } from "antd";
import {
  getMallReviewDetail,
  postConfirmOrder,
  postResetOrder,
  postSearchOrderSuccess,
} from "api/shop";
import { useEffect, useState } from "react";
import { PayType } from "types/enums/commodity-pay-type";
import {
  apiPostAliPayCommodity,
  apiPostWeixinPayCommodity,
} from "api/commodity";
import EvaluateOrder from "./evaluate";
import { ChatRoom } from "components/chat";
import PayModal from "./payModal";
import { useNavigate } from "react-router-dom";
import Countdown from "antd/es/statistic/Countdown";
import ExpressInfo from "components/expressInfo";
import { translateRefundDetailStatus } from "../../fn";
import { DeliveryStatusArr } from "types/enums/commodity-status-type";
import dayjs from "dayjs";
import { initChatService, logoutChat, orderPayedSendMessage, orderReceiptOfGoodsSendMessage } from "utils/functions/chat";

const OrderState = ({
  detail,
  update,
  getWeixinCode,
  clear,
  onNeedDetailRefresh
}: {
  clear: () => void;
  detail: orderDetailResponse;
  update: () => void;
  getWeixinCode: (code: string) => void;
  onNeedDetailRefresh: () => void
}) => {
  const [loading, setLoading] = useState(false);
  const [isEvaluateShow, setIsEvaluateShow] = useState(false);
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [isShowPayModal, setIsShowPayModal] = useState(false);
  let state = new URLSearchParams(window.location.search).get("state");
  let from = new URLSearchParams(window.location.search).get("from");
  const [reviewDetail, setReviewDetail] = useState<MallReviewListResponse>();
  const [disabled, setDisabled] = useState(false);
  const [isUpdatePrice, setIsUpdatePrice] = useState(false)
  const navigate = useNavigate();
  const [isYueGao, setIsYueGao] = useState(false)
  const reset = () => {
    Modal.confirm({
      title: "确定取消？",
      content: "取消订单后 订单失效！",
      okText: "确定",
      cancelText: "取消",
      onCancel() { },
      centered: true,
      onOk() {
        setLoading(true);
        postResetOrder(detail.orderNumber)
          .then(() => {
            message.success("取消成功");
            update();
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  const pay = async () => {
    if (detail.mallProduct.isPrivateInquiry && detail.amount === 0) {
      message.warning("请联系商家修改价格")
      return
    }
    clear();
    setIsShowPayModal(true);
  };

  useEffect(() => {
    // 查询是否是阿里支付并发送消息
    if (from === 'ali' && !!detail && detail?.mallProduct?.categoryList[0]?.code === 'yuegao') {
      initChatService(detail.sellerUserId)
      postSearchOrderSuccess(detail.orderNumber).then(res => {
        if (res.state === 1) {
          setTimeout(() => {
            console.log("支付成功")
            orderPayedSendMessage(detail)
            let url = new URL(window.location.href);
            console.log(url.searchParams)
            url.searchParams.delete("from");
            window.history.pushState({ path: url.href }, '', url.href);
          }, 1000)
        }
      })
    }
  }, [from, detail])

  useEffect(() => {
    const { amount, rmbPrice, num } = detail.mallProduct
    setIsUpdatePrice((rmbPrice * num) !== amount)
    if (detail?.mallProduct.categoryList[0].code === 'yuegao') {
      setIsYueGao(true)
    }
    return () => {
      logoutChat()
    }
  }, [detail])

  const submitPay = async (payType: number) => {
    const { orderNumber } = detail;
    if (PayType.COMMODITY_PAY_WEIXIN === payType) {
      setLoading(true);
      const payDetail = await apiPostWeixinPayCommodity(String(orderNumber)).finally(() => {
        setLoading(false)
      })
      getWeixinCode(payDetail.code_url);
    } else if (PayType.COMMODITY_PAY_ALI === payType) {
      const payDetail = await apiPostAliPayCommodity({
        orderNumber: String(orderNumber),
        returnUrl: `user-center/shop/order/detail/${orderNumber}?from=ali`,
      });
      if (!isUpdatePrice && isYueGao) {
        Modal.confirm({
          title: '提示',
          content: <>
            <div>约稿商品请和卖家协商后再进行支付</div>
          </>,
          okText: "继续支付",
          cancelText: "取消",
          centered: true,
          onOk() {
            AliPay(payDetail)
          },
        });
      } else {
        AliPay(payDetail)
      }
    }
    setIsShowPayModal(false);
    setLoading(false);
  };

  const AliPay = async (payDetail: any) => {
    var form = payDetail.body;
    const div = document.createElement("div");
    div.innerHTML = form; //此处form就是后台返回接收到的数据
    document.body.appendChild(div);
    document.forms[0].submit();
  }

  // const applyService = () => {
  //   Modal.info({
  //     title: "申请售后",
  //     content: "请添加官方客服微信：AboxGGAC",
  //     okText: "关闭",
  //     centered: true,
  //     cancelText: "",
  //     icon: (
  //       <RiInformationFill
  //         style={{ color: "#FF9A16", marginTop: "5px", marginRight: "5px" }}
  //         size={18}
  //       ></RiInformationFill>
  //     ),
  //   });
  // };

  useEffect(() => {
    if (detail.state === 3 && detail.reviewState === 1 && detail.orderNumber) {
      getMallReviewDetail(detail.orderNumber).then((res) => {
        setReviewDetail(res);
        setDisabled(true);
      });
    }
  }, [detail]);

  useEffect(() => {
    if (state === undefined || state === null) {
      return;
    }
    if (!detail) {
      return;
    }
    const { pathname } = window.location;
    if (Number(state) === 0) {
      setIsShowPayModal(true);
    } else if (
      Number(state) === 3 &&
      (detail.reviewState === 0 || !detail.reviewState)
    ) {
      setIsEvaluateShow(true);
    }
    navigate(pathname, { replace: true });
  }, [state, detail]);

  const submitReceipt = () => {
    Modal.confirm({
      title: "确认收货",
      content: "请确认无误后再确认收货",
      okText: "确定",
      cancelText: "取消",
      onCancel() {
        if (isYueGao) {
          logoutChat()
        }
      },
      centered: true,
      onOk() {
        setLoading(true);
        postConfirmOrder(detail.orderNumber)
          .then(() => {
            message.success("确认收货成功");
            update();
            if (isYueGao) {
              orderReceiptOfGoodsSendMessage(detail, () => logoutChat)
            }
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
    if (isYueGao) {
      initChatService(detail.sellerUserId)
    }
  };

  const lookReview = () => {
    setIsEvaluateShow(true);
  };

  const onClickOpenChat = () => {
    setChatModalOpen(true);
  };
  const handleCloseModal = () => {
    setChatModalOpen(false);
  };

  const submitComments = () => {
    setIsEvaluateShow(true);
  };

  return (
    <OrderStateStyle>
      <div className="state">
        <div className="tip">
          <RiInformationLine />
          {detail.state === 0 && <div>待支付 金额：￥{(detail.mallProduct.amount / 100).toFixed(2)}</div>}

          {detail.state === 1 && <div>订单已支付，等待卖家发货</div>}
          {
            detail.state === 1 && !!detail.mallProduct.lastSendTime && <div className="value" style={{ paddingLeft: 4, fontWeight: "bold" }}>
              {detail.mallProduct.lastSendType === 3 ? '预售' : ''} {dayjs(detail.mallProduct.lastSendTime).format("YYYY年MM月DD日 HH时")}前发货
            </div>
          }
          {
            detail.state === 1 && (detail.mallProduct.lastSendType === 0 || !!detail.mallProduct.lastSendType) && <div className="item">
              {
                detail.mallProduct.lastSendType < 2 && <div className="value" style={{ paddingLeft: 4 }}>
                  {DeliveryStatusArr.find(item => item.value === detail.mallProduct.lastSendType)?.label}内发货
                </div>
              }

              {
                detail.mallProduct.lastSendType === 3 && <div className="value" style={{ paddingLeft: 4 }}>
                  预售 {dayjs(detail.mallProduct.lastSendTime).format("YYYY年MM月DD日 HH时")}前发货
                </div>
              }
            </div>
          }
          {detail.state === 2 && (
            <>
              <div>{(detail.mallProduct.isAuthorization === 1 || detail.mallProduct.isManuscript === 1) ? "已支付" : "卖家已发货"}</div>
            </>
          )}
          {detail.state === 3 && <div>订单已完成</div>}
          {detail.state === 4 && (
            <div className="row-cell">
              {detail.closeType === 1 ? "逾期未支付" : ""}
              {detail.closeType === 2 ? "买家取消" : ""}
              {detail.closeType === 3 ? "卖家取消" : ""}
              {detail.closeType === 4 ? "平台取消" : ""}
            </div>
          )}
        </div>
        {detail && detail.refund && (
          <div className="tip">
            <span className="label">售后状态：</span> <span className="red">{translateRefundDetailStatus(detail, 'buyer')}</span>
          </div>
        )}
        {detail.state === 0 && (
          <div className="btns">
            <Button loading={loading} type="primary" onClick={pay}>
              立即支付
            </Button>
            <Button loading={loading} type="primary" onClick={reset}>
              取消订单
            </Button>
          </div>
        )}

        {/* {
                detail.state === 2 && detail.mallProduct.productType === 2 && <div>
                    <div className="express">
                        <div className="item">
                            <div className="label">物流名称</div>
                            <div className="valie">{detail?.expressName}</div>
                        </div>
                        <div className="item">
                            <div className="label">物流单号</div>
                            <div className="valie">{detail?.expressNumber}</div>
                        </div>
                    </div>

                </div>
            } */}
        {detail.mallProduct.productType === 2 && (
          <div className="logistic">
            {(detail.state === 2 || detail.state === 3) && (
              <>
                <div className="express">
                  <div className="item">
                    <div className="label">物流名称</div>
                    <div className="valie">{detail?.expressName}</div>
                  </div>
                  <div className="item">
                    <div className="label">物流单号</div>
                    <div className="valie">{detail?.expressNumber}</div>
                  </div>
                </div>
              </>
            )}
            {detail.state === 2 && (
              <div className="time">
                <span>
                  <Countdown
                    value={Date.now() + detail.autoConfirmSecond * 1000}
                    format="D 天 HH : mm : ss "
                  />
                </span>
                &nbsp;&nbsp;后自动收货
              </div>
            )}
          </div>
        )}
        {detail.expressNumber && (
          <ExpressInfo orderNumber={detail.orderNumber}></ExpressInfo>
        )}
        <div className="btns">
          {/* {detail.state === 1 && (
            <div>
              <Button type="primary" onClick={applyService}>
                申请售后
              </Button>
            </div>
          )}   */}
          {detail.state === 2 && (
            <Button type="primary" onClick={submitReceipt}>
              确定收货
            </Button>
          )}
          {detail.state === 3 &&
            (detail.reviewState === 0 || !detail.reviewState) && (
              <div>
                <Button type="primary" onClick={submitComments}>
                  发表评价
                </Button>
              </div>
            )}
          {detail.state === 3 && detail.reviewState === 1 && (
            <div>
              <Button type="primary" onClick={lookReview}>
                查看评价
              </Button>
            </div>
          )}
          {(detail.state === 1 || detail.state === 2) &&
            detail.mallProduct.productType === 3 && (
              <div>
                <Button type="primary" onClick={onClickOpenChat}>
                  立即咨询
                </Button>
              </div>
            )}
        </div>
      </div>
      {chatModalOpen && (
        <ChatRoom
          show={chatModalOpen}
          close={handleCloseModal}
          toUserId={detail!.sellerUserId}
          orderNumber={detail!.orderNumber}
        ></ChatRoom>
      )}

      <EvaluateOrder
        disabled={disabled}
        reviewDetail={reviewDetail}
        isEvaluateShow={isEvaluateShow}
        update={update}
        onCancel={() => setIsEvaluateShow(false)}
        orderNumber={detail.orderNumber}
      />
      {detail && (
        <PayModal
          detail={detail}
          submitPay={submitPay}
          isShow={isShowPayModal}
          onCancal={() => { setIsShowPayModal(false); logoutChat() }}
          onNeedDetailRefresh={onNeedDetailRefresh}
        />
      )}
    </OrderStateStyle>
  );
};

export default OrderState;
