import UiAvatar from "ui-lib/avatar";
import { UserCenterProfileStyle } from "./style";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { PublicUserStatistics, publicUserDetailType } from "types/user/detail";
import { apiPublicUserDetail, apiPublicUserStatistics } from "api/user/detail";
import { RiEdit2Fill, RiStarFill } from "react-icons/ri";
import UpdateUserName from "./updateUserName";

import { Button, Checkbox, Popover, message } from "antd";
import { getLocalUserInfo } from "utils/functions/common";
import { apiUpdateUserCfg, postFollowUser } from "api/user";
import { useNavigate, useParams } from "react-router-dom";
import { UserBaseInfo } from "types/user";
import { renderTagline } from "./fn";
import BadgeModal from "components/badge/badge-modal";
import UserBusinessContact from "components/user-business-contact";

function UserCenterProfile({ type, from, setCommentCount, isCanEdit = false }: { type?: string, setCommentCount?: (count: number) => void, isCanEdit?: boolean, from?: string }) {
  const [statistics, setStatistics] = useState<PublicUserStatistics>();
  const [userDetail, setUserDetail] = useState<publicUserDetailType>();
  const [visible, setVisible] = useState(false);
  const [focusLoading, setFocusLoading] = useState(false)
  const params = useParams();
  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
  const [isShowBadgeModal, setIsShowBodgeModal] = useState(false)
  const [isShowBusiness, setIsShowBusiness] = useState(false)



  const onClose = () => {
    setIsShowBusiness(false)
  }

  const openBadgeModal = () => {
    if (statistics?.badgeCount === 0) {
      return
    }
    setIsShowBodgeModal(true)
  }

  useEffect(() => {
    getDetail();
  }, [params.id]);
  const ref = useRef<HTMLDivElement>(null);

 
  const navigate = useNavigate()

  

  const getDetail = () => {
    let userId = undefined;
    if (type === "user-center") {
      userId = getLocalUserInfo().id;
    }
    if (type === "user-frontpage") {
      userId = params.id;
    }
    if (!userId) {
      return;
    }

    apiPublicUserDetail(userId).then((userDetail) => {
      setUserDetail(userDetail);
    });
    apiPublicUserStatistics({ id: Number(userId) }).then((res) => {
      setCommentCount && setCommentCount(res.commentCount)
      setStatistics(res);
    });
  };

  if (!userDetail) {
    return <></>;
  }

  const updateName = () => {
    getDetail()
  }

  const btnContent = () => {
    return (
      <div style={{ width: "200px" }}>
        <div className="btn-content">
          <Button
            type="primary"
            onClick={() => window.open("/work/create", "_blank")}
          >
            上传作品
          </Button>
        </div>
        <div className="btn-content">
          <Button
            type="primary"
            onClick={() => window.open("/article/create", "_blank")}
          >
            上传文章
          </Button>
        </div>
      </div>
    );
  };

  const updateCfgCopyright = (copyight: boolean) => {
    apiUpdateUserCfg({ isCopyright: Number(copyight) }).then(() => {
      getDetail();
    });
  };
  const updateCfgHandOff = (handoff: boolean) => {
    apiUpdateUserCfg({ isHandoff: Number(handoff) }).then(() => {
      getDetail();
    });
  };

  const followUser = () => {
    setFocusLoading(true)
    const flag = userDetail?.isFollow === 1 ? 0 : 1
    postFollowUser(
      {
        followee: userDetail?.id!,
        flag
      }
    ).then(() => {
      message.success("操作成功")
      if (userDetail) {
        userDetail.isFollow = flag
      }
      setUserDetail(userDetail)
    }).finally(() => {
      setFocusLoading(false)
    })
  }



  const toDetail = (value: string) => {
    if (userInfo && (userInfo.id === userDetail.id)) {
      if (value === 'myfans') {
        navigate("/user-center/fans")
      } else if (value === 'focus') {
        navigate("/user-center/followee")
      }
    } else {
      if (value === 'myfans') {
        navigate(`/user/${userDetail.id}/fans`)
      } else if (value === 'focus') {
        navigate(`/user/${userDetail.id}/followee`)
      }
    }
  }

  const toEdit = () => {
    navigate("/user-center/user-detail")
  }

  return (
    <UserCenterProfileStyle>
      <div className="avatar">
        <UiAvatar
          url={userDetail?.avatarUrl}
          username={userDetail?.username}
          certifiedRoleId={userDetail?.certifiedRoleId}
        ></UiAvatar>
        {
          isCanEdit && <div className="block" onClick={toEdit}>
            编辑
          </div>
        }
      </div>

      <div className="user-name">
    <div className="name" title={userDetail.username} ref={ref}>{userDetail.username}</div>   
     <div className="edit-box">
        {type === "user-center" && (
          <RiEdit2Fill onClick={() => setVisible(true)} className="edit" />
        )}
        <div className="tag" onClick={() => navigate(`/user-center/quests`)}> Lv.{userDetail.level}</div>
        </div>
        <UpdateUserName
          username={userDetail.username}
          updateVisible={() =>{
            setVisible(false)
            window.location.reload()
          }}
          visible={visible}
          updateName={updateName}
        />
      </div>
      <div className="badge-count" onClick={openBadgeModal}>
        <div className="icon">
          <RiStarFill></RiStarFill>
        </div>
        <span>徽章{statistics?.badgeCount}枚</span>
      </div>
      <div className="user-info">
        {renderTagline(userDetail)}
        <div className="user-signature">
          <span>{userDetail.userInfo.signature}</span>
        </div>
      </div>
      {type === "user-center" && (
        <div className="user-config">

          <Checkbox
            onChange={(e) => updateCfgHandOff(e.target.checked)}
            checked={Boolean(userDetail.userInfo.isHandoff)}
          >
            约稿
          </Checkbox>
          <Checkbox
            onChange={(e) => updateCfgCopyright(e.target.checked)}
            checked={Boolean(userDetail.userInfo.isCopyright)}
          >
            版权
          </Checkbox>
        </div>
      )}
      {type === "user-center" && (
        <div className="upload-btns">
          <Popover
            placement="bottom"
            content={btnContent}
            getPopupContainer={(trigger) => trigger}
            trigger="click"
            className="content-wrap"
          >
            <Button block className="btn" type="primary">
              <span className="text">上传 </span>
            </Button>
          </Popover>
        </div>
      )}
      {type === "user-frontpage" &&
        !(Number(params.id) === getLocalUserInfo()?.id) && (
          <>
            {!userDetail?.isFollow && (
              <Button block type="primary" onClick={followUser} loading={focusLoading}>
                <span className="text">关注</span>
              </Button>
            )}
            {Boolean(userDetail?.isFollow) && (
              <Button block type="default" onClick={followUser} loading={focusLoading} className="defaultBtn">
                <span className="text">已关注</span>
              </Button>
            )}
             {
                userDetail.userInfo.isCooperation === 1 && <Button block type="default" className="defaultBtn" onClick={() => setIsShowBusiness(true)}>
                合作邀约
              </Button>
              }
          </>
        )}



      <div className="statistics">
        <div className="item">
          <span>浏览量</span>
          <span className="num">{statistics?.viewCount}</span>
        </div>
        <div className="item">
          <span>点赞</span>
          <span className="num">{statistics?.likeCount}</span>
        </div>
        <div className="item pointer" onClick={() => toDetail("focus")}>
          <span>关注</span>
          <span className="num">{statistics?.followCount}</span>
        </div>
        <div className="item pointer" onClick={() => toDetail("myfans")}>
          <span>粉丝</span>
          <span className="num">{statistics?.fansCount}</span>
        </div>
      </div>
      <BadgeModal isShowRodio={from === 'user-center'} closeModal={() => setIsShowBodgeModal(false)} badgeCount={statistics?.badgeCount!} isShow={isShowBadgeModal} userDetail={userDetail} />
     {userDetail && <UserBusinessContact partnerUser={{ name: userDetail?.username, id: userDetail?.id }} onClose={onClose} isShow={isShowBusiness} />} 

    </UserCenterProfileStyle>
  );
}

export default UserCenterProfile;
