import styled from "styled-components";

export const PrizeIconsStyle = styled.div`

    .item{
        margin-right: ${(props) => props.theme.space.paddingXXS}px;
        &.stage-prize{
            border-radius:20px;
            margin-top: ${(props) => props.theme.space.paddingXS}px;
            margin-right: ${(props) => props.theme.space.paddingXS}px;
            padding: 1px 0;
            background-color: ${(props) => props.theme.colors.colorPrimary};
            color: ${(props) => props.theme.colors.colorText} !important;
            display: flex;
            align-items: center;
            .title{
                padding: 0 4px;
                padding-left: 8px;
            }
            .avatar{
                width: 24px;
                height: 24px;
                border-radius: 50% 50%;
            }
            .default-icon {
                /* position: absolute; */
                width: 10px;
                padding: 0 5px;
            }
        }
    }
`