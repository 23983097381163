import styled from "styled-components";

export const UserCenterProfileStyle = styled.div`
  border: 1px solid ${(props) => props.theme.colors.colorBorder};
  border-radius: ${(props) => props.theme.border.borderRadiusLG}px;
  padding:30px ${(props) => props.theme.space.paddingLG}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:  ${(props) => props.theme.border.borderRadiusLG * 2}px;
  background-color: ${(props) => props.theme.colors.colorTextLightSolid};
  /* width: 100%; */
  min-width: 270px;

  .user-name {
    .tag {
      background-color: ${(props) => props.theme.colors.colorPrimary};
      padding: 0 ${(props) => props.theme.space.paddingXS}px;
      border-radius: ${(props) => props.theme.border.borderRadiusLG + 4}px;
      cursor: pointer;
    }
    .name {
      font-size: ${(props) => props.theme.fontSizes.fontSizeHeading4}px;
      font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
      margin-right: ${(props) => props.theme.space.paddingSM}px;
      max-width: 200px;
      text-align: center;
    }
    .edit-box{
      display: flex;
      align-items: center;
    }
    .edit {
      margin-right: ${(props) => props.theme.space.marginXS}px;
      cursor: pointer;
      display: inline-block;
    }
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .badge-count {
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.colorPrimary};
    padding: 0 ${(props) => props.theme.space.padding}px;
    padding-left: 42px;
    border-radius: ${(props) => props.theme.border.borderRadiusLG * 4}px;
    position: relative;
    height: 28px;
    color: ${(props) => props.theme.colors.colorText};
    cursor: pointer;
    .icon {
      background-color:${(props) => props.theme.colors.colorPrimary};
      aspect-ratio: 1/1;
      border-radius: 50%;
      width: 28px;
      color: ${(props) => props.theme.colors.colorTextLightSolid};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      position: absolute;
      left: 0;
    }
  }
  .user-config{
    padding-left: 8px;
  
  }
  .user-info{

    .user-tagline{
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            padding:0 4px;
        }
    }
    .user-signature{
        font-size:  ${(props) => props.theme.fontSizes.fontSizeSM}px;
        color: ${(props) => props.theme.colors.colorTextTertiary};
        text-align: center;
        span{
          padding-top:  ${(props) => props.theme.space.paddingXXS}px;
        }
    }
  }
  .defaultBtn{
    color:${(props) => props.theme.colors.colorPrimary} !important;
    border:1px solid ${(props) => props.theme.colors.colorPrimary} !important;

  } 
  .upload-btns{
    padding: ${(props) => props.theme.space.padding}px 0;
         
          width: 100%;
          .btn{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .text{
              padding:0 ${(props) => props.theme.space.paddingXS}px;
            }
          }
          .content-wrap {
            width: 100%;
           
          }
          .btn-content {
            width: 100%;
            margin: ${props => props.theme.space.marginXS}px 0;
            button {
              width: 100%;
            }
          }
  }

  .avatar {
    position: relative;
    &:hover {
      .block {
        display: block;
      }
    }
    .block {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      background-color: rgba(0,0,0,.3);
      border-radius: 50%;
      width: 112px;
      height: 112px;
      transform: translate(-50%,-50%);
      color: ${porps=>porps.theme.colors.colorPrimary};
      cursor: pointer;
      display: none;
      text-align: center;
      line-height: 112px;
    }
  }

  .statistics{
    width: 100%;
    .item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:${(props) => props.theme.space.paddingXXS}px  0;
      .num{
        font-size: 16px;
      }
    }
    .pointer {
      cursor: pointer;
    }
  }
`;
