import { MallProductRecommendListItem } from "types/product";
import { MallProductRecommendStyle } from "./style"
import { useEffect, useState } from "react";
import { apiGetPublicRecommandProductList } from "api/shop";
import RecommandProductCard from "components/product-card/recommand-product-card";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

function MallProductRecommend() {
  const [productList,setProductList] = useState<MallProductRecommendListItem[]>();

  useEffect(() => {
    apiGetPublicRecommandProductList({count:dynamicColumnsFullWidth2()}).then((res) => {
        setProductList(res)
    })
  },[])

  
  return (
    <MallProductRecommendStyle>
          <div className="data-list">
          {productList &&
            productList.map((item, index) => {
              return <RecommandProductCard item={item} key={index} />;
            })}
        </div>
    </MallProductRecommendStyle>
  )
}

export default MallProductRecommend