import { ShopRigthNodeStyle, ShopTopLineStyle } from "./style";
import UISegmentedControl from "ui-lib/segmented-control";
import { useNavigate } from "react-router-dom";
import { RiShareForwardBoxLine } from "react-icons/ri";
import { getFileName, getLocalUserInfo } from "utils/functions/common";
import GlobalShare from "components/share";
import { useEffect, useState } from "react";
import { apiGetMallShareImage, apiPostUpdateMallCoverUrl, getShopDetail } from "api/shop";
import { UserBaseInfo } from "types/user";
import { getFileUrlByCosUpload } from "config/cos";
import { CosDirectoryType } from "types/enums/media-type";
import { ImageCrop } from "components/crop-img";
import { message } from "antd";

function ShopTopLine({ defaultValue }: { defaultValue: string }) {
  const navigate = useNavigate();
  const [showShare, setShowShare] = useState(false)
  const [shareUrl, setShareUrl] = useState("")
  const [shareImageUrl, setShareImageUrl] = useState('')
  const [shareQrCode, setShareQrCose] = useState("")
  let userInfo: UserBaseInfo = JSON.parse(
    localStorage.getItem("user_info")!
  );
  const tabOption = [
    {
      label: "我发布的",
      value: "product",
    },
    {
      label: "我卖出的",
      value: "order",
    },
  ];

  const [coverUrlInfo, setCoverUrlInfo] = useState({
    file: undefined,
    imgUrl: ""
  })

  let userId: number
  if (!!JSON.parse(localStorage.getItem('user_info')!)) {
    userId = JSON.parse(localStorage.getItem('user_info')!).id
  }

  const localUser = getLocalUserInfo()
  const changeTab = (e: any) => {
    if (e === "product") {
      navigate("/user-center/shop/product-list");
    } else if (e === "order") {
      navigate("/user-center/shop/order-list?state=-1");
    }
  };

  const updaetShareImage = () => {
    apiGetMallShareImage(Number(userInfo.shopId)).then(res => {
      setShareImageUrl(res.minaImage)
      setShareQrCose(res.minaQrCode)
    })
  }

  const share = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    apiGetMallShareImage(Number(userInfo.shopId)).then(res => {
      let currentHostWithPrefix = window.location.protocol + '//' + window.location.host;
      setShareUrl(`${currentHostWithPrefix}/user/${localUser.id}/shop?shareUserId=${userId}`)
      setShowShare(true);
      setShareImageUrl(res.minaImage)
      setShareQrCose(res.minaQrCode)
    })
  };

  useEffect(() => {
    if (showShare) {
      getShopDetail().then(res => {
        setCoverUrlInfo({
          file: undefined,
          imgUrl: res.coverUrl,
        })
      })
    }
  }, [showShare])


  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      let urlKey = getFileName(CosDirectoryType.productImage, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      await apiPostUpdateMallCoverUrl(res.url)
      message.success("上传成功")
      updaetShareImage()
      setCoverUrlInfo({
        file: undefined,
        imgUrl: res.url,
      })
    }
  };

  const deleteImage = () => {
    apiPostUpdateMallCoverUrl("").then(() => {
      message.success("删除成功")
      updaetShareImage()
    })
  }

  const rightNode = <ShopRigthNodeStyle>
    <div className="tip">上传店铺封面</div>
    <ImageCrop uploadImage={upload} deleteImage={deleteImage} value={coverUrlInfo} aspect={700 / 500} />
  </ShopRigthNodeStyle>

  return (
    <ShopTopLineStyle>
      <UISegmentedControl
        onValueChange={changeTab}
        options={tabOption}
        value={defaultValue}
      ></UISegmentedControl>
      <div className="share" onClick={(e) => share(e)}>
        <a href="">
          <RiShareForwardBoxLine></RiShareForwardBoxLine>
          <span>分享店铺</span>
        </a>
      </div>
      <GlobalShare
        info={{
          title: "",
          cover_image: "",
          desc: "",
        }}
        shareImageInfo={{ qrCode: shareQrCode, shareImageUrl: shareImageUrl }}
        shareType="shop"
        isShow={showShare}
        onClose={() => {
          setShowShare(false);
        }}
        shareUrl={shareUrl}
        rightNode={rightNode}
      ></GlobalShare>
    </ShopTopLineStyle>
  );
}

export default ShopTopLine;
