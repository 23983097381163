import { useCallback, useEffect, useState } from "react";
import { CategoryFilterStyle } from "./style";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MallProductCategoryListItem, MallWorktypeListItem } from "types/product";
import { apiGetMallProductListCategory, apiGetMallThemeStyleList, apiGetMallWorkTypeList } from "api/shop";
import { RiArrowLeftSLine, RiArrowRightSLine, RiCloseCircleFill, RiCloseCircleLine } from "react-icons/ri";
import { queryUrl } from "utils/functions/common";
import { useKeenSlider } from "keen-slider/react";
import Swiper from "swiper";

import 'swiper/css';

function CategoryFilter({ clearValue, listOne, isYueGao }: { clearValue: () => void, listOne: MallProductCategoryListItem[], isYueGao: boolean }) {
  const [listTwo, setListTwo] = useState<MallProductCategoryListItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  const [isCanSwiper, setIsCanSwiper] = useState(false)
  const catalog = new URLSearchParams(window.location.search).get("catalog") || ""
  const workType = new URLSearchParams(window.location.search).get("workType") || ""
  const categoryPid = new URLSearchParams(window.location.search).get("categoryPid") || ""
  const [workTypeList, setWorkTyleList] = useState<MallWorktypeListItem[]>([])

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    mode: "free",
    initial: 0,
    slides: {
      perView: 9,
      spacing: 10
    },
  });

  useEffect(() => {
    instanceRef && instanceRef.current?.update();
  }, [listOne])

  const [swiper, setSwiper] = useState<any>()

  useEffect(() => {
    apiGetMallWorkTypeList().then(res => {
      setWorkTyleList(res);
    })
  }, []);



  const onClickPid = (pid: number) => {
    searchParams.set("categoryPid", pid.toString());
    if (searchParams.get("categoryId") !== pid.toString()) {
      searchParams.set("pageNumber", "1");
      searchParams.delete("categoryId");
      searchParams.delete("catalog");
      searchParams.delete("workType");
      searchParams.delete("themeStyle");
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    initSwiper()
  }, [listTwo])


  const initSwiper = () => {

    const swiper = new Swiper(".tags_container", {
      slidesPerView: "auto",
      spaceBetween: 10,
    });
    setSwiper(swiper)
  }


  useEffect(() => {
    const categoryPid = searchParams.get("categoryPid")
    const params: any = {
      type: 3,
      level: 2
    }
    if (!!categoryPid) {
      params.pid = categoryPid
      delete params.isRecommand
    } else {
      delete params.pid
      params.isRecommand = 1
    }
    apiGetMallProductListCategory(params).then((res) => setListTwo(res));
  }, [searchParams.get("categoryPid")]);


  const onSecondCategoryClick = (id: number) => {
    searchParams.set("categoryId", id.toString());
    searchParams.set("pageNumber", "1");
    searchParams.delete("catalog");
    setSearchParams(searchParams);
  };

  const getNameAndDesc = () => {
    const pid = searchParams.get("categoryPid");
    const cid = searchParams.get("categoryId");
    if (!pid && !cid) {
      return {
        name: "全部商品",
        desc: "全部商品由GGAC平台创作者提供，为各位创作者和读者们提供更专业、更精致的商品内容。",
      };
    }
    if (pid && !cid) {
      const pItem = listOne.find((item) => item.id === Number(pid));
      return { name: pItem?.name, desc: pItem?.description };
    }
    if (pid && cid) {
      const cItem = listTwo.find((item) => item.id === Number(cid));
      return { name: cItem?.name, desc: cItem?.description };
    }
  };

  const clear = () => {
    clearValue()
    const query = queryUrl({ keyword: "" })
    navigate(`?${query}`)
  }

  const move2 = (type: string) => {
    if (type === "prev") {
      instanceRef.current?.prev();
    }
    if (type === "next") {
      instanceRef.current?.next();
    }
  }

  const move = (flag: string) => {
    if (flag == "prev") {
      swiper.slidePrev();
    } else {
      swiper.slideNext()
    }
  }

  useEffect(() => {
    if (!swiper) {
      return
    }
    setIsCanSwiper(swiper.isBeginning && !swiper.isEnd)
  }, [swiper])

  const checkWorkType = (value: string) => {
    const query = queryUrl({ workType: value, pageNumber: "" })
    navigate(`?${query}`)
  }

  return (
    <CategoryFilterStyle className="filter">
      <div className="row-1">
        {
          listOne.length > 7 && <div className="control prev" onClick={() => move2("prev")}>
            <RiArrowLeftSLine></RiArrowLeftSLine>
          </div>
        }
        <div ref={sliderRef} style={{ justifyContent: listOne.length >= 7 ? 'flex-start' : 'center' }} className="keen-slider">
          <div
            className="block keen-slider__slide"
            onClick={() => {
              searchParams.delete("categoryPid");
              searchParams.delete("categoryId");
              searchParams.delete("catalog");
              setSearchParams(searchParams);
            }}
          >
            <div
              className="block-overlay"
              style={{
                backgroundImage: `url(${'https://cdn-prd.ggac.com/ggac/assets/image/shop/all.jpg'})`,
              }}
            ></div>
            <div
              className={
                !searchParams.get("categoryPid") && !searchParams.get("catalog") ? "text active" : "text"
              }
            >
              全部商品
            </div>
          </div>
          {listOne.map((item, index) => {
            return (
              <div
                className="block keen-slider__slide"
                onClick={() => onClickPid(item.id)}
                key={index}
              >
                <div
                  className="block-overlay"
                  style={{
                    backgroundImage: `url(${item.coverUrl})`,
                  }}
                ></div>
                <div
                  className={
                    searchParams.get("categoryPid") === item.id.toString()
                      ? "text active"
                      : "text"
                  }
                >
                  {item.name}
                </div>
              </div>
            );
          })}
          <div
            className="block keen-slider__slide"
            onClick={() => {
              navigate(`?${queryUrl({ categoryPid: "", categoryId: "", catalog: 1, pageNumber: "", keyword: "" })}`)
            }}
          >
            <div
              className="block-overlay"
              style={{
                backgroundImage: `url(${'https://cdn-prd.ggac.com/ggac/assets/image/shop/shop-nft.png'})`,
              }}
            ></div>
            <div
              className={
                searchParams.get("catalog") ? "text active" : "text"
              }
            >
              数字图鉴
            </div>
          </div>
        </div>

        {
          listOne.length > 7 && <div className="control next" onClick={() => move2("next")}>
            <RiArrowRightSLine></RiArrowRightSLine>
          </div>
        }

      </div>

      {
        !catalog && <>
          {
            !isYueGao && <div className="row-2">
              {
                isCanSwiper && <div className="control prev" onClick={() => move("prev")}>
                  <RiArrowLeftSLine></RiArrowLeftSLine>
                </div>
              }

              <div className="tags_container swiper-container">
                <div className="swiper-wrapper" style={{ justifyContent: isCanSwiper ? "flex-start" : 'center' }}>
                  {listTwo.length > 0 && (
                    <div
                      className={
                        `${!searchParams.get("categoryId") ? 'active' : ''} tag swiper-slide`
                      }

                      onClick={() => {
                        searchParams.delete("categoryId");
                        searchParams.delete("catalog");
                        setSearchParams(searchParams);
                      }}
                    >
                      全部
                    </div>
                  )}
                  {listTwo.map((item, index) => {
                    return (
                      <div
                        className={
                          `tag swiper-slide ${searchParams.get("categoryId") === item.id.toString() ? 'active' : ''}`
                        }
                        onClick={() => onSecondCategoryClick(item.id)}
                        key={index}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>

              {
                isCanSwiper && <div className="control next" onClick={() => move("next")}>
                  <RiArrowRightSLine></RiArrowRightSLine>
                </div>
              }

            </div>
          }
          {
            isYueGao && <div className="select">
              <div className="row-2">
                <div className={`tag ${!workType ? 'active' : ''}`} onClick={() => checkWorkType("")}>全部</div>
                {
                  workTypeList.map(item => {
                    return <div onClick={() => checkWorkType(String(item.id))} className={`tag ${Number(workType) === item.id ? 'active' : ''}`} key={item.id}>{item.name}</div>
                  })
                }
              </div>
            </div>
          }

          {!searchParams.get("keyword") && getNameAndDesc()?.desc && (
            <div className="meta">
              {/* <div className="name">{getNameAndDesc()?.name}</div> */}
              <div className="desc">{getNameAndDesc()?.desc}</div>
            </div>
          )}
          {searchParams.get("keyword") && <div className="meta">
            <div className="desc">搜索：{searchParams.get("keyword")}  <RiCloseCircleFill size={15} onClick={clear} /></div>

          </div>}
        </>
      }
    </CategoryFilterStyle>
  );
}

export default CategoryFilter;
