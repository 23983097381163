import styled from "styled-components";

export const MallSelectStyle = styled.div`
    .search-wrap {
        position: relative;
        z-index: 111;
        width: 262px;
        .input-wrap {
            position: relative;
            .icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
                height: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
                svg {
                    color: rgb(151, 151, 155);
                }
            }
        }
        input {
            overflow: hidden !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 40px;
        }
    }
    .popup-wrap {
        position: absolute;
        width: 100%;
        background-color: #fff;
        top: 45px;
        left: 0;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
        padding: 8px 5px;
        border-radius: 6px;
        height: max-content;
    }
    .active {
        background-color: rgba(0, 0, 0, 0.04);
    }
    .popup {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        row-gap: 0;
        max-height: 300px;
        overflow: auto;
        gap: 5px;
        ::-webkit-scrollbar {
          width: 5px; /* 设置滚动条的宽度 */
          background-color: transparent; /* 滚动条的背景色 */
        }
        ::-webkit-scrollbar-track {
          background: rgb(223, 220, 220); /* 轨道的背景色 */
          border-radius: 10px; /* 轨道的圆角 */
        }
        /* 自定义滚动条的滑块（thumb） */
        ::-webkit-scrollbar-thumb {
          background-color: #7c7b7b; /* 滑块的背景色 */
          border-radius: 10px; /* 滑块的圆角 */
        }
        &-item {
            min-width: 120px;
            text-align: center;
            padding: 8px;
            cursor: pointer;
            border-radius: 4px;
            &:hover{
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
`