import { useEffect, useState } from "react";
import { MarketStyle } from "./style";
import { MallProductCategoryListItem, PublicProductListItem } from "types/product";
import { apiGetMallProductListCategory, apiGetMallThemeStyleList, apiGetPublicProductList } from "api/shop";
import { Input, Pagination, Spin, FloatButton, Radio, Checkbox, Flex, Button, Select } from "antd";
import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel";
import MarketProductCard from "components/product-card/market-product-card";
import { useSearchParams } from "react-router-dom";
import MallProductRecommend from "./recommand";
import CategoryFilter from "./category-filter";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ShopAlumb from "./shop-album";
import MallSelect from "./select";

function Market() {
  const [productList, setProductList] = useState<PublicProductListItem[]>();
  const pageNumber = new URLSearchParams(window.location.search).get("pageNumber") || 1;
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 48;
  const { Search } = Input;

  const [keywordValue, setKeywordValue] = useState("")

  const keyword = new URLSearchParams(window.location.search).get("keyword") || ""
  const catalog = new URLSearchParams(window.location.search).get("catalog") || ""
  const workType = new URLSearchParams(window.location.search).get("workType") || ""
  const themeStyle = new URLSearchParams(window.location.search).get("themeStyle")
  const categoryPid = new URLSearchParams(window.location.search).get("categoryPid") || ""
  const [themeStyleList, setThemeStyleList] = useState<{ name: string, id: string }[]>([])
  const freeFee = searchParams.get("freeFee")
  const [listOne, setListOne] = useState<MallProductCategoryListItem[]>([]);
  const [isYueGao, setIsYueGao] = useState(false)

  useEffect(() => {
    let params: any = {
      pageNumber,
      pageSize,
      sortField: searchParams.get("sortField") ?? "globalRank",
      keyword,
      freeFee: searchParams.get("freeFee") === "1" ? 1 : 0,
    };

    if (searchParams.get("categoryPid") && !searchParams.get("categoryId")) {
      params.categoryId = Number(searchParams.get("categoryPid"));
    }
    if (searchParams.get("categoryPid") && searchParams.get("categoryId")) {
      params.categoryId = Number(searchParams.get("categoryId"));
    }

    if (!searchParams.get("categoryPid") && searchParams.get("categoryId")) {
      params.categoryId = Number(searchParams.get("categoryId"));
    }

    if (workType) {
      params.mallWorkTypeId = workType
    }

    if (themeStyle) {
      params.mallThemeStyleIds = themeStyle
    }


    setLoading(true);

    apiGetPublicProductList(params).then((res) => {
      setProductList(res.pageData);
      setLoading(false);
      setTotal(res.totalSize);
    });

  }, [searchParams, keyword]);

  useEffect(() => {
    setKeywordValue(keyword)
  }, [keyword])

  useEffect(() => {
    const result = listOne.find((item) => {
      return item.code === 'yuegao'
    })
    setIsYueGao(Number(categoryPid) === result?.id)
  }, [listOne, categoryPid])

  useEffect(() => {
    apiGetMallProductListCategory({ type: 3, level: 1 }).then((res) => setListOne(res));
  }, []);

  useEffect(() => {
    apiGetMallThemeStyleList().then(res => {
      const result: { name: string, id: string }[] = JSON.parse(JSON.stringify(res))
      result.forEach((item) => {
        item.id = String(item.id)
      })
      setThemeStyleList(result);
    })
  }, [])

  const changePage = (e: number) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    searchParams.set("pageNumber", e.toString());
    setSearchParams(searchParams);
  };

  const handleChangeSort = (e: any) => {
    searchParams.set("pageNumber", "1");
    searchParams.set("sortField", e.target.value);
    setSearchParams(searchParams);
  }

  const handleCheckFree = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      searchParams.set("freeFee", "1");
    } else {
      searchParams.delete("freeFee");
    }
    setSearchParams(searchParams);
  }

  const onSearch = (e: string) => {
    if (e === '') {
      searchParams.delete("keyword");
    } else {
      searchParams.set("keyword", e.toString());
    }
    setSearchParams(searchParams);
  }

  return (
    <MarketStyle>
      <div className="guanggao-banner-wrapper">
        <GuanggaoBannerCarousel
          isAutoplay={true}
          positionCode={28}
        ></GuanggaoBannerCarousel>
      </div>
      <MallProductRecommend></MallProductRecommend>
      <CategoryFilter listOne={listOne} isYueGao={isYueGao} clearValue={() => setKeywordValue("")}></CategoryFilter>
      {
        !catalog && <>
          <div className="sort">
            <Flex gap={8}>
              <Radio.Group onChange={handleChangeSort} value={searchParams.get("sortField") || 'globalRank'}>
                <Radio value={"globalRank"}>推荐</Radio>
                <Radio value={"onSaleTime"}>最新</Radio>
              </Radio.Group>
              <Checkbox onChange={handleCheckFree} checked={Number(freeFee) === 1}>免费</Checkbox>
              {
                isYueGao && <MallSelect option={themeStyleList} />
              }
            </Flex>
            <Search placeholder="请输入关键字搜索" onSearch={onSearch} style={{ width: 200 }} allowClear onChange={(e) => setKeywordValue(e.target.value)} value={keywordValue || ""} />
          </div>
          <Spin spinning={loading}>
            <div className="data-list">
              {productList &&
                productList.map((item, index) => {
                  return <MarketProductCard item={item} key={index} />;
                })}
            </div>
            {productList?.length === 0 && (
              <div style={{ padding: "130px 0" }}>
                {/* <NoData /> */}
                <div className="no-data">暂无此类型商品</div>
                <div className="no-data jump-btn">
                  <Button
                    type="primary"
                    onClick={() => {
                      window.open(
                        `/user-center/shop/product-list`
                      );
                    }}
                  >
                    立即发布商品
                  </Button>
                </div>
              </div>
            )}
          </Spin>
          <Pagination
            style={{ textAlign: "center", marginTop: "28px" }}
            hideOnSinglePage={true}
            current={Number(searchParams.get("pageNumber")) > 0 ? Number(searchParams.get("pageNumber")) : 1}
            onChange={changePage}
            showSizeChanger={false}
            pageSize={pageSize}
            total={total}
          />
        </>
      }

      {
        !!catalog && <ShopAlumb />
      }

      <FloatButton.BackTop />
    </MarketStyle>
  );
}

export default Market;
