import { WorkDetailType } from "types/work"
import { WorkVoteStyle } from "./style"
import {  useEffect, useRef, useState } from "react"
import { Button, message } from "antd"
import { ContestDetailResponse, ContestWorkTotalType } from "types/contest"
import { getContestDetail, getContestUserVoteTotal, getContestWorkTotal, postContestUserTicket, postOneVote } from "api/contest"
import { UserBaseInfo } from "types/user"
import VoteTicket from "components/vote-ticket"
import { useNavigate } from "react-router-dom"
import { loginPathWithRedirect } from "utils/functions/user"

const WorkVote = ({ detail, updateDetail }: { detail: WorkDetailType, updateDetail: () => void }) => {
    const { isContest, contestWork } = detail
    const [voteLoading, setVoteLoading] = useState(false)
    const [contestDetail, setContestDetail] = useState<ContestDetailResponse>()
    const [canVoteTotal, setCanVoteTotal] = useState(0)
    const [isShow, setIsShow] = useState(false)
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
    const voteRef = useRef() as React.MutableRefObject<any>;
    const navigate = useNavigate()
    const [workTicketDetail, setWorkTicketDetail] = useState<ContestWorkTotalType>()
    const [oneVoteLoading, setOneVoteLoading] = useState(false)

    const toVote = () => {
        if (!userInfo?.id) {
            navigate(loginPathWithRedirect())
            return
        }
        if (canVoteTotal > 0) {
            setIsShow(true)
        }
    }

    const close = () => {
        setIsShow(false)
    }

    useEffect(() => {
        if (!isContest) {
            return
        }
        getContest()
    }, [detail])

    const getContest = () => {
        getContestDetail({ id: Number(contestWork.contestId) }).then(res => {
            setContestDetail(res)
            // if (res.voteId) {
            //     getWorkContestVote()
            // }
            getWorkContestVote()
        })
    }

    const getUserContestVote = () => {
        getContestUserVoteTotal(Number(contestDetail?.voteId)).then(res => {
            setCanVoteTotal(res.validTicketCount)
        })
    }

    useEffect(() => {
        if (!contestDetail?.voteId) {
            return
        }
        if (userInfo?.id) {
            getUserContestVote()
        }
    }, [contestDetail?.voteId])

    const getWorkContestVote = () => {
        getContestWorkTotal(detail?.id).then(res => {
            setWorkTicketDetail(res)
        })
    }

    const vote = (value: number) => {
        if (!userInfo?.id) {
            navigate(loginPathWithRedirect())
            return
        }
        postContestUserTicket({ count: value, id: Number(contestDetail?.voteId), workId: detail?.id }).then(res => {
            getUserContestVote()
            getWorkContestVote()
            message.success("投票成功")
            close()
        }).finally(() => {
            voteRef.current?.resetLoading()
        })
    }


    // 一票晋级
    const oneVote = () => {
        setOneVoteLoading(true)
        postOneVote(detail?.id).then(res => {
            message.success(`${detail?.contestWork?.oneVoteName}成功`)
            updateDetail()
        }).finally(() => {
            setOneVoteLoading(false)
        })
    }


    return <WorkVoteStyle>
        {
         detail.publishType ===2 &&   workTicketDetail && workTicketDetail?.status > 0 && <>
                <Button
                    disabled={(canVoteTotal === 0 && (!!userInfo?.id)) || workTicketDetail?.status === 1}
                    loading={voteLoading}
                    style={{ width: '100%' }}
                    onClick={toVote}
                    type="primary"
                >
                    投票 {workTicketDetail?.ticketCount}
                </Button>
            {workTicketDetail?.status ===2 && <div className="tip">当前可使用票数：{canVoteTotal} </div>}    
            </>
        }
        {
            detail?.contestWork?.oneVoteFlag === 1 && <Button
                className="onVoteBtn"
                loading={oneVoteLoading}
                style={{ width: '100%' }}
                onClick={oneVote}
                type="primary"
            >
                {detail?.contestWork?.oneVoteName}
            </Button>
        }
        <VoteTicket ref={voteRef} isShow={isShow} vote={vote} close={close} ticket={canVoteTotal} />
    </WorkVoteStyle>
}

export default WorkVote