import styled from "styled-components";

export const OtherMessageItemStyle = styled.div`
width: 100%;
.other-msg-wrapper{
    width: 100%;
    position: relative;
    padding: 0 10px;
    margin: 15px 0;
    
   .msg{
        display: flex;
        width: fit-content;
        max-width: 60%;
        .msg-left{
            margin-right: ${props => props.theme.space.marginXS}px;
        }
        .msg-right{
            .time{
                font-size: 12px;
                color: ${props => props.theme.colors.colorTextSecondary};
            }
            .content{
                padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.paddingSM}px;
                word-break: break-all;
                background: rgb(243, 243, 243);
                color: #000;
                border-radius: ${props => props.theme.border.borderRadius}px;
                position: relative;
                display: flex;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: -10px;
                    height: 0;
                    width: 0;
                    border-top: 5px solid transparent;
                    border-left: 5px solid transparent;
                    border-right: 5px solid  rgb(243, 243, 243);;
                    border-bottom: 5px solid transparent;
                    }
            }
            .orderCreateSendMessage {
                width: 300px;
                .title {
                    margin-bottom: 5px;
                    font-size: 14px !important;
                    font-weight: normal;
                    font-weight: bold;
                }
                .order-detail {
                    display: flex;
                    gap: 10px;
                    margin: 5px 0;
                    .cover{
                        width: 100px;
                        height: 50px;
                        background-size: cover;
                    }
                    .right {
                        width: calc(100% - 110px);
                    }
                    .price-wrap {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        .link {
                            color: ${props=>props.theme.colors.colorPrimary};
                            cursor: pointer;
                        }
                    }
                }
                .tip {
                    margin-top: 5px;
                    padding-top: 5px;
                    border-top: 1px solid ${props=>props.theme.colors.colorBorder};
                    display: flex;
                    justify-content: space-between;
                    &-word {
                        min-width: 0;
                        color: #ff8809;
                    }
                }
            }
            .custome-msg{
                flex-direction: column;
                .photo-wrapper{
                    display: flex;
                    flex-wrap: wrap;
                    /* justify-content: space-between; */
                }
            }
            .file {
                display: flex;
                align-items: center;
                min-width: 220px;
                justify-content: space-between;
                cursor: pointer;
                gap: 10px;
                .detail {
                    .name {
                        overflow: hidden;
                        display: -webkit-box; 
                        -webkit-line-clamp: 2; 
                        -webkit-box-orient: vertical; 
                        max-width: 180px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .size {
                        font-size: 11px;
                    }
                }
            }
        }
    }    
}`
